import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	useAdminGetSitesQuery,
	useAdminDeleteSiteMutation,
} from '../../Core/Api';
import { List } from '../../Core/components/List/List';
import { ListItem } from '../../Core/components/List/ListItem';
import { ListLoading } from '../../Core/components/List/ListLoading';
import { PopMenuItems } from '../../Core/components/PopMenu/PopMenu';
import { toastConfig } from '../../Core/configuration/toast';
import { byKeyIncludes } from '../../Core/utils/Filter';
import { Site } from '../../SharedTypes/API/Admin';
import { AdminLayout } from './AdminLayout';
import { AdminRemoveSiteModal } from './AdminModals';

const popMenuItemsForSites: PopMenuItems = [
	{
		label: 'Edit',
		key: 'edit',
	},
	{
		label: 'Delete',
		key: 'delete',
	},
];

export const AdminSites = () => {
	const [siteToRemove, setSiteToRemove] = useState<Site | null>(null);
	const navigate = useNavigate();
	const { accountId } = useParams<{ accountId: string }>();
	const [filter, setFilter] = useState('');
	const { data, isLoading, isSuccess } = useAdminGetSitesQuery({
		accountId: accountId!,
	});
	const [deleteSite] = useAdminDeleteSiteMutation();

	const handleGoToNewSite = () => {
		navigate('new');
	};

	// ! Memoize sites to stop the blinky re-render on refetch
	const memoizedSites = useMemo(() => {
		if (data && data.sites && data.sites.length > 0) {
			return data.sites.filter(byKeyIncludes('name', filter));
		}

		return [];
	}, [data, filter]);

	const handleSelectedItem = (siteId: string) => (action: string) => {
		if (action === 'edit') {
			navigate(`${siteId}`);
		}

		if (action === 'delete') {
			setSiteToRemove(memoizedSites.find((site) => site.id === siteId)!);
		}
	};

	const handleModalClose = (doAction: boolean) => {
		// If true, then remove the site
		if (!doAction) {
			setSiteToRemove(null);
			return;
		}
		// Otherwise, well, close the modal and do nothing
		deleteSite({
			siteId: siteToRemove!.id,
		}).then((response) => {
			if ('error' in response) {
				// @ts-ignore
				toast(response.error.data.message, toastConfig.error);
				return;
			}

			toast('Site removed', toastConfig.success);
			setSiteToRemove(null);
		});
	};

	const handleSelectSite = (siteId: string) => {
		navigate(`${siteId}/config`);
	};

	return (
		<AdminLayout title="Sites" searchHandler={setFilter} searchTerm={filter}>
			{siteToRemove && (
				<AdminRemoveSiteModal
					respond={handleModalClose}
					name={siteToRemove.name}
				/>
			)}
			<List handleOnAdd={handleGoToNewSite} buttonLabel="Add site">
				{isLoading && (
					<ListItem templateColumns="1fr">
						<ListLoading text="Loading sites" />
					</ListItem>
				)}
				{!isLoading && memoizedSites.length === 0 && (
					<ListItem templateColumns="1fr">
						<ListLoading text="No sites to display" />
					</ListItem>
				)}
				{isSuccess &&
					memoizedSites.map((site) => (
						<ListItem
							templateColumns="repeat(3, 1fr)"
							key={site.id}
							popMenuItems={popMenuItemsForSites}
							handleMenuItemSelected={handleSelectedItem(site.id)}
							handleClick={() => handleSelectSite(site.id)}
						>
							<p>{site.name}</p>
						</ListItem>
					))}
			</List>
		</AdminLayout>
	);
};
