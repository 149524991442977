import { ChartDataSet } from '../../SharedTypes/API/Dashboard';

export const isSelectedDatePresent = (
	selectedDate: number,
	dates: number[]
): boolean => {
	return dates.some((date) => date === selectedDate);
};

export const getDatesFromDataSet = (dataSet: ChartDataSet): number[] => {
	return dataSet.data.flatMap((point) => {
		if (Array.isArray(point)) {
			return point.map((p) => p.x);
		}

		return point.x;
	});
};
