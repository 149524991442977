import { FC, ReactNode } from 'react';
import { CalendarContainer as DatepickerContainer } from 'react-datepicker';
import './CalendarContainer.scss';

type Props = {
	options: {
		dates: [Date, Date];
		title: string;
	}[];
	setDates: (args: [Date, Date]) => void;
	className?: string;
	children: ReactNode;
};

export const CalendarContainer: FC<Props> = ({
	options,
	setDates,
	className,
	children,
}) => (
	<div className="CalendarContainer">
		<div className="CalendarContainer__QuickAccess">
			<ul className="CalendarContainer__QuickAccessList">
				{options.map((option) => (
					<li
						key={option.title}
						onClick={() => setDates(option.dates)}
						className="CalendarContainer__Option"
					>
						{option.title}
					</li>
				))}
			</ul>
		</div>
		<DatepickerContainer className={className}>{children}</DatepickerContainer>
	</div>
);
