import { FC, ReactNode } from 'react';
import { AnimateHeight } from '../AnimateHeight/AnimateHeight';
import './FoldOutList.scss';

type Props = {
	labelComponent: ReactNode;
	isVisible: boolean;
	children: ReactNode;
};

export const FoldOutList: FC<Props> = ({
	children,
	labelComponent,
	isVisible,
}) => {
	const variants = {
		open: { height: 'auto', opacity: 1 },
		collapsed: { height: '0px', opacity: 0 },
	};
	return (
		<div>
			{labelComponent}
			<AnimateHeight
				ease="easeOut"
				duration={0.2}
				variants={variants}
				isVisible={isVisible}
			>
				{children}
			</AnimateHeight>
		</div>
	);
};
