import classNames from 'classnames';
import { ReactElement } from 'react';
import { Icon } from '../Icon/Icon';

import './ConfiguratorHeader.scss';

type Props = {
	children?: ReactElement;
	title: string;
	onCloseClick: () => void;
	onExpandClick: () => void;
	onQuickReferenceClick?: () => void;
	quickReferenceState?: boolean;
	showCloseButton?: boolean;
};

export const ConfiguratorHeader = ({
	title,
	onCloseClick,
	onExpandClick,
	onQuickReferenceClick,
	quickReferenceState,
	showCloseButton = true,
	children,
}: Props) => {
	const hasChildren = !!children;

	return (
		<>
			<header
				className={classNames('ConfiguratorHeader', {
					hasChildren,
				})}
			>
				<div className="ConfiguratorHeader__Header">
					<div className="ConfiguratorHeader__Title">{title}</div>
					<div className="ConfiguratorHeader__Icons">
						{quickReferenceState !== undefined && (
							<div
								className={classNames(['ConfiguratorHeader__QuickReference'], {
									'ConfiguratorHeader__QuickReference--Open':
										quickReferenceState,
								})}
								onClick={onQuickReferenceClick}
							>
								<Icon name="Help" width={24} />
							</div>
						)}
						<div className="ConfiguratorHeader__Expand">
							<Icon name="CircleExpand" width={24} onClick={onExpandClick} />
						</div>
						{showCloseButton && (
							<div className="ConfiguratorHeader__Close">
								<Icon name="CircleX" width={24} onClick={onCloseClick} />
							</div>
						)}
					</div>
				</div>
				{hasChildren && (
					<div className="ConfiguratorHeader__Children">{children}</div>
				)}
			</header>
		</>
	);
};
