import { useCallback } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

export const useQueryParam = <T extends string>(
	key: string
): [T | undefined, (newQuery: T, options?: NavigateOptions) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();
	const paramValue = searchParams.get(key) as T;

	const setValue = useCallback(
		(newValue: T, options?: NavigateOptions) => {
			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.set(key, newValue || '');
			setSearchParams(newSearchParams, options);
		},
		[key, searchParams, setSearchParams]
	);

	return [paramValue, setValue];
};
