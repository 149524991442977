import { FC } from 'react';
import { Icon } from '../Icon/Icon';
import './LoadingBlock.scss';

type Props = {
	iconSize?: number;
};

export const LoadingBlock: FC<Props> = ({ iconSize = 30 }) => {
	return (
		<div className="LoadingBlock">
			<Icon name="LoadingSpinner" width={iconSize} />
		</div>
	);
};
