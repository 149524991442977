import { useState, useCallback, RefObject } from 'react';

export function useCallbackRef(): [
	RefObject<any> | null,
	(ref: RefObject<any>) => void
] {
	const [ref, setRef] = useState<RefObject<any> | null>(null);
	const fn = useCallback((node: any): void => {
		setRef(node);
	}, []);

	return [ref, fn];
}
