import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../Core/components/Button/Button';
import { FormInput } from '../Core/components/FormInput/FormInput';
import './LoginForm.scss';
import { ColorLookup } from '../Core/utils/ColorLookup';
import classNames from 'classnames';
import { useBoolean } from 'usehooks-ts';
import { api, useCreatePasswordMutation } from '../Core/Api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../Core/redux/useAppDispatch';
import { RootState } from '../Core/redux/store';
import { useAppSelector } from '../Core/redux/useAppSelector';
import { resetLoginFlow } from './Login.slice';
import { passwordRules } from './Config';

const schema = z
	.object({
		newPassword1: z
			.string()
			.refine(
				passwordRules.containsLowercaseLetter,
				'Must contain at least 1 lower-case letter'
			)
			.refine(
				passwordRules.containsUppercaseLetter,
				'Must contain at least 1 upper-case letter'
			)
			.refine(passwordRules.containsNumber, 'Must contain at least 1 number')
			.refine(
				passwordRules.containsSpecialCharacter,
				'Must contain at least 1 special character'
			)
			.refine(
				passwordRules.minimumEightCharacters,
				'Must be at least 8 characters'
			),
		newPassword2: z.string(),
	})
	.refine((data) => data.newPassword1 === data.newPassword2, {
		message: 'Please repeat the same password',
		path: ['newPassword2'],
	});

type Schema = z.infer<typeof schema>;

export const OnboardingCreatePasswordForm = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const email = useAppSelector((state: RootState) =>
		state.login.state === 'onboarding' ? state.login.email : ''
	);
	const oldPassword = useAppSelector((state: RootState) =>
		state.login.state === 'onboarding' ? state.login.oldPassword : ''
	);
	const [resetPassword, response] = useCreatePasswordMutation();

	// State for wether login errored out
	const { value: hasError, setValue: setHasError } = useBoolean(false);

	// Setup React Hook Form handler
	const { control, handleSubmit } = useForm<Schema>({
		resolver: zodResolver(schema),
		mode: 'onBlur',
		shouldFocusError: true,
		defaultValues: {
			newPassword1: '',
			newPassword2: '',
		},
	});

	// Handle the submit call
	const onSubmit = handleSubmit(({ newPassword1 }) => {
		setHasError(false);
		resetPassword({ username: email, newPassword: newPassword1, oldPassword });
	});

	// Control error state base on response
	useEffect(() => {
		if (response.isLoading) {
			setHasError(false);
			return;
		}

		if (response.isError) {
			setHasError(true);
		}
	});

	// If the user authentication succeeded, redirect to root route
	useEffect(() => {
		if (!response.data) {
			return;
		}

		if (response.data.success) {
			// Remove any cached queries from RTK
			dispatch(api.util.resetApiState());

			// Reset any saved credentials for the onboarding flow
			dispatch(resetLoginFlow());

			// Navigate to root to start over
			navigate('/');
		}
	}, [dispatch, navigate, response.data]);

	return (
		<div className="LoginForm">
			<div
				className={classNames(
					hasError
						? ['LoginForm__Error', 'LoginForm__Error--Active']
						: ['LoginForm__Error']
				)}
				style={{ backgroundColor: ColorLookup.forGraph['red'] }}
			>
				Could not sign up with provided credentials
			</div>

			<form className="LoginForm__Form" onSubmit={onSubmit}>
				<div className="LoginForm__Input">
					<FormInput
						theme="Login"
						control={control}
						name="newPassword1"
						label="New Password"
						type="password"
					/>
				</div>
				<div className="LoginForm__Input">
					<FormInput
						theme="Login"
						control={control}
						name="newPassword2"
						label="Repeat Password"
						type="password"
					/>
				</div>

				<div className="LoginForm__Actions">
					<div className="LoginForm__Submit">
						<Button type="submit" theme="Blue" height="Large">
							Create Password
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};
