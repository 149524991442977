import React from 'react';
import { NavigationButton } from './NavigationButton';

import './ExplorerNavigation.scss';
import { noop } from '../../Core/utils/Function';

type Props = {
	onZoomIn?: () => void;
	onZoomOut?: () => void;
	onToggleAnalysisExplorer: () => void;
};

export const ExplorerNavigation = ({
	onZoomIn = noop,
	onZoomOut = noop,
	onToggleAnalysisExplorer,
}: Props) => {
	return (
		<nav className="ExplorerNavigation">
			{/* <div className="ExplorerNavigation__Button" onClick={onZoomIn}>
				<NavigationButton iconName="Plus" iconWidth={12} />
			</div>
			<div className="ExplorerNavigation__Button" onClick={onZoomOut}>
				<NavigationButton iconName="Minus" iconWidth={12} />
			</div> */}
			<div
				className="ExplorerNavigation__Button"
				onClick={onToggleAnalysisExplorer}
			>
				<NavigationButton iconName="AnalysisExplorer" iconWidth={19} />
			</div>
		</nav>
	);
};
