import './CalendarHeader.scss';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Icon } from '../../Icon/Icon';

type CalendarHeaderProps = ReactDatePickerCustomHeaderProps;

export const CalendarHeader = ({
	monthDate,
	decreaseMonth,
	increaseMonth,
}: CalendarHeaderProps) => (
	<div className="CalendarHeader">
		<div className="CalendarHeader__Month">
			<div
				aria-label="Previous Month"
				className="CalendarHeader__Action CalendarHeader__Action--PrevMonth"
				onClick={decreaseMonth}
			>
				<Icon name="CaretRight" width={5} />
			</div>
			<span className="react-datepicker__current-month">
				{monthDate.toLocaleString('da-DK', {
					month: 'long',
					year: 'numeric',
				})}
			</span>
			<div
				aria-label="Next Month"
				className="CalendarHeader__Action CalendarHeader__Action--NextMonth"
				onClick={increaseMonth}
			>
				<Icon name="CaretRight" width={5} />
			</div>
		</div>
	</div>
);
