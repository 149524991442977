import './UserCard.scss';

type Props = {
	name: string;
	email: string;
};

export const UserCard = ({ email, name }: Props) => {
	const shortName = name.length > 0 ? name[0] : 'A';

	return (
		<div className="UserCard">
			<div className="UserCard__Thumbnail">{shortName}</div>
			<div className="UserCard__Details">
				<div className="UserCard__Name">{name}</div>
				<div className="UserCard__AccountName">{email}</div>
			</div>
		</div>
	);
};
