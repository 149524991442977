import classNames from 'classnames';
import { FC } from 'react';
import './Counter.scss';

type Props = {
	theme: 'Default';
	count: number;
};

export const Counter: FC<Props> = ({ theme, count }) => (
	<div className={classNames(['Counter', `Counter--${theme}`])}>{count}</div>
);
