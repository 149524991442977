import { useEffect, useMemo, useState } from 'react';
import {
	ComponentSelectionId,
	deserializeComponentSelectionId,
} from './Explorer.slice';

import { ModelJointFactory } from './models/ModelJoint.factory';
import { ModelElementFactory } from './models/ModelElement.factory';
import { ModelExplorerProps } from './objects/ModelExplorer';
import { useGetSiteGeometryQuery } from '../../Core/Api';
import { ComponentId, SiteGeometry } from '../../SharedTypes/API/Explorer';
import { isUndefined } from '../../Core/utils/Logic';
import { noop } from '../../Core/utils/Function';
import { ColorLookup } from '../../Core/utils/ColorLookup';
import { useLocation } from 'react-router-dom';

export const useSectionSelectorData = ({
	siteId,
	onSectionSelect = noop,
}: {
	siteId: string;
	onSectionSelect?: (sectionId: string) => void;
}):
	| { modelData: ModelExplorerProps; seaLevel: number; soilLevel: number }
	| 'loading'
	| 'error' => {
	const { isError, isLoading, data } = useGetSiteGeometryQuery({
		siteId,
	});
	const { pathname } = useLocation();
	const [hoveredSection, setHoveredSection] = useState<string | null>(null);

	// When we change pathname, we reset the hovered section
	useEffect(() => {
		if (pathname.includes('dashboard') || pathname.includes('trends')) {
			setHoveredSection(null);
		}
	}, [pathname]);

	const colors = useMemo(
		() => ({
			base: ColorLookup.forElement['none'],
			hover: '#ffdd75',
		}),
		[]
	);

	// Keep track of a list of all elements in the hovered section for coloring
	const hoveredElements: ComponentId[] = useMemo(() => {
		if (data === null || hoveredSection === null) {
			return [];
		}

		return data?.siteGeometry.sections[hoveredSection].elements ?? [];
	}, [data, hoveredSection]);

	// Keep track of a list of all joints in the hovered section for coloring
	const hoveredJoints: ComponentId[] = useMemo(() => {
		if (data === null || hoveredSection === null) {
			return [];
		}

		return data?.siteGeometry.sections[hoveredSection].nodes ?? [];
	}, [data, hoveredSection]);

	// Joints to render
	const joints = useMemo(() => {
		const joints = data?.siteGeometry.allNodes;

		if (typeof joints === 'undefined') {
			return [];
		}

		// Map to ModelJoints
		return joints.map((joint) => {
			const id: ComponentId = joint[0];

			const isHovered = hoveredJoints.includes(id);

			return ModelJointFactory.fromNodeDTO({
				color: isHovered ? colors.hover : colors.base,
			})(joint);
		});
	}, [colors.base, colors.hover, data?.siteGeometry.allNodes, hoveredJoints]);

	// Elements to render
	const elements = useMemo(() => {
		const elements = data?.siteGeometry.allElements;

		if (typeof elements === 'undefined') {
			return [];
		}

		// Map to ModelElements
		return elements.map((element) => {
			const id: ComponentId = element[0];

			const isHovered = hoveredElements.includes(id);

			return ModelElementFactory.fromElementDTO({
				color: isHovered ? colors.hover : colors.base,
			})(element);
		});
	}, [
		colors.base,
		colors.hover,
		data?.siteGeometry.allElements,
		hoveredElements,
	]);

	const seaLevel = useMemo(
		() => data?.siteGeometry.sea.seaLevel ?? 0,
		[data?.siteGeometry.sea.seaLevel]
	);

	const soilLevel = useMemo(
		() => data?.siteGeometry.sea.soilLevel ?? -55,
		[data?.siteGeometry.sea.soilLevel]
	);

	function onComponentClick(componentSelectionId: ComponentSelectionId) {
		if (isUndefined(data)) {
			return;
		}

		const section = getSectionIdByComponent(data.siteGeometry.sections)(
			componentSelectionId
		);

		if (section !== null) {
			onSectionSelect(section);
		}
	}

	function onComponentHover(componentSelectionId: ComponentSelectionId | null) {
		if (isUndefined(data)) {
			return;
		}

		// Check if the onSectionSelect is defined as something other than noop.
		if (onSectionSelect === noop) {
			// In case we don't have a select, we do not need to highlight on hover.
			return;
		}

		// Don't update if already null
		if (componentSelectionId === null && hoveredSection == null) {
			return;
		}

		// No need to make a lookup with this
		if (componentSelectionId === null) {
			setHoveredSection(null);
			return;
		}

		// Get the hovered section
		const section = getSectionIdByComponent(data.siteGeometry.sections)(
			componentSelectionId
		);

		if (section !== null) {
			setHoveredSection(section);
		}
	}

	if (isLoading) {
		return 'loading';
	}

	if (isError) {
		return 'error';
	}

	const modelData: ModelExplorerProps = {
		joints,
		jointSubResults: [],
		elements,
		onComponentClick,
		highlightedComponent: null,
		selectedComponents: [],
		onComponentHover,
	};

	return { modelData, seaLevel, soilLevel };
};

const getSectionIdByComponent =
	(sections: SiteGeometry['sections']) => (component: ComponentSelectionId) => {
		const sectionsArray = Object.entries(sections);

		const { componentId, componentType } =
			deserializeComponentSelectionId(component);

		const match = sectionsArray.find(([_sectionId, { nodes, elements }]) => {
			const searchArray = componentType === 'joint' ? nodes : elements;

			return searchArray.includes(componentId);
		});

		// If we get a match for a section, return the Section ID
		if (match) {
			return match[0];
		}

		// Otherwise return null
		return null;
	};
