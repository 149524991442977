import { FC } from 'react';
import { ColorLookup } from '../../utils/ColorLookup';
import './ActiveIndicator.scss';

type Props = {
	active: boolean;
};

export const ActiveIndicator: FC<Props> = ({ active }) => (
	<div className="ActiveIndicator">
		<div
			className="ActiveIndicator__Dot"
			style={{
				backgroundColor: active
					? ColorLookup.forGraph['green']
					: ColorLookup.forGraph['yellow'],
			}}
		></div>
		<p className="ActiveIndicator__Text">{active ? 'Active' : 'Invited'}</p>
	</div>
);
