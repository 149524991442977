import { FC, ReactNode } from 'react';
import { Button } from '../Button/Button';
import './List.scss';

export const List: FC<{
	headline?: string;
	handleOnAdd?: () => void;
	buttonLabel?: string;
	children: ReactNode;
}> = ({ children, headline, handleOnAdd, buttonLabel }) => (
	<section>
		<div className="List__Top">
			{headline && <h3 className="List__Headline">{headline}</h3>}
			{handleOnAdd && (
				<Button theme="Blue" onClick={handleOnAdd} type="button">
					{buttonLabel}
				</Button>
			)}
		</div>
		<ul className="List">{children}</ul>
	</section>
);
