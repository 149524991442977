import { calculateChartColor } from '../../Core/components/Chart/ChartOptions';
import { DashboardStatus } from '../../Core/types/DashboardStatus';
import { ColorLookup } from '../../Core/utils/ColorLookup';
import { ChartDataSetData } from '../../SharedTypes/API/Dashboard';

function createData(num: number, min = 0, max = 100): ChartDataSetData[] {
	const todayMinusNumberOfPoints = Date.now() - num * 24 * 60 * 60 * 1000;
	return Array.from(new Array(num)).map((_, i) => ({
		x: new Date(todayMinusNumberOfPoints).getTime() + i * 24 * 60 * 60 * 1000,
		y: randomIntFromInterval(min, max),
		thresholds: [25, 50, 75],
	}));
}

function randomIntFromInterval(min: number, max: number) {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export const defaultChartData = createData(10, 66, 88);
export const dataSetTwo = createData(10, 10, 40);
export const dataSetThree = createData(10, 40, 100);
export const dataSetFour = createData(10, 10, 24);
export const dataSetFive = createData(10, 30, 35);

export const calculateStatusForTesting = (
	thresholds: (number | null)[],
	value: number | null,
	order: 'asc' | 'desc'
): DashboardStatus => {
	/** Calculate the color the chart should be, and convert it to a status */
	const chartColor = calculateChartColor(
		{ x: 1, y: value, thresholds, equalAxis: true },
		order
	);

	if (chartColor === ColorLookup.forGraph['yellow']) {
		return 'alert';
	}

	if (chartColor === ColorLookup.forGraph['orange']) {
		return 'warning';
	}

	if (chartColor === ColorLookup.forGraph['red']) {
		return 'critical';
	}

	return 'default';
};
