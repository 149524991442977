import { useMemo } from 'react';
import { useGetSectionResultsQuery } from '../Core/Api';
import { useAppSelector } from '../Core/redux/useAppSelector';
import {
	selectElementDimensionConfiguration,
	selectJointDimensionConfiguration,
} from './Explorer/Explorer.selectors';
import { GetSectionResults, SectionResults } from '../SharedTypes/API/Explorer';
import { isUndefined } from '../Core/utils/Logic';

type UseSectionResult = ({ sectionId }: { sectionId: string }) => {
	sectionResult: SectionResults | null;
	state: 'error' | 'loading' | 'fetching' | 'success';
};
export const useSectionResult: UseSectionResult = ({ sectionId }) => {
	const elementDimensionConfig = useAppSelector(
		selectElementDimensionConfiguration
	);
	const jointDimensionConfig = useAppSelector(
		selectJointDimensionConfiguration
	);

	// Memoize the query to prevent unecessary calls
	const query: GetSectionResults['arguments'] = useMemo(
		() => ({
			sectionId,
			elementDimensionConfig,
			jointDimensionConfig,
		}),
		[elementDimensionConfig, jointDimensionConfig, sectionId]
	);

	// Do the query
	const { isError, isLoading, isFetching, data } = useGetSectionResultsQuery(
		query,
		{
			skip: !sectionId,
		}
	);

	// On error just return the error
	if (isError) {
		return { sectionResult: null, state: 'error' };
	}

	if (isFetching || isLoading || isUndefined(data)) {
		return {
			sectionResult: data ?? null,
			state: isLoading ? 'loading' : 'fetching',
		};
	}

	return {
		sectionResult: data ?? null,
		state: 'success',
	};
};
