import { Stats } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import { Suspense, useCallback, useMemo, useRef } from 'react';
import { JointMesh } from './JointMesh';
import { PipeMesh } from './PipeMesh';
import * as Colors from '../../../Core/utils/Colors';

import { PlateMesh } from './PlateMesh';
import { configuration } from '../../../Core/configuration/configuration';
import { noop } from '../../../Core/utils/Function';
import { ModelJoint, ModelJointColor } from '../models/ModelJoint.entity';
import { ModelElement, ModelElementColor } from '../models/ModelElement.entity';
import {
	ModelJointSubResult,
	ModelJointSubResultColor,
} from '../models/ModelJointSubResult.entity';
import { JointSubResultMesh } from './JointSubResultMesh';
import {
	ComponentSelectionId,
	deserializeComponentSelectionId,
	serializeComponentSelectionId,
} from '../Explorer.slice';
import { prop } from '../../../Core/utils/Object';
import { ComponentId } from '../../../SharedTypes/API/Explorer';
import { ModelElementFactory } from '../models/ModelElement.factory';
import { ModelJointSubResultFactory } from '../models/ModelJointSubResult.factory';
import { Instance, PointerGroup } from './PointerGroup';
import { ColorLookup } from '../../../Core/utils/ColorLookup';
import { EffectComposer, Outline } from '@react-three/postprocessing';
import { SensorMesh } from './SensorMesh';
import { SensorLocation } from '../../../SharedTypes/API/Dashboard';

export interface ModelExplorerProps {
	elements: ModelElement[];
	joints: ModelJoint[];
	jointSubResults: ModelJointSubResult[];
	onComponentClick?: (componentSelection: ComponentSelectionId) => void;
	onComponentHover?: (componentSelection: ComponentSelectionId | null) => void;
	selectedComponents: ComponentSelectionId[];
	highlightedComponent: ComponentSelectionId | null;
}

export type ModelExplorerExtras = {
	sensorLocations?: SensorLocation[];
	selectedSensorId: string | null;
	setSelectedSensorId: (id: string | null) => void;
};

export function ModelExplorer({
	elements,
	joints,
	jointSubResults,
	onComponentClick = noop,
	onComponentHover = noop,
	selectedComponents,
	highlightedComponent,
	sensorLocations = [],
	selectedSensorId,
	setSelectedSensorId,
}: ModelExplorerProps & ModelExplorerExtras) {
	// Elements with a round cross-section
	const pipeElements = useMemo(
		() => elements.filter((el) => el[1] === 'pipe'),
		[elements]
	);

	// Elements with a square cross-section
	const boxElements = useMemo(
		() => elements.filter((el) => el[1] === 'box'),
		[elements]
	);

	// Elements with vertice-based shape
	const plateElements = useMemo(
		() => elements.filter((el) => el[1] === 'plate'),
		[elements]
	);

	// const handleHover = useDebouncedCallback((_, instance: Instance | null) => {
	// 	if (instance === null) {
	// 		return onComponentHover(null);
	// 	}

	// 	const userData = instance.userData;
	// 	const idHovered = userData.componentIds[instance.instanceNumber];

	// 	if (!idHovered) {
	// 		console.error('Could not find instance id in component id list');

	// 		return;
	// 	}

	// 	// console.log(`Id: ${idHovered}, Category: ${userData.category}`);

	// 	// Create the component selection id
	// 	// Reminder: this is just a string value that represents the type and id of a component
	// 	// This allows us to easier keep track of selected components across types
	// 	const componentSelectionId = serializeComponentSelectionId({
	// 		componentId: idHovered,
	// 		componentType: userData.category,
	// 	});

	// 	onComponentHover(componentSelectionId);
	// }, 200);

	const handleClick = useCallback(
		(event: ThreeEvent<MouseEvent>, instance: Instance | null) => {
			// Prevent objects behind the one in front to trigger the event
			event.stopPropagation();

			if (instance === null) {
				return;
			}

			const userData = instance.userData;
			const idClicked = userData.componentIds[instance.instanceNumber];

			if (!idClicked) {
				console.error('Could not find instance id in component id list');

				return;
			}

			// console.log(`Id: ${idClicked}, Category: ${userData.category}`);

			// Create the component selection id
			// Reminder: this is just a string value that represents the type and id of a component
			// This allows us to easier keep track of selected components across types
			const componentSelectionId = serializeComponentSelectionId({
				componentId: idClicked,
				componentType: userData.category,
			});

			onComponentClick(componentSelectionId);
		},
		[onComponentClick]
	);

	return (
		<Suspense fallback={null}>
			<ModelRenderMemo
				onElementClick={handleClick}
				// onElementHover={handleHover}  Old implementation
				onElementHover={noop}
				pipeElements={pipeElements}
				selectedComponents={selectedComponents}
				highlightedComponent={highlightedComponent}
				boxElements={boxElements}
				plateElements={plateElements}
				joints={joints}
				jointSubResults={jointSubResults}
				sensorElements={sensorLocations}
				selectedSensorId={selectedSensorId ?? null}
				setSelectedSensorId={setSelectedSensorId}
			/>
		</Suspense>
	);
}

/** Calculate the bounding box of the model in x-z plane based on an array of joints */
const getBoundingBox = (joints: ModelJoint[]) => {
	return joints.reduce(
		(bounding, joint) => {
			const x: number = joint[3];
			const z: number = joint[5];

			bounding.x.min = Math.min(x, bounding.x.min);
			bounding.x.max = Math.max(x, bounding.x.max);
			bounding.z.min = Math.min(z, bounding.z.min);
			bounding.z.max = Math.max(z, bounding.z.max);

			return bounding;
		},
		{
			x: { min: Infinity, max: -Infinity },
			z: { min: Infinity, max: -Infinity },
		}
	);
};

const ModelRender = ({
	boxElements,
	onElementClick,
	onElementHover,
	jointSubResults,
	joints,
	pipeElements,
	plateElements,
	selectedComponents,
	highlightedComponent,
	sensorElements = [],
	selectedSensorId,
	setSelectedSensorId,
}: {
	onElementClick: (
		event: ThreeEvent<MouseEvent>,
		instance: Instance | null
	) => void;
	onElementHover: (
		event: ThreeEvent<MouseEvent>,
		instance: Instance | null
	) => void;
	pipeElements: ModelElement[];
	selectedComponents: ComponentSelectionId[];
	highlightedComponent: ComponentSelectionId | null;
	boxElements: ModelElement[];
	plateElements: ModelElement[];
	joints: ModelJoint[];
	jointSubResults: ModelJointSubResult[];
	sensorElements?: SensorLocation[];
	selectedSensorId: string | null;
	setSelectedSensorId: (id: string | null) => void;
}) => {
	// Calcuate the bounding box of the model for positioning in the center
	const modelBoundingBox = useMemo(() => getBoundingBox(joints), [joints]);

	// Calculate the model center
	// This can't be done in 3D space yet due to ThreeJS limitation
	// See https://github.com/mrdoob/three.js/issues/18334
	// Eventually Dreis Center component may work
	const center = useMemo(() => {
		return {
			x: (modelBoundingBox.x.min + modelBoundingBox.x.max) / 2,
			// For some reason, only the z axis is skewed, so needs to have the width
			// of the element subtracted from the centering position
			z:
				(modelBoundingBox.z.min + modelBoundingBox.z.max) / 2 -
				modelBoundingBox.z.min -
				modelBoundingBox.z.max,
		};
	}, [modelBoundingBox]);

	const deserializedSelectedComponents = useMemo(
		() => selectedComponents.map(deserializeComponentSelectionId),
		[selectedComponents]
	);

	const { hasJointSelections, hasElementSelections } = useMemo(
		() => ({
			hasJointSelections: deserializedSelectedComponents.some(
				({ componentType }) => componentType === 'joint'
			),
			hasElementSelections: deserializedSelectedComponents.some(
				({ componentType }) => componentType === 'element'
			),
		}),
		[deserializedSelectedComponents]
	);

	// Get the Id's of the selected elements (if any, could be all joints)
	const selectedElements = deserializedSelectedComponents
		.filter(({ componentType }) => componentType === 'element')
		.map(prop('componentId'));

	// Get the Id's of the selected joints (if any, could be all joints)
	const selectedJoints = deserializedSelectedComponents
		.filter(({ componentType }) => componentType === 'joint')
		.map(prop('componentId'));

	// Get the highlighted PipeElements, BoxElements, Joints or jointSubElements
	// Only one will be highlighted, but we represent them separately because of their separate geometry
	// and as arrays since the render components expect ModelElement[] | ModelJoint[]
	const {
		highlightedPipeElements,
		highlightedBoxElements,
		highlightedJoints,
		highlightedJointSubResults,
	} = useMemo<{
		highlightedPipeElements: ModelElement[];
		highlightedBoxElements: ModelElement[];
		highlightedJoints: ModelJoint[];
		highlightedJointSubResults: ModelJointSubResult[];
	}>(() => {
		const emptyOutput = {
			highlightedPipeElements: [],
			highlightedBoxElements: [],
			highlightedJoints: [],
			highlightedJointSubResults: [],
		};

		if (highlightedComponent === null) {
			// Nothing highlighted, so we return empty arrays
			return emptyOutput;
		}

		const { componentId, componentType } =
			deserializeComponentSelectionId(highlightedComponent);

		// Utility function for the search
		const matchesComponentIds =
			(searchId: ComponentId[]) =>
			(component: ModelElement | ModelJoint | ModelJointSubResult) => {
				const componentId: ComponentId = component[0];

				return searchId.includes(componentId);
			};

		// The joint case is a little easier
		if (componentType === 'joint') {
			// Find the ModelJoint
			const foundJoint = joints.filter(matchesComponentIds([componentId]));

			// If a joint was found, return it
			if (foundJoint.length > 0) {
				return { ...emptyOutput, highlightedJoints: foundJoint };
			}

			const foundJointSubResult = jointSubResults.filter(
				matchesComponentIds([componentId])
			);

			if (foundJointSubResult.length > 0) {
				return {
					...emptyOutput,
					highlightedJointSubResults: foundJointSubResult,
				};
			}
		}

		if (componentType === 'element') {
			// Get the relevant search function for the element
			const searchFunction = (() => {
				// It the element is a parent, we search by the parent-specific first part
				// of the Component ID. IE. for 320-0 we search for all elements with the 320 prefix
				// This ensures that both any parent element and any child elements are part of the selection
				// when a parent is highlighted
				if (isParentElement(componentId)) {
					const parentId = componentId.split('-')[0];

					return (element: ModelElement) => {
						const elementComponentId: ComponentId = element[0];
						return elementComponentId.split('-')[0] === parentId;
					};
				}

				// If the element is a child, we search for the specific id as normal
				return matchesComponentIds([componentId]);
			})();

			// Search both for box and pipe elements, since the element could be in any of these
			const foundPipeElement = pipeElements.filter(searchFunction);
			const foundBoxElement = boxElements.filter(searchFunction);

			// If a Pipe was found, return it
			if (foundPipeElement.length > 0) {
				return {
					...emptyOutput,
					highlightedPipeElements: foundPipeElement,
				};
			}

			// If a Box was found, return it
			if (foundBoxElement.length > 0) {
				return { ...emptyOutput, highlightedBoxElements: foundBoxElement };
			}
		}

		// This should not happen, but we need safety
		return emptyOutput;
	}, [
		boxElements,
		highlightedComponent,
		jointSubResults,
		joints,
		pipeElements,
	]);

	const modelPipeElements = useMemo(
		() =>
			setSelectedColors({
				hasSelections: hasElementSelections,
				selectedComponentsOfType: selectedElements,
				elements: pipeElements,
				inferParentFn: ModelElementFactory.inferParentId,
			}),
		[hasElementSelections, pipeElements, selectedElements]
	);

	const modelBoxElements = useMemo(
		() =>
			setSelectedColors({
				hasSelections: hasElementSelections,
				selectedComponentsOfType: selectedElements,
				elements: boxElements,
				inferParentFn: ModelElementFactory.inferParentId,
			}),
		[hasElementSelections, selectedElements, boxElements]
	);

	const modelJointElements = useMemo(
		() =>
			setSelectedColors({
				hasSelections: hasJointSelections,
				selectedComponentsOfType: selectedJoints,
				elements: joints,
				inferParentFn: () => null,
			}),
		[hasJointSelections, joints, selectedJoints]
	);

	const modelJointSubResultElements = useMemo(
		() =>
			setSelectedColors({
				hasSelections: hasJointSelections,
				selectedComponentsOfType: selectedJoints,
				elements: jointSubResults,
				inferParentFn: ModelJointSubResultFactory.inferParentId,
			}),
		[hasJointSelections, jointSubResults, selectedJoints]
	);

	// Unfortunately the Outline effect does not play nicely with null refs, so any is used here
	const highlightedPipeElementsRef = useRef<any>();
	const highlightedBoxElementsRef = useRef<any>();
	const highlightedJointsRef = useRef<any>();
	const highlightedJointSubResultsRef = useRef<any>();

	return (
		<>
			{configuration.explorer.showStats && <Stats />}

			<group
				name="Model"
				// Move the model into center on the xz plane
				position={[-center.x, 0, -center.z]}
			>
				{/* Selectable Components */}
				<PointerGroup onDoubleClick={onElementClick} onHover={onElementHover}>
					<PipeMesh pipes={modelPipeElements} />
					<PipeMesh pipes={modelBoxElements} pipeSegments={4} />
					<JointMesh joints={modelJointElements} />
					<SensorMesh
						sensors={sensorElements}
						selectedId={selectedSensorId}
						setSelectedId={setSelectedSensorId}
					/>
				</PointerGroup>

				{/* Decorative Components */}
				<group>
					<PlateMesh plates={plateElements} />
					<JointSubResultMesh jointSubResults={modelJointSubResultElements} />
				</group>

				{/* Highlighted Components */}
				<group>
					<PipeMesh
						pipes={highlightedPipeElements}
						instanceRef={highlightedPipeElementsRef}
					/>
					<PipeMesh
						pipes={highlightedBoxElements}
						instanceRef={highlightedBoxElementsRef}
						pipeSegments={4}
					/>
					<JointMesh
						joints={highlightedJoints}
						instanceRef={highlightedJointsRef}
					/>
					<JointSubResultMesh
						jointSubResults={highlightedJointSubResults}
						instanceRef={highlightedJointSubResultsRef}
						isHighlight
					/>
				</group>
			</group>

			{/* <GizmoHelper
				alignment="bottom-right"
				margin={[80, 120]}
				renderPriority={2}
			>
				<GizmoViewcube
					hoverColor="#a9bbc3"
					color="#809299"
					strokeColor="#394144"
					showCorners={false}
					showEdges={false}
				/>
			</GizmoHelper> */}

			{/* Autoclear is needed, see https://github.com/pmndrs/react-postprocessing/issues/82 */}
			<EffectComposer autoClear={false}>
				<Outline
					xRay
					selection={[
						highlightedPipeElementsRef,
						highlightedBoxElementsRef,
						highlightedJointsRef,
						highlightedJointSubResultsRef,
					]}
					edgeStrength={4}
					visibleEdgeColor={0xffffff}
					hiddenEdgeColor={0xffffff}
					pulseSpeed={0.4}
				/>
			</EffectComposer>
		</>
	);
};

const adjustColor = <
	T extends ModelElementColor | ModelJointColor | ModelJointSubResultColor
>(
	color: T
): T => Colors.blendColors(color as string, '#fff', 0.7) as T;

const setSelectedColors = <
	T extends ModelElement | ModelJoint | ModelJointSubResult
>(args: {
	hasSelections: boolean;
	selectedComponentsOfType: ComponentId[];
	elements: T[];
	inferParentFn: (id: ComponentId) => ComponentId | null;
}) => {
	// If nothing is selected, the original colors remain
	if (!args.hasSelections) {
		return args.elements;
	}

	// Manipulate the colors to highlight any selected elements
	return args.elements.map((element) => {
		const id: ComponentId = element[0];
		const parentId: ComponentId | null = args.inferParentFn(id);
		const color:
			| ModelElementColor
			| ModelJointColor
			| ModelJointSubResultColor = element[2];

		// If the element is selected, we should keep the colors
		const elementIsSelected = args.selectedComponentsOfType.includes(id);

		// If the parentId is selected, we should keep the colors
		const parentIdIsSelected =
			parentId !== null && args.selectedComponentsOfType.includes(parentId);

		// If the color is neutral, we keep the colors
		// This is a little bit of a hacky reverse engineering - could be improved
		const colorIsNeutral = [
			ColorLookup.forElement.neutral,
			ColorLookup.forJoint.none,
		].includes(color);

		if (elementIsSelected || parentIdIsSelected || colorIsNeutral) {
			return element;
		}

		// Otherwise we return a modified color
		const newColor = adjustColor(element[2]);

		const newElement = element.slice(0) as T;
		newElement[2] = newColor;

		return newElement;
	});
};

/**
 * We use a memo component to prevent renders when the navigation state changes
 * This may be changed in the future since a satisfactory solution to the click
 * vs navigation issues was not found
 */
// const ModelRenderMemo = React.memo(ModelRender);
const ModelRenderMemo = ModelRender;

/** Determine if an Element ComponentId represents a parent or child id */
const isParentElement = (id: ComponentId) => {
	const [, subId] = id.split('-');

	if (subId === undefined || subId !== '0') {
		return false;
	}

	return true;
};

// INSPIRATION
// https://www.autodesk.dk/collections/architecture-engineering-construction/overview

// WATER
// https://jsfiddle.net/prisoner849/5ktzxfuq/
