import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForgotPasswordMutation } from '../Core/Api';
import { Button } from '../Core/components/Button/Button';
import { FormInput } from '../Core/components/FormInput/FormInput';

export const ForgotPassword = () => (
	<>
		<h1 className="Login__Title">Reset password</h1>
		<ForgotPasswordForm />
	</>
);

const schema = z.object({
	email: z.string().email('Please check your e-mail'),
});

type Schema = z.infer<typeof schema>;

export const ForgotPasswordForm = () => {
	const navigate = useNavigate();
	const [forgotPassword] = useForgotPasswordMutation();

	// Setup React Hook Form handler
	const { control, handleSubmit } = useForm<Schema>({
		resolver: zodResolver(schema),
		mode: 'onBlur',
		shouldFocusError: true,
		defaultValues: {
			email: '',
		},
	});

	// Handle the submit call
	const onSubmit = handleSubmit(({ email }) => {
		forgotPassword({ email });
		navigate(`confirm?email=${email}`);
	});

	return (
		<div className="LoginForm">
			{/* Only used a a "spacer" in this case, to have the same spacing as login form */}
			<div className="LoginForm__Error">
				Could not sign in with provided credentials
			</div>

			<form className="LoginForm__Form" onSubmit={onSubmit}>
				<div className="LoginForm__Input">
					<FormInput
						theme="Login"
						control={control}
						name="email"
						label="E-mail"
					/>
				</div>

				<div className="LoginForm__Actions">
					<div className="LoginForm__Submit">
						<Button type="submit" theme="Blue" height="Large">
							Reset password
						</Button>
					</div>
					<div className="LoginForm__ForgotPassword">
						<Button
							type="button"
							theme="Grey"
							onClick={() => navigate('/login')}
						>
							Back to login
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};
