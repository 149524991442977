import classNames from 'classnames';
import { ReactNode } from 'react';
import { useGetUserQuery } from '../../Api';
import { useLogout } from '../../hooks/useLogout';
import { noop } from '../../utils/Function';
import { Icon } from '../Icon/Icon';

import './SideMenu.scss';

export enum SideMenuTheme {
	SIDEBAR = 'Sidebar',
	OVERLAY = 'Overlay',
}

type Props = {
	theme?: SideMenuTheme;
	children?: ReactNode;
	onUserClick?: () => void;
	onLogoClick?: () => void;
};

export const SideMenu = ({
	theme = SideMenuTheme.SIDEBAR,
	onUserClick = noop,
	onLogoClick = noop,
	children,
}: Props) => {
	const { data: userData } = useGetUserQuery({});
	const logout = useLogout();

	const userLetter = userData?.isLoggedIn ? userData.user.name[0] ?? '?' : '?';

	return (
		<div className={classNames('SideMenu', `SideMenu--Theme-${theme}`)}>
			<div className="SideMenu__Logo" onClick={onLogoClick}>
				<Icon name="RiamsLogo" width={40} />
			</div>
			<div className="SideMenu__Buttons">{children}</div>
			<div className="SideMenu__BottomActions">
				<div className="SideMenu__LogOut" onClick={() => logout()}>
					<Icon name="Power" width={16} />
				</div>
				<div className="SideMenu__User" onClick={onUserClick}>
					<div className="SideMenu__UserLetter">{userLetter}</div>
				</div>
			</div>
		</div>
	);
};
