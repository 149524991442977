import { Middleware } from '@reduxjs/toolkit';
import { history } from '../history';

/** Redirect to login screen if an unauthorized (401) response is received */
export const authorizationMiddleware: Middleware = () => (next) => (action) => {
	// Unathorized users should login first
	if (action?.payload?.status === 401) {
		history.push('/login');
	}

	return next(action);
};
