import { useGetUserQuery } from '../Core/Api';
import { Icon } from '../Core/components/Icon/Icon';
import { useLogout } from '../Core/hooks/useLogout';
import { UserCard } from './UserCard';
import './UserHeader.scss';

export const UserHeader = () => {
	const { data: userData } = useGetUserQuery({});
	const logout = useLogout();

	const userName = userData?.isLoggedIn ? userData.user.name : '';
	const email = userData?.isLoggedIn ? userData.user.email : '';

	return (
		<div className="UserHeader">
			<div className="UserHeader__Main">
				<UserCard name={userName} email={email} />
			</div>
			<div className="UserHeader__Controls">
				<div className="UserHeader__Control" onClick={() => logout()}>
					<Icon name="Power" width={16} />
				</div>
			</div>
		</div>
	);
};
