import { GroupProps, Intersection, ThreeEvent } from '@react-three/fiber';
import React, { useCallback, useState } from 'react';
import { noop } from '../../../Core/utils/Function';
import { ComponentUserData, isComponentUserData } from './SelectionMesh';

/** A comparison function to assess if two Instances are equal */
const isEqual = (a: Instance, b: Instance) => {
	return a.parentUUID === b.parentUUID && a.instanceNumber === b.instanceNumber;
};

export interface Instance {
	parentUUID: string;
	instanceNumber: number;
	userData: ComponentUserData;
}

const instanceFromIntersection = (
	intersection: Intersection
): Instance | 'invalid' => {
	const userData = intersection.object.userData;

	if (!isComponentUserData(userData)) {
		return 'invalid';
	}

	return {
		parentUUID: intersection.object.uuid,
		instanceNumber: intersection.instanceId ?? 0,
		userData,
	};
};

const nearestInstanceFromEvent = (
	event: ThreeEvent<PointerEvent>
): Instance | null => {
	if (event.intersections.length === 0) {
		return null;
	}

	const instance = instanceFromIntersection(event.intersections[0]);

	return instance !== 'invalid' ? instance : null;
};

type WrappedHandler<T> = (
	event: ThreeEvent<T>,
	instance: Instance | null
) => void;

/**
 * A mesh groups that adds hover and click events to a group of meshes
 * The mesh nearest the camera is always the one clicked or hovered
 */
const PointerGroupComponent = ({
	onClick = noop,
	onDoubleClick = noop,
	onHover = noop,
	...props
}: Omit<GroupProps, 'onClick' | 'onDoubleClick'> & {
	onClick?: WrappedHandler<MouseEvent>;
	onDoubleClick?: WrappedHandler<MouseEvent>;
	onHover?: WrappedHandler<PointerEvent>;
}) => {
	const [hoveredInstance, setHoveredInstance] = useState<Instance | null>(null);

	const handleHoverEvent = useCallback(
		(event: ThreeEvent<PointerEvent>) => {
			const nearestInstance = nearestInstanceFromEvent(event);

			if (nearestInstance === null) {
				if (hoveredInstance === null) {
					return;
				}

				setHoveredInstance(null);
				return onHover(event, null);
			}

			// Change the instance if nothing was previously hovered or the instance is different
			if (
				hoveredInstance === null ||
				!isEqual(nearestInstance, hoveredInstance)
			) {
				setHoveredInstance(nearestInstance);
				return onHover(event, nearestInstance);
			}
		},
		[hoveredInstance, onHover]
	);

	const handleClick = useCallback(
		(event: ThreeEvent<MouseEvent>) => onClick(event, hoveredInstance),
		[hoveredInstance, onClick]
	);

	const handleDoubleClick = useCallback(
		(event: ThreeEvent<MouseEvent>) => onDoubleClick(event, hoveredInstance),
		[hoveredInstance, onDoubleClick]
	);

	return (
		<group
			{...props}
			onPointerOver={handleHoverEvent}
			onPointerOut={handleHoverEvent}
			onClick={handleClick}
			onDoubleClick={handleDoubleClick}
		/>
	);
};

// We memo the component so as not to rerender when the internal state changes
export const PointerGroup = React.memo(PointerGroupComponent);
