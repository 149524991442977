import { useNavigate, useParams } from 'react-router-dom';
import {
	useAdminCreateSiteMutation,
	useAdminGetSiteQuery,
	useAdminUpdateSiteMutation,
} from '../../Core/Api';
import { BackButton } from '../../Core/components/SlideInOverlay/BackButton';
import { AdminSiteForm } from './AdminSiteForm';
import { toast } from 'react-toastify';
import { toastConfig } from '../../Core/configuration/toast';

export const AdminSiteEdit = () => {
	const navigate = useNavigate();
	const { accountId, selectedSiteId } = useParams<{
		accountId: string;
		selectedSiteId: string;
	}>();
	const [updateSite] = useAdminUpdateSiteMutation();
	const [createSite] = useAdminCreateSiteMutation();

	const { data } = useAdminGetSiteQuery({ siteId: selectedSiteId! });

	const navigateBack = () => {
		navigate(-1);
	};

	// Responsible for updating or create a site
	const handleUpdateSite = ({ name, slug }: { name: string; slug: string }) => {
		if (selectedSiteId === 'new') {
			createSite({
				args: {
					accountId: accountId!,
					name,
					slug,
				},
			}).then((response) => {
				if ('error' in response) {
					// @ts-ignore
					toast(response.error.data.message, toastConfig.error);
					return;
				}

				toast('Site created', toastConfig.success);
				navigateBack();
			});
		} else {
			updateSite({ id: selectedSiteId!, name, slug }).then((response) => {
				if ('error' in response) {
					// @ts-ignore
					toast(response.error.data.message, toastConfig.error);
					return;
				}
				toast('Site updated', toastConfig.success);
				navigateBack();
			});
		}
	};

	return (
		<div className="AdminAccountEdit">
			<div className="AdminAccountEdit__Top">
				<BackButton />
			</div>
			{(selectedSiteId === 'new' || (data && data?.site?.name)) && (
				<AdminSiteForm
					name={data?.site?.name || ''}
					slug={data?.site?.slug || ''}
					updateSite={handleUpdateSite}
				/>
			)}
		</div>
	);
};
