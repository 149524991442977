import { useMemo } from 'react';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import {
	ComponentSelectionId,
	deserializeComponentSelectionId,
	serializeComponentSelectionId,
	setComponentHighlight,
	stopComponentHighlight,
	toggleComponentSelection,
} from './Explorer.slice';
import {
	selectElementSortedDimension,
	selectHighlightedComponent,
	selectJointSortedDimension,
	selectSelectedComponents,
} from './Explorer.selectors';
import { useAppSelector } from '../../Core/redux/useAppSelector';
import { ModelJointFactory } from './models/ModelJoint.factory';
import { ModelElementFactory } from './models/ModelElement.factory';
import { useSectionResult } from '../UseSectionResult';
import { SectionResultElement } from '../../SharedTypes/API/Explorer';
import { ModelJointSubResultFactory } from './models/ModelJointSubResult.factory';
import { ModelExplorerProps } from './objects/ModelExplorer';

export const useAnalysisExplorerData = ({
	sectionId,
}: {
	sectionId: string;
}):
	| { modelData: ModelExplorerProps; seaLevel: number; soilLevel: number }
	| 'loading'
	| 'error' => {
	const dispatch = useAppDispatch();

	const { sectionResult, state: sectionResultState } = useSectionResult({
		sectionId,
	});

	const elementSortedDimension = useAppSelector(selectElementSortedDimension);
	const jointSortedDimension = useAppSelector(selectJointSortedDimension);

	const selectedComponents = useAppSelector(selectSelectedComponents);
	const highlightedComponent = useAppSelector(selectHighlightedComponent);

	// const showLoading = useMemo(
	// 	() => isLoading || isFetching,
	// 	[isLoading, isFetching]
	// );

	const joints = useMemo(() => {
		const joints = sectionResult?.joints.results;

		if (typeof joints === 'undefined') {
			return [];
		}

		// Get the parent results
		const parents: SectionResultElement[] = joints
			// Map to a simpler SectionResultElement
			.map(
				([id, name, type, coordinates, dimensionResults]) =>
					[
						id,
						name,
						type,
						coordinates,
						dimensionResults,
					] as SectionResultElement
			);

		// Map to ModelJoints
		const out = parents.map((sectionResultElement) =>
			ModelJointFactory.fromSectionResult({
				sectionResultElement,
				selectedDimension: jointSortedDimension,
			})
		);

		return out;
	}, [sectionResult, jointSortedDimension]);

	const jointSubResults = useMemo(() => {
		const joints = sectionResult?.joints.results;

		if (typeof joints === 'undefined') {
			return [];
		}

		// Get the subResults
		const parents: SectionResultElement[] = joints
			// Map to a simpler SectionResultElement
			.flatMap((parent) => {
				const children: SectionResultElement[] = parent[6];

				return children ? children : [];
			});

		// Map to ModelJointSubResultss
		const out = parents.map((sectionResultElement) =>
			ModelJointSubResultFactory.fromSectionResult({
				sectionResultElement,
				selectedDimension: jointSortedDimension,
			})
		);

		return out;
	}, [sectionResult, jointSortedDimension]);

	const elements = useMemo(() => {
		const elements = sectionResult?.elements.results;

		if (typeof elements === 'undefined') {
			return [];
		}

		// Get the parent elements
		// Filter away any parents with children, since
		// they should be visualized as children only
		const parents: SectionResultElement[] = elements
			// We don't want the parent element if there are child elements
			.filter((el) => {
				const childrenAreIncluded = el[6].length > 0;
				return !childrenAreIncluded;
			})
			// Map to a simpler SectionResultElement
			.map(
				([id, name, type, coordinates, dimensionResults]) =>
					[
						id,
						name,
						type,
						coordinates,
						dimensionResults,
					] as SectionResultElement
			);

		// Get any children elements in the result
		const children: SectionResultElement[] = elements.flatMap((parent) => {
			return parent[6] as SectionResultElement[];
		});

		// Add children elements alongside the parents
		const allElements = parents.concat(children);

		// The default color for beams should be none when there are no results and neutral when there are any results
		const defaultColor = [elementSortedDimension, jointSortedDimension].some(
			(v) => v !== null
		)
			? 'neutral'
			: 'none';

		// Map to ModelElements
		const out = allElements.map((sectionResultElement) =>
			ModelElementFactory.fromSectionResult({
				sectionResultElement,
				selectedDimension: elementSortedDimension,
				defaultColor,
			})
		);

		return out;
	}, [sectionResult, elementSortedDimension, jointSortedDimension]);

	const seaLevel = useMemo(
		() => sectionResult?.sea.seaLevel ?? 0,
		[sectionResult]
	);

	const soilLevel = useMemo(
		() => sectionResult?.sea.soilLevel ?? -55,
		[sectionResult]
	);

	function onComponentClick(componentSelectionId: ComponentSelectionId) {
		// Logic to select the parent element when a child is clicked
		const { componentId, componentType } =
			deserializeComponentSelectionId(componentSelectionId);

		const componentSelectionIdToToggle = (() => {
			// If the type is an element, we check if it has a parent
			if (componentType === 'element') {
				// Get the inferred parent
				const inferredParentId = ModelElementFactory.inferParentId(componentId);

				// Serialize as the parent id if defined, otherwise return the original id
				return inferredParentId === null
					? componentSelectionId
					: serializeComponentSelectionId({
							componentType,
							componentId: inferredParentId,
					  });
			}

			// If it is a joint we do nothing
			return componentSelectionId;
		})();

		console.log(
			'Component selected',
			componentSelectionId,
			componentSelectionIdToToggle
		);
		dispatch(
			toggleComponentSelection({
				componentSelectionId: componentSelectionIdToToggle,
			})
		);
	}

	function onComponentHover(componentSelectionId: ComponentSelectionId | null) {
		dispatch(
			componentSelectionId === null
				? stopComponentHighlight({})
				: setComponentHighlight({
						componentSelectionId: componentSelectionId,
				  })
		);
	}

	if (sectionResultState === 'loading') {
		return 'loading';
	}

	if (sectionResultState === 'error') {
		return 'error';
	}

	const modelData: ModelExplorerProps = {
		joints,
		jointSubResults,
		elements,
		onComponentClick,
		onComponentHover,
		highlightedComponent,
		selectedComponents,
	};

	return {
		modelData,
		seaLevel,
		soilLevel,
	};
};
