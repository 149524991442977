import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminGetSiteDimensionsQuery } from '../../Core/Api';
import { List } from '../../Core/components/List/List';
import { ListItem } from '../../Core/components/List/ListItem';
import { ListLoading } from '../../Core/components/List/ListLoading';
import { Dimension } from '../../SharedTypes/API/Explorer';
import { AdminLayout } from './AdminLayout';
import './AdminSiteDimension.scss';

type ExtendedDimension = {
	category: string;
} & Dimension;

export const AdminSiteDimension = () => {
	const { selectedSiteId } = useParams<{ selectedSiteId: string }>();
	const navigate = useNavigate();

	const { data: adminData } = useAdminGetSiteDimensionsQuery({
		siteId: selectedSiteId!,
	});

	const memoizedFlattenedElementDimensions = useMemo(() => {
		if (!adminData) {
			return [];
		}

		return adminData?.dimensionCategories.reduce((accumulator, category) => {
			const dimensions = category.dimensions.map((dimension) => {
				return {
					...dimension,
					category: category.name,
				};
			});

			return [...accumulator, ...dimensions];
		}, [] as ExtendedDimension[]);
	}, [adminData]);

	const handleSelectedItem = (dimensionId: string) => (action: string) => {
		if (action === 'edit') {
			navigate(`${dimensionId}`);
		}

		if (action === 'delete') {
			const answer = window.confirm('Delete dimension element?');
			if (answer) {
				// deleteSite({ dimensionId });
			}
		}
	};

	return (
		<AdminLayout title="Dimensions">
			<div className="AdminSiteDimension__List">
				<List headline="Elements">
					{memoizedFlattenedElementDimensions.length === 0 && (
						<ListItem templateColumns="1fr">
							<ListLoading text="No dimensions to display" />
						</ListItem>
					)}
					{memoizedFlattenedElementDimensions.map((dimension) => (
						<ListItem
							key={dimension.id}
							handleMenuItemSelected={handleSelectedItem(dimension.id)}
							templateColumns="3fr 1fr 1fr 1fr"
						>
							<div>
								<p>{dimension.name}</p>
								<p>{dimension.id}</p>
							</div>
							<p>{dimension.category}</p>
							<p>Active</p>
						</ListItem>
					))}
				</List>
			</div>
		</AdminLayout>
	);
};
