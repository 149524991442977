export const upperFirst = (str: string) => {
	if (str.length === 0) {
		return str;
	}

	const [first, ...rest] = str.split('');

	return `${first.toUpperCase()}${rest.join('').toLowerCase()}`;
};

export const formatCamelCase = (text: string) => {
	return text
		.replace('_', ' ')
		.trimStart()
		.trimEnd()
		.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};
