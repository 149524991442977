import { ColorLookup } from '../../../Core/utils/ColorLookup';
import {
	ComponentId,
	Coordinate,
	DimensionId,
	NodeDTO,
	Result,
	ResultColor,
	SectionResultElement,
} from '../../../SharedTypes/API/Explorer';
import {
	ModelJoint,
	ModelJointColor,
	ModelJointCoordinate,
	ModelJointType,
} from './ModelJoint.entity';

// We define specific mappers to stay true to our Value Objects
const createJointColor = (input: string): ModelJointColor =>
	input as ModelJointColor;
const createJointType = (): ModelJointType => 'joint' as ModelJointType;
const createJointCoordinate = (input: Coordinate): ModelJointCoordinate =>
	input as number as ModelJointCoordinate;

const fromNodeDTO = (args: { color?: string } = {}) => {
	const color = args.color ?? ColorLookup.forJoint['none'];

	return (node: NodeDTO): ModelJoint => {
		const [id, , x, y, z] = node;

		return [
			id,
			createJointType(),
			createJointColor(color),
			createJointCoordinate(x),
			createJointCoordinate(y),
			createJointCoordinate(z),
		] as ModelJoint;
	};
};

const fromSectionResult = (args: {
	sectionResultElement: SectionResultElement;
	selectedDimension: DimensionId | null;
}): ModelJoint => {
	const id: ComponentId = args.sectionResultElement[0];
	const coordinates: [x: number, y: number, z: number] =
		args.sectionResultElement[3][0];
	const dimensionResults: {
		[dimensionId: string]: Result;
	} = args.sectionResultElement[4];

	let color: ResultColor = args.selectedDimension
		? dimensionResults?.[args.selectedDimension]?.[2] ?? 'neutral'
		: 'none';

	const hexColor = ColorLookup.forJoint[color];

	return [
		id,
		createJointType(),
		createJointColor(hexColor),
		...coordinates,
	] as ModelJoint;
};

export const ModelJointFactory = {
	fromNodeDTO,
	fromSectionResult,
};
