import { FC, ReactNode } from 'react';
import { Search } from '../../Core/components/Search/Search';
import { AdminBreadcrumb } from './AdminBreadcrumb';
import './AdminLayout.scss';

type Props = {
	title: string;
	searchTerm?: string;
	searchHandler?: (searchTerm: string) => void;
	children: ReactNode;
};

export const AdminLayout: FC<Props> = ({
	children,
	title,
	searchTerm,
	searchHandler,
}) => {
	const handleClearInput = () => {
		if (searchHandler) {
			searchHandler('');
		}
	};

	return (
		<main className="AdminLayout">
			<header className="AdminLayout__Header">
				<div className="AdminLayout__HeaderTop">
					<div className="AdminLayout__Search">
						{searchHandler && (
							<Search
								value={searchTerm ?? ''}
								onChange={(e) => searchHandler(e.target.value)}
								clearInput={handleClearInput}
							/>
						)}
					</div>
					<div className="AdminLayout__Logo">
						{/* <Icon name="RambollLogo" width={82} /> */}
					</div>
				</div>
				<div className="AdminLayout__Title">
					<h1>{title}</h1>
				</div>
				<div className="AdminLayout__TitleBar">
					<AdminBreadcrumb />
				</div>
			</header>
			<section className="AdminLayout__Content">{children}</section>
		</main>
	);
};
