import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ElementType } from '../../../Core/types/ElementType';

export enum ElementDetailsState {
	LOADING = 'LOADING',
	LOADERROR = 'LOADERROR',
	IDLE = 'IDLE',
	CLOSED = 'CLOSED',
}

interface SliceState {
	state: ElementDetailsState;
	elementDetails: { id: string; type: ElementType } | null;
}

const initialState: SliceState = {
	state: ElementDetailsState.CLOSED,
	elementDetails: null,
};

export const elementDetails = createSlice({
	name: 'elementDetails',
	initialState,
	reducers: {
		open: (state, action: PayloadAction<{ elementId: string }>) => {
			state.state = ElementDetailsState.IDLE;
			state.elementDetails = {
				id: action.payload.elementId,
				type: ElementType.NODE,
			};
		},
		close: (state) => {
			state.state = ElementDetailsState.CLOSED;
			state.elementDetails = null;
		},
	},
});

// Action creators are generated for each case reducer function
export const { open, close } = elementDetails.actions;

export const elementDetailsReducer = elementDetails.reducer;
