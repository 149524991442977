import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// We create a state-machine-like state with two different flows
type SliceState =
	| {
			state: 'default';
	  }
	| {
			state: 'onboarding';
			email: string;
			oldPassword: string;
	  };

const initialState: SliceState = {
	state: 'default',
} as SliceState;

export const login = createSlice({
	name: 'login',
	initialState,
	reducers: {
		resetLoginFlow() {
			return {
				state: 'default',
			};
		},
		startOnboardingFlow(
			_,
			action: PayloadAction<{
				email: string;
				oldPassword: string;
			}>
		) {
			return {
				state: 'onboarding',
				email: action.payload.email,
				oldPassword: action.payload.oldPassword,
			};
		},
	},
});

export const { resetLoginFlow, startOnboardingFlow } = login.actions;
