// import { ThreeEvent } from '@react-three/fiber';
import React, { ReactNode, Ref, useEffect, useRef, useState } from 'react';
import {
	BufferGeometry,
	Color,
	DoubleSide,
	FrontSide,
	InstancedMesh,
	Material,
	Matrix4,
	Vector3,
} from 'three';
import { mergeRefs } from '../../../Core/utils/mergeRefs';
import { ComponentId } from '../../../SharedTypes/API/Explorer';
import { ModelElement } from '../models/ModelElement.entity';
import { ModelJoint } from '../models/ModelJoint.entity';
import { ModelJointSubResult } from '../models/ModelJointSubResult.entity';

/**
 * Data saved in the mesh to look up the component id on pointer events
 */
export type ComponentUserData = {
	category: 'element' | 'joint';
	componentIds: ComponentId[];
};

/** Guard to ensure that user data is in the correct format */
export function isComponentUserData(test: any): test is ComponentUserData {
	return (
		test !== null &&
		'category' in test &&
		['element', 'joint', 'brace'].includes(test.category) &&
		Array.isArray(test.componentIds)
	);
}

interface Props<T extends ModelElement | ModelJoint | ModelJointSubResult> {
	components: T[];
	InstancedGeometry: ({ children }: { children: ReactNode }) => JSX.Element;
	placementFn: (element: T) => Matrix4;
	opacity?: number;
	category: 'element' | 'joint' | 'brace' | 'sensor';
	sides?: 'double' | 'single';
	shadows?: boolean;
}

export const SelectionMesh = <
	T extends ModelElement | ModelJoint | ModelJointSubResult
>({
	components,
	InstancedGeometry,
	placementFn,
	opacity = 1,
	category,
	instanceRef = React.createRef<InstancedMesh>(),
	sides = 'single',
	shadows = true,
}: Props<T> & { instanceRef?: Ref<InstancedMesh> }) => {
	// Refrence for the instanced mesh
	const meshRef = useRef<InstancedMesh>();

	// When the nodes change, place them at their correct positions
	// according to the given placement function
	useEffect(() => {
		components.forEach((component, i) => {
			meshRef.current?.setMatrixAt(i, placementFn(component));
		});
		if (meshRef.current) {
			meshRef.current.instanceMatrix.needsUpdate = true;
		}
	}, [components, placementFn, meshRef]);

	// When the nodes change, save their ids as userData on
	// the instancedMesh so parent components can look up
	// the component id from the instanceId
	useEffect(() => {
		if (meshRef.current) {
			const componentIds = components.map((component) => {
				// We do it this verbosely for type safety
				const id: ComponentId = component[0];

				return id;
			});

			meshRef.current.userData = {
				category,
				componentIds,
			} as ComponentUserData;
		}
	}, [components, placementFn, meshRef, category]);

	const [colorArray, setColorArray] = useState<Float32Array>(
		new Float32Array()
	);

	// Reset the array when hovered is null
	// This prevents stray colored geometry from building up
	useEffect(() => {
		setColorArray(
			createColorArray({
				components,
			})
		);
	}, [components]);

	return (
		<instancedMesh
			scale={new Vector3(1, 1, -1)}
			ref={mergeRefs(instanceRef, meshRef)}
			args={[
				null as unknown as BufferGeometry,
				null as unknown as Material,
				components.length,
			]}
			castShadow={shadows}
			receiveShadow={shadows}
		>
			<InstancedGeometry>
				<instancedBufferAttribute
					attachObject={['attributes', 'color']}
					args={[colorArray, 3]}
				/>
			</InstancedGeometry>

			<meshPhongMaterial
				shininess={0}
				transparent={opacity < 1} // Note this messes with the z order and requires manual ordering
				opacity={opacity}
				side={sides === 'double' ? DoubleSide : FrontSide}
				vertexColors
			/>
		</instancedMesh>
	);
};

/**
 * A memo class for getting a color as a ThreeJS color
 * Memoizes so duplicate colors are not calculated multiple times
 */
class ColorMemo {
	private colorMap = new Map<string, number[]>();

	// Get either the memoized value or create a new one
	get(value: string): number[] {
		const memoColor = this.colorMap.get(value);

		if (memoColor !== undefined) {
			return memoColor;
		}

		const color = this.creator(value);

		this.colorMap.set(value, color);

		return color;
	}

	private creator(value: string): number[] {
		return new Color(value).convertSRGBToLinear().toArray();
	}
}

function createColorArray<
	T extends ModelElement | ModelJoint | ModelJointSubResult
>({
	components,
}: // hoveredComponent,
// selectedComponent,
{
	components: T[];
	// hoveredComponent: string | null;
	// selectedComponent: string | null;
}) {
	// Use a memoized creator to improve performance by reusing the color creation logic
	const colorMemo = new ColorMemo();

	const newArray = Float32Array.from(
		components.flatMap((component) => colorMemo.get(component[2]))
	);

	return newArray;
}
