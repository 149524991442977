import { ReactNode } from 'react';

import './Configurator.scss';

type Props = {
	children: ReactNode;
};

export const Configurator = ({ children }: Props) => {
	return <div className="Configurator">{children}</div>;
};
