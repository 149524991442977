import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, useLogoutUserMutation } from '../Api';
import { useAppDispatch } from '../redux/useAppDispatch';

/** Hook that returns a function that logs out the user, resets cache and navigates to root */
export const useLogout = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [logoutUser, logoutResponse] = useLogoutUserMutation();

	// When the user has been logged out
	useEffect(() => {
		if (logoutResponse.isSuccess === true) {
			// Remove any cached queries from RTK
			dispatch(api.util.resetApiState());

			// Navigate to root
			navigate('/');
		}
	}, [dispatch, logoutResponse.isSuccess, navigate]);

	// Return a function that triggers the logoutUser command
	return () => {
		logoutUser({});
	};
};
