import React from 'react';

import './ElementDetailsHeader.scss';
import { IconButton } from '../../../Core/components/IconButton/IconButton';
import { ElementType } from './ElementType';
import { ElementType as ElementTypeType } from '../../../Core/types/ElementType';

type Props = {
	onBackClick: () => void;
	elementId: string;
	elementType: string;
};

export const ElementDetailsHeader = ({
	onBackClick,
	elementId,
	elementType,
}: Props) => {
	return (
		<header className="ElementDetailsHeader">
			<div className="ElementDetailsHeader__Back">
				<IconButton onClick={onBackClick} icon="CircleArrowLeft">
					Back
				</IconButton>
			</div>
			<div className="ElementDetailsHeader__Details">
				<div className="ElementDetailsHeader__Identifier">{elementId}</div>
				<div className="ElementDetailsHeader__Type">
					<ElementType type={elementType as ElementTypeType} />
				</div>
			</div>
		</header>
	);
};
