import './Explorer.scss';
import { Canvas } from '@react-three/fiber';
import { Stage } from './objects/Stage';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { isDefined } from '../../Core/utils/Logic';
import { Suspense } from 'react';
import { ModelExplorer } from './objects/ModelExplorer';
import { SoilLevel } from './objects/SoilLevel';
import { WaterAnimated } from './objects/Water';
import { useSectionSelectorData } from './useSectionSelectorData';
import { TitleBarMenu } from '../../Core/components/TitleBarMenu/TitleBarMenu';
import { useSectionMenu } from './useSectionMenuItems';
import { TitleBar } from '../../Core/components/TitleBar/TitleBar';
import { ExplorerNavigation } from './ExplorerNavigation';
import { useAnalysisExplorerData } from './useAnalysisExplorerData';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import { useAppSelector } from '../../Core/redux/useAppSelector';
import { setSelectedSensor } from './Explorer.slice';
import { noop } from '../../Core/utils/Function';
import { ACESFilmicToneMapping } from 'three';

export const Explorer = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const location = useLocation();

	// Get siteId from URL params
	const { siteId } = useParams<{
		siteId: string;
	}>();

	const sensorLocations = useAppSelector(
		(state) => state.explorer.sensorLocations
	);

	const selectedSensorId = useAppSelector(
		(state) => state.explorer.selectedSensorId
	);
	const dispatchSelectedSensorId = (id: string | null) =>
		dispatch(setSelectedSensor(id));

	const getSectionId = () => {
		const splitPath = location.pathname.split('/');
		if (splitPath.at(-1) === 'analysis') {
			return splitPath.at(-2);
		}

		if (['explorer', 'dashboard', 'trends'].includes(splitPath.at(-1) ?? '')) {
			return undefined;
		}

		return splitPath.at(-1);
	};

	const sectionId = getSectionId();

	const handleSectionSelect = (id: string) => {
		navigate(`explorer/${id}`);
	};

	const isExplorer = location.pathname.includes('explorer');
	const isDashboard = location.pathname.includes('dashboard');
	const isTrends = location.pathname.includes('trends');

	const data = useSectionSelectorData({
		siteId: siteId ?? '',
		onSectionSelect:
			!sectionId && !isDashboard && !isTrends ? handleSectionSelect : undefined,
	});

	const analysisData = useAnalysisExplorerData({ sectionId: sectionId ?? '' });

	/**
	 * Toggle the Analysis Explorer panel
	 */
	function handleToggleAnalysisExplorer() {
		if (location.pathname.split('/').at(-1) === 'analysis') {
			return;
		}
		navigate(location.pathname + '/analysis');
	}

	// Get data for the section menu
	const sectionMenu = useSectionMenu();

	return (
		<div className="Explorer">
			<div className="Explorer__Model">
				{sectionMenu.isLoaded && isExplorer && (
					<div className="Explorer__SectionMenu">
						<TitleBarMenu
							theme="Overlay"
							items={sectionMenu.items}
							onSelectItem={sectionMenu.onSelectItem}
						>
							{sectionMenu.currentSelection}
						</TitleBarMenu>
					</div>
				)}

				{sectionMenu.isLoaded && !isExplorer && (
					<div className="Dashboard__SiteTitle">
						<TitleBar theme="Overlay">{sectionMenu.currentSelection}</TitleBar>
					</div>
				)}

				<Canvas
					gl={{
						logarithmicDepthBuffer: true,
						toneMapping: ACESFilmicToneMapping,
						toneMappingExposure: 0.35,
					}}
					linear={false}
					shadows
				>
					<Suspense fallback={<></>}>
						{(!sectionId || isDashboard || isTrends) &&
						data !== 'error' &&
						data !== 'loading' ? (
							<>
								<ModelExplorer
									{...data.modelData}
									sensorLocations={sensorLocations}
									selectedSensorId={selectedSensorId}
									setSelectedSensorId={dispatchSelectedSensorId}
								/>

								{isDefined(data.soilLevel) && (
									<SoilLevel level={data.soilLevel} />
								)}

								{isDefined(data.seaLevel) && (
									<WaterAnimated level={data.seaLevel} />
								)}
							</>
						) : (
							analysisData !== 'error' &&
							analysisData !== 'loading' && (
								<>
									<ModelExplorer
										{...analysisData.modelData}
										selectedSensorId={null}
										setSelectedSensorId={noop}
									/>

									{isDefined(analysisData.soilLevel) && (
										<SoilLevel level={analysisData.soilLevel} />
									)}

									{isDefined(analysisData.seaLevel) && (
										<WaterAnimated level={analysisData.seaLevel} />
									)}
								</>
							)
						)}

						<Stage />

						<PerspectiveCamera
							makeDefault
							position={[230, 80, 190]}
							near={5}
							far={100000}
						/>
						<OrbitControls
							enableDamping
							dampingFactor={0.1}
							rotateSpeed={0.5}
							zoomSpeed={1.5}
						/>
					</Suspense>
				</Canvas>

				{isExplorer && sectionId && (
					<div className="Explorer__Navigation">
						<ExplorerNavigation
							onToggleAnalysisExplorer={handleToggleAnalysisExplorer}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
