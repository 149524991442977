import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminGetSiteQuery } from '../../Core/Api';
import { List } from '../../Core/components/List/List';
import { ListItem } from '../../Core/components/List/ListItem';
import { AdminLayout } from './AdminLayout';

export const AdminSite = () => {
	const navigate = useNavigate();
	const { selectedSiteId } = useParams<{ selectedSiteId: string }>();
	const { data } = useAdminGetSiteQuery({ siteId: selectedSiteId! });

	const memoizedSiteName = useMemo(() => {
		return (data && data.site && data.site.name) ?? 'Site';
	}, [data]);

	return (
		<AdminLayout title={memoizedSiteName}>
			<List>
				<ListItem handleClick={() => navigate('users')}>
					<p>Manage users</p>
				</ListItem>
				<ListItem handleClick={() => navigate('models')}>
					<p>Models</p>
				</ListItem>
				<ListItem handleClick={() => navigate('dimensions')}>
					<p>Dimensions</p>
				</ListItem>
			</List>
		</AdminLayout>
	);
};
