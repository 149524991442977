import { ReactNode } from 'react';

import './Modal.scss';

type Props = {
	children: ReactNode;
	title: string;
	footerContent: ReactNode;
	width?: string;
};

export const Modal = ({
	children,
	title,
	footerContent,
	width = '600px',
}: Props) => {
	const style = {
		width,
	};

	return (
		<>
			<div className="ModalOverlay">
				<div className="Modal" style={style}>
					<div className="Modal__Header">
						<div
							className="Modal__Title"
							dangerouslySetInnerHTML={{ __html: title }}
						></div>
					</div>
					<div className="Modal__Body">{children}</div>
					<div className="Modal__Footer">{footerContent}</div>
				</div>
			</div>
		</>
	);
};
