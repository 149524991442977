import { useFrame } from '@react-three/fiber';
import { FC, useMemo, useState } from 'react';
import { Vector3 } from 'three';
import { Color } from 'three';
import { SensorLocation } from '../../../SharedTypes/API/Dashboard';

type Props = {
	sensors: SensorLocation[];
	selectedId: string | null;
	setSelectedId?: (id: string | null) => void;
};

export const SensorMesh: FC<Props> = ({
	sensors = [],
	selectedId,
	setSelectedId,
}) => {
	const [radius, setRadius] = useState(1.9);

	useFrame(() => {
		// Redementary animation on hover, not perfect, but works
		if (selectedId) {
			if (radius >= 6) {
				setRadius(1);
			} else {
				setRadius((r) => r + 0.09);
			}
		}

		if (!selectedId && radius > 1) {
			setRadius(1);
		}
	});

	const getShouldAnimate = useMemo(
		() => (id: string) => {
			return id === selectedId;
		},
		[selectedId]
	);

	const toggleHoverState = (id: string | null) => {
		if (!setSelectedId) {
			return;
		}

		if (!id) {
			setSelectedId(null);
			return;
		}

		setSelectedId(id);
	};

	return (
		<group name="Sensors">
			{sensors.map((sensor) => {
				const { color, coordinates, metricIdentifier, renderOrder } = sensor;
				if (!Array.isArray(coordinates)) {
					return null;
				}
				const threeColor = new Color(color);

				return coordinates.map(([x, z, y], index) => {
					const corretedZ = z * -1;
					return (
						<group
							key={metricIdentifier + index}
							renderOrder={renderOrder + 100 ?? 0}
						>
							{/* The pulsating animation */}
							<mesh
								position={new Vector3(x, y, corretedZ)}
								onPointerEnter={() => toggleHoverState(metricIdentifier)}
								onPointerLeave={() => toggleHoverState(null)}
								renderOrder={0}
							>
								<sphereGeometry
									args={[getShouldAnimate(metricIdentifier) ? radius : 1, 32]}
								></sphereGeometry>
								<meshBasicMaterial
									depthWrite={false}
									color={threeColor}
									opacity={0.2}
									transparent
								/>
							</mesh>
							{/* The actual dot */}
							<mesh
								visible
								position={new Vector3(x, y, corretedZ)}
								renderOrder={sensor.metricIdentifier === selectedId ? 2 : 1}
							>
								<sphereGeometry args={[1.1, 32]}></sphereGeometry>
								<meshPhysicalMaterial color={threeColor} opacity={1} />
							</mesh>
						</group>
					);
				});
			})}
		</group>
	);
};
