/** Determine if an array includes a given value */
export const includes =
	<T extends unknown>(arr: T[]) =>
	(value: T) =>
		arr.includes(value);

export const any = (arr: any[]) => arr.length > 0;

export const last = <T>(arr: T[]) =>
	arr.length > 0 ? arr[arr.length - 1] : null;

export const createArrayWith = <T>(numberOfItems: number, item: T): T[] =>
	Array.from({ length: numberOfItems }, () => item);
