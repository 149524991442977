import classNames from 'classnames';
import { FC } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { FormTheme } from '../../types/FormTheme';
import './ToggleButton.scss';

type Props = {
	theme: FormTheme;
	field: ControllerRenderProps<any, string>;
	disabled?: boolean;
};

export const ToggleButton: FC<Props> = ({ theme, field, disabled = false }) => (
	<button
		type="button"
		className={classNames(['ToggleButton', `ToggleButton--${theme}`], {
			'ToggleButton--On': field.value,
			'ToggleButton--Disabled': disabled,
		})}
		onClick={() => field.onChange(!field.value)}
	>
		<div
			className={classNames(['ToggleButton__Tick'], {
				'ToggleButton__Tick--On': field.value,
			})}
		></div>
	</button>
);
