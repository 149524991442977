import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { close, ElementDetailsState } from './elementDetails.slice';
import { RootState } from '../../../Core/redux/store';
import { SlideInOverlay } from '../../../Core/components/SlideInOverlay/SlideInOverlay';
import { ElementDetailsOverlay } from './ElementDetailsOverlay';

export const ElementDetailsOverlayContainer = () => {
	const elementDetailsState = useSelector(
		(state: RootState) => state.elementDetails.state
	);
	const elementDetails = useSelector(
		(state: RootState) => state.elementDetails.elementDetails
	);

	const dispatch = useDispatch();

	function closePopup() {
		dispatch(close());
	}

	return (
		<SlideInOverlay
			width="768px"
			isOpen={elementDetailsState === ElementDetailsState.IDLE}
		>
			{elementDetailsState === ElementDetailsState.IDLE && elementDetails && (
				<ElementDetailsOverlay
					onClose={closePopup}
					id={elementDetails.id}
					type={elementDetails.type}
				/>
			)}
			{elementDetailsState === ElementDetailsState.LOADING && (
				<div>Loading...</div>
			)}
			{elementDetailsState === ElementDetailsState.LOADERROR && (
				<div>Error...</div>
			)}
		</SlideInOverlay>
	);
};
