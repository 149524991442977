import { FC } from 'react';
import { createPortal } from 'react-dom';
import { ActionModal } from '../../Core/components/ActionModal/ActionModal';
import { Button } from '../../Core/components/Button/Button';
import './AdminModals.scss';

type Props = {
	name: string;
	respond: (responseValue: boolean) => void;
};

export const AdminRemoveUserModal: FC<Props> = ({ name, respond }) => {
	const rootNode = document.getElementById('modalRoot');

	const modalElement = (
		<ActionModal
			title="Remove user"
			footerContent={<AdminModalActions respond={respond} />}
		>
			<div className="AdminRemoveUserModal">
				Are you sure you want to remove {name} from the site?
			</div>
		</ActionModal>
	);

	return createPortal(modalElement, rootNode!);
};

export const AdminRemoveSiteModal: FC<Props> = ({ name, respond }) => {
	const rootNode = document.getElementById('modalRoot');

	const modalElement = (
		<ActionModal
			title="Delete site"
			footerContent={<AdminModalActions respond={respond} />}
		>
			<div className="AdminRemoveUserModal">
				Are you sure you want to delete {name}?
			</div>
		</ActionModal>
	);

	return createPortal(modalElement, rootNode!);
};

export const AdminRemoveAccountModal: FC<Props> = ({ name, respond }) => {
	const rootNode = document.getElementById('modalRoot');

	const modalElement = (
		<ActionModal
			title="Delete account"
			footerContent={<AdminModalActions respond={respond} />}
		>
			<div className="AdminRemoveUserModal">
				Are you sure you want to delete {name}?
			</div>
		</ActionModal>
	);

	return createPortal(modalElement, rootNode!);
};

const AdminModalActions: FC<Pick<Props, 'respond'>> = ({ respond }) => {
	return (
		<div className="AdminModalActions">
			<Button theme="OutlineBlue" onClick={() => respond(false)}>
				Cancel
			</Button>
			<Button theme="Blue" onClick={() => respond(true)}>
				Confirm
			</Button>
		</div>
	);
};
