import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	useAdminGetSiteUserQuery,
	useAdminRemoveSiteUserMutation,
	useAdminUpdateSiteUserMutation,
} from '../../Core/Api';
import { Button } from '../../Core/components/Button/Button';
import { FormInput } from '../../Core/components/FormInput/FormInput';
import { Icon } from '../../Core/components/Icon/Icon';
import { BackButton } from '../../Core/components/SlideInOverlay/BackButton';
import { toastConfig } from '../../Core/configuration/toast';
import { User } from '../../SharedTypes/API/Admin';
import { AdminRemoveUserModal } from './AdminModals';

export const AdminSiteUserEdit = () => {
	const [userToRemove, setUserToRemove] = useState<User | null>(null);
	const navigate = useNavigate();
	const { selectedSiteId, userId } = useParams<{
		selectedSiteId: string;
		userId: string;
	}>();

	const [updateSiteUser] = useAdminUpdateSiteUserMutation();
	const [removeSiteUser] = useAdminRemoveSiteUserMutation();

	const { data, isLoading } = useAdminGetSiteUserQuery({
		userId: userId!,
		siteId: selectedSiteId!,
	});

	const navigateBack = () => {
		navigate(-1);
	};

	const handleModalClose = (doAction: boolean) => {
		// If true, then remove the user
		if (!doAction) {
			setUserToRemove(null);
			return;
		}
		// Request the backend to remove the user from the site.
		removeSiteUser({ siteId: selectedSiteId!, userId: userId! }).then(
			(response) => {
				if ('error' in response) {
					// @ts-ignore
					toast(response.error.data.message, toastConfig.error);
					return;
				}

				toast('User removed', toastConfig.success);
				setUserToRemove(null);
				navigateBack();
			}
		);
	};

	const handleUpdateUser = (values: { name: string; role: string }) => {
		// Send a request to Cognito to update the users name attribute
		updateSiteUser({
			siteId: selectedSiteId!,
			userId: userId!,
			accessLevel: values.role === 'User' ? values.role : 'User',
		}).then((response) => {
			if ('error' in response) {
				// I have a typing issue here, but I don't know how to fix it
				//@ts-ignore
				toast(response.error.data.message, toastConfig.error);
				return;
			} else {
				toast(`User updated`, toastConfig.success);
				navigateBack();
			}
		});
	};

	const handleRemoveUser = () => {
		setUserToRemove(data!.user);
	};

	return (
		<div className="AdminAccountEdit">
			{userToRemove && (
				<AdminRemoveUserModal
					respond={handleModalClose}
					name={userToRemove.name}
				/>
			)}
			<div className="AdminAccountEdit__Top">
				<BackButton />
			</div>
			{!isLoading && data?.user && (
				<AdminSiteUserForm
					user={data?.user}
					updateUser={handleUpdateUser}
					removeUser={handleRemoveUser}
				/>
			)}
		</div>
	);
};

const AdminSiteUserForm = ({
	user,
	updateUser,
	removeUser,
}: {
	user: User;
	updateUser: (args: { name: string; role: string }) => void;
	removeUser: () => void;
}) => {
	const { handleSubmit, control } = useForm({
		defaultValues: {
			name: user.name,
			role: user.accessLevel,
		},
	});

	const saveChanges = (values: { name: string; role: string }) => {
		return updateUser(values);
	};

	return (
		<form className="AdminAccountForm" onSubmit={handleSubmit(saveChanges)}>
			<FormInput control={control} label="Name" name="name" theme="Admin" />
			<FormInput
				control={control}
				label="Role"
				name="role"
				theme="Admin"
				inputType="select"
				options={[{ key: 'User', label: 'User' }]}
			/>

			<div className="AdminAccountForm__Actions">
				<Button theme="Grey" onClick={() => removeUser()}>
					<div style={{ display: 'flex', gap: '8px' }}>
						<Icon name="Trashcan" width={14} />
						<p>Remove user</p>
					</div>
				</Button>
			</div>

			<Button type="submit" theme="Blue">
				Save
			</Button>
		</form>
	);
};
