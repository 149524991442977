import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';

import './TitleBar.scss';

export type TitleBarTheme = 'Default' | 'Overlay';

export type TitleBarProps = {
	children: ReactNode;
	theme?: TitleBarTheme;
	/** Show a hover variation */
	isActive?: boolean;
	icon?: ReactNode;
};

export const TitleBar = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & TitleBarProps
>(({ children, theme = 'Default', isActive = false, icon, ...props }, ref) => {
	return (
		<div
			ref={ref}
			{...props}
			className={classNames(['TitleBar', `TitleBar--Theme-${theme}`], {
				isActive: isActive,
				hasIcon: !!icon,
			})}
		>
			{icon && (
				<>
					<div className="TitleBar__Icon">{icon}</div>
					<div className="TitleBar__Divider"></div>
				</>
			)}

			<div className="TitleBar__Title">{children}</div>
		</div>
	);
});
