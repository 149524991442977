import React from 'react';
import { ElementType as ElementTypeType } from '../../../Core/types/ElementType';
import { Icon } from '../../../Core/components/Icon/Icon';

import './ElementType.scss';

type Props = {
	type: ElementTypeType;
};

type ElementTypeMap = { [Type in ElementTypeType]: string };

const ElementTypeToText: ElementTypeMap = {
	NODE: 'Node',
	ELEMENT: 'Element',
	SUBELEMENT: 'Sub-element',
};

const ElementTypeToIcon: ElementTypeMap = {
	NODE: 'ElementNode',
	ELEMENT: 'ElementBeam',
	SUBELEMENT: 'ElementSegment',
};

export const ElementType = ({ type }: Props) => {
	const typeText = ElementTypeToText[type] ?? '';
	const typeIcon = ElementTypeToIcon[type] ?? '';

	return (
		<div className="ElementType">
			<div className="ElementType__Name">{typeText}</div>
			<div className="ElementType__Icon">
				<Icon name={typeIcon} width={52} />
			</div>
		</div>
	);
};
