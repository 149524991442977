import { Navigate, useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { AdminAccounts } from './AdminAccounts';
import './Admin.scss';
import { SlideInOverlay } from '../../Core/components/SlideInOverlay/SlideInOverlay';
import { AnimatePresence } from 'framer-motion';
import { AdminAccountEdit } from './AdminAccountEdit';
import { AdminSites } from './AdminSites';
import { AdminSiteEdit } from './AdminSiteEdit';
import { AdminSite } from './AdminSite';
import { AdminSiteDimension } from './AdminSiteDimension';
import './Admin.scss';
import { AdminSiteModels } from './AdminSiteModels';
import { AdminSiteUsers } from './AdminSiteUsers';
import { AdminSiteUserEdit } from './AdminSiteUserEdit';
import { AdminSiteUserInvite } from './AdminSiteUserInvite';

export const Admin = () => {
	const location = useLocation();

	return (
		<div className="Admin">
			<Routes>
				<Route path="/" element={<Navigate to="accounts" />} />
				<Route path="accounts/*" element={<AdminAccounts />} />
				<Route path="/accounts/:accountId/sites/*" element={<AdminSites />} />
				<Route
					path="/accounts/:accountId/sites/:selectedSiteId/config"
					element={<AdminSite />}
				/>
				<Route
					path="/accounts/:accountId/sites/:selectedSiteId/config/dimensions/*"
					element={<AdminSiteDimension />}
				/>
				<Route
					path="/accounts/:accountId/sites/:selectedSiteId/config/models/*"
					element={<AdminSiteModels />}
				/>
				<Route
					path="/accounts/:accountId/sites/:selectedSiteId/config/users/*"
					element={<AdminSiteUsers />}
				/>
			</Routes>
			{/** OVERLAY ROUTES */}
			<AnimatePresence exitBeforeEnter initial={false}>
				<Routes location={location} key={location.pathname}>
					<Route
						path="/accounts/:accountId"
						element={
							<SlideInOverlay isOpen={true} width="520px">
								<AdminAccountEdit />
							</SlideInOverlay>
						}
					/>
					<Route
						path="/accounts/:accountId/sites/:selectedSiteId"
						element={
							<SlideInOverlay isOpen={true} width="520px">
								<AdminSiteEdit />
							</SlideInOverlay>
						}
					/>
					<Route
						path="/accounts/:accountId/sites/:selectedSiteId/config/dimensions/:dimensionId"
						element={
							<SlideInOverlay isOpen={true} width="520px">
								<AdminSiteEdit />
							</SlideInOverlay>
						}
					/>
					<Route
						path="/accounts/:accountId/sites/:selectedSiteId/config/users/new"
						element={
							<SlideInOverlay isOpen={true} width="520px">
								<AdminSiteUserInvite />
							</SlideInOverlay>
						}
					/>
					<Route
						path="/accounts/:accountId/sites/:selectedSiteId/config/users/:userId"
						element={
							<SlideInOverlay isOpen={true} width="520px">
								<AdminSiteUserEdit />
							</SlideInOverlay>
						}
					/>
					<Route path="/accounts" element={null} />
					<Route path="/*" element={null} />
				</Routes>
			</AnimatePresence>
		</div>
	);
};
