import classNames from 'classnames';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
	useLazyAdminGetAccountQuery,
	useLazyAdminGetSiteQuery,
} from '../../Core/Api';
import { Icon } from '../../Core/components/Icon/Icon';
import './AdminBreadcrumb.scss';

export const AdminBreadcrumb = () => {
	const { accountId, selectedSiteId } = useParams();

	const location = useLocation();
	const [getAccount, { data: accountData }] = useLazyAdminGetAccountQuery();
	const [getSite, { data: siteData }] = useLazyAdminGetSiteQuery();

	const path = location.pathname;

	useEffect(() => {
		if (!accountId) {
			return;
		}

		getAccount({
			accountId: accountId ?? '',
		});
	}, [accountId, getAccount]);

	useEffect(() => {
		if (!selectedSiteId) {
			return;
		}

		getSite({
			siteId: selectedSiteId ?? '',
		});
	}, [selectedSiteId, getSite]);

	return (
		<div className="AdminBreadcrumb">
			<Link to={`/riams/admin/accounts`}>
				<div className="AdminBreadcrumb__Base">
					<p
						className={classNames(['AdminBreadcrumb__Text'], {
							'AdminBreadcrumb__Text--Last': !accountId,
						})}
					>
						Accounts
					</p>
					<div
						className={classNames(['AdminBreadcrumb__Icon'], {
							'AdminBreadcrumb__Icon--Last': !accountId,
						})}
					>
						<Icon name="Home" width={17} />
					</div>
				</div>
			</Link>
			{accountId && accountData?.account?.name && (
				<Link to={`/riams/admin/accounts/${accountId}/sites`}>
					<div className="AdminBreadcrumb__Section">
						<p className="AdminBreadcrumb__Text AdminBreadcrumb__Divider">
							&nbsp;/&nbsp;
						</p>
						<p
							className={classNames(['AdminBreadcrumb__Text'], {
								'AdminBreadcrumb__Text--Last': !selectedSiteId,
							})}
						>
							{accountData?.account?.name}
						</p>
					</div>
				</Link>
			)}
			{selectedSiteId && siteData?.site?.name && (
				<Link
					to={`/riams/admin/accounts/${accountId}/sites/${selectedSiteId}/config`}
				>
					<div className="AdminBreadcrumb__Section">
						<p className="AdminBreadcrumb__Text AdminBreadcrumb__Divider">
							&nbsp;/&nbsp;
						</p>
						<p
							className={classNames(['AdminBreadcrumb__Text'], {
								'AdminBreadcrumb__Text--Last': !(
									pathIncludes(path, 'users') ||
									pathIncludes(path, 'models') ||
									pathIncludes(path, 'dimensions')
								),
							})}
						>
							{siteData?.site?.name}
						</p>
					</div>
				</Link>
			)}
			{pathIncludes(path, 'users') && (
				<p className="AdminBreadcrumb__Text AdminBreadcrumb__Text--Last">
					&nbsp;/&nbsp;Users
				</p>
			)}
			{pathIncludes(path, 'models') && (
				<p className="AdminBreadcrumb__Text AdminBreadcrumb__Text--Last">
					&nbsp;/&nbsp;Models
				</p>
			)}
			{pathIncludes(path, 'dimensions') && (
				<p className="AdminBreadcrumb__Text AdminBreadcrumb__Text--Last">
					&nbsp;/&nbsp;Dimensions
				</p>
			)}
		</div>
	);
};

const pathIncludes = (path: string, matcher: string) => {
	return path.split('/').at(-1)?.includes(matcher) ?? false;
};
