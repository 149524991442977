import React from 'react';
import { Icon } from '../../Core/components/Icon/Icon';
import { noop } from '../../Core/utils/Function';
import './NavigationButton.scss';

export const NavigationButton = ({
	onClick = noop,
	iconWidth,
	iconName,
}: {
	iconName: string;
	iconWidth: number;
	onClick?: () => void;
}) => {
	return (
		<div className="NavigationButton" onClick={onClick}>
			<Icon name={iconName} width={iconWidth} />
		</div>
	);
};
