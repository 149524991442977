import { ColorLookup } from '../../../Core/utils/ColorLookup';
import {
	ComponentId,
	DimensionId,
	ElementDTO,
	ElementId,
	ElementType,
	Result,
	ResultColor,
	SectionResultElement,
} from '../../../SharedTypes/API/Explorer';
import {
	ModelElement,
	ModelElementColor,
	ModelElementType,
} from './ModelElement.entity';

const createElementColor = (input: string): ModelElementColor =>
	input as ModelElementColor;

const createElementType = (type: ElementType): ModelElementType => {
	switch (type) {
		case 'Box':
			return 'box' as ModelElementType;
		case 'Plate':
			return 'plate' as ModelElementType;
		default:
			return 'pipe' as ModelElementType;
	}
};

const fromElementDTO = (args: { color?: string } = {}) => {
	const color = args.color ?? ColorLookup.forElement['none'];

	return (element: ElementDTO): ModelElement => {
		const id: ElementId = element[0];
		const type: ElementType = element[2];
		const coordinates: [x: number, y: number, z: number][] = element[3];

		return [
			id as ComponentId,
			createElementType(type),
			createElementColor(color),
			...coordinates,
		] as ModelElement;
	};
};

const fromSectionResult = (args: {
	sectionResultElement: SectionResultElement;
	selectedDimension: DimensionId | null;
	defaultColor: 'neutral' | 'none';
}): ModelElement => {
	const id: ComponentId = args.sectionResultElement[0];
	const type: ElementType = args.sectionResultElement[2];
	const coordinates: [x: number, y: number, z: number][] =
		args.sectionResultElement[3];
	const dimensionResults: {
		[dimensionId: string]: Result;
	} = args.sectionResultElement[4];

	// We get the color for the selected dimension or a fallback default color
	// The default is passed in as an argument to differentiate between yellow (none) when no results are chosen
	// and white (neutral) when either a joint or element dimension is visualized. IE the model should be white whenever there is any result
	let color: ResultColor = args.selectedDimension
		? dimensionResults?.[args.selectedDimension]?.[2] ?? 'neutral'
		: args.defaultColor;

	const hexColor = ColorLookup.forElement[color];

	return [
		id,
		createElementType(type),
		createElementColor(hexColor),
		...coordinates,
	] as ModelElement;
};

const inferParentId = (id: ComponentId): ComponentId | null => {
	const [parentNumber, childNumber] = id.split('-');

	// Parents have no parent
	if (childNumber === '0') {
		return null;
	}

	// Set the parent id as parentNumber-0 as per the business logic
	return `${parentNumber}-0` as ComponentId;
};

export const ModelElementFactory = {
	fromElementDTO,
	fromSectionResult,
	inferParentId,
};
