import { APIQuery } from '../../SharedTypes/API/_utilities';

/** Generate a RTK query based on an APIQuery type */
export const makeQuery =
	<T extends APIQuery<unknown, unknown, unknown>>(path: T['path']) =>
	(params: T['arguments']) => ({
		method: 'POST',
		url: path,
		body: params,
	});
