import { FC } from 'react';
import './ChartWarning.scss';

type Props = {
	timestamp: string;
};

export const ChartWarning: FC<Props> = ({ timestamp }) => (
	<div className="ChartWarning">
		<div className="ChartWarning__Message">
			<p className="ChartWarning__Message--Bold">
				<b>Notice: Data is shown for {timestamp}.&nbsp;</b>
				Select a date range of maximum 24 hours to inspect hourly data
			</p>
		</div>
	</div>
);
