import React from 'react';
import { ElementType } from '../../../Core/types/ElementType';
import { ElementDetailsHeader } from './ElementDetailsHeader';

import './ElementDetailsOverlay.scss';

type Props = {
	id: string;
	type: ElementType;
	onClose: () => void;
};

export const ElementDetailsOverlay = ({ id, type, onClose }: Props) => {
	return (
		<div className="ElementDetailsOverlay">
			<div className="ElementDetailsOverlay__Header">
				<ElementDetailsHeader
					elementId={id}
					elementType={type}
					onBackClick={onClose}
				/>
			</div>
		</div>
	);
};
