import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { noop } from '../../utils/Function';

import { Icon } from '../Icon/Icon';

import './IconButton.scss';

type Theme = 'blue-grey' | 'none';

type Props = {
	children: ReactNode;
	onClick?: () => void;
	icon: string;
	alignText?: 'left' | 'right';
	iconSize?: number;
	theme?: Theme;
};

export const IconButton = ({
	children,
	onClick = noop,
	icon,
	alignText = 'right',
	iconSize = 24,
	theme = 'blue-grey',
}: Props) => {
	return (
		<div
			className={classNames(
				'IconButton',
				`IconButton--align-${alignText}`,
				`IconButton--theme-${theme}`
			)}
			onClick={onClick}
		>
			<Icon className="IconButton__Icon" name={icon} width={iconSize} />
			<div className="IconButton__Text">{children}</div>
		</div>
	);
};
