import { ReactComponent as SpinnerIcon } from './Spinner.svg';

import './Spinner.scss';

interface Props {
	color?: string;
}

export const Spinner = ({ color = '#fff' }: Props) => {
	const style = {
		color,
	};

	return <SpinnerIcon style={style} className="Spinner" />;
};
