import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	useAdminDeleteAccountMutation,
	useAdminGetAccountsQuery,
} from '../../Core/Api';
import { List } from '../../Core/components/List/List';
import { ListItem } from '../../Core/components/List/ListItem';
import { ListLoading } from '../../Core/components/List/ListLoading';
import { PopMenuItems } from '../../Core/components/PopMenu/PopMenu';
import { toastConfig } from '../../Core/configuration/toast';
import { byKeyIncludes } from '../../Core/utils/Filter';
import { Account } from '../../SharedTypes/API/Admin';
import './AdminAccounts.scss';
import { AdminLayout } from './AdminLayout';
import { AdminRemoveAccountModal } from './AdminModals';

const createPopMenuItemsForAccounts: PopMenuItems = [
	{
		label: 'Edit',
		key: 'edit',
	},
	{
		label: 'Delete',
		key: 'delete',
	},
];

export const AdminAccounts = () => {
	const [accountToRemove, setAccountToRemove] = useState<Account | null>(null);
	const [deleteAccount] = useAdminDeleteAccountMutation();
	const { isLoading, data, isSuccess } = useAdminGetAccountsQuery({});
	const [filter, setFilter] = useState('');
	const navigate = useNavigate();

	const handleSelectedItem = (accountId: string) => (action: string) => {
		if (action === 'edit') {
			navigate(`${accountId}`);
		}

		if (action === 'delete') {
			setAccountToRemove(
				data?.accounts.find((account) => account.id === accountId)!
			);
		}
	};

	const handleModalClose = (doAction: boolean) => {
		// If true, then remove the user
		if (!doAction) {
			setAccountToRemove(null);
			return;
		}
		// Otherwise, well, close the modal and do nothing
		deleteAccount({ accountId: accountToRemove!.id }).then((response) => {
			if ('error' in response) {
				// @ts-ignore
				toast(response.error.data.message, toastConfig.error);
				return;
			}
			toast('Account removed', toastConfig.success);
			setAccountToRemove(null);
		});
	};

	// ! Memoize accounts to stop the blinky re-render on refetch
	const memoizedAccounts = useMemo(() => {
		if (data && data?.accounts?.length > 0) {
			return data.accounts.filter(byKeyIncludes('name', filter));
		}

		if (data && data?.accounts) {
			return [];
		}

		return [];
	}, [data, filter]);

	const handleGoToNewAccount = () => {
		navigate('new');
	};

	const handleSelectAccount = (accountId: string) => {
		navigate(`${accountId}/sites`);
	};

	return (
		<AdminLayout title="Accounts" searchHandler={setFilter} searchTerm={filter}>
			{accountToRemove && (
				<AdminRemoveAccountModal
					name={accountToRemove.name}
					respond={handleModalClose}
				/>
			)}
			<List handleOnAdd={handleGoToNewAccount} buttonLabel="Add account">
				{isLoading && (
					<ListItem templateColumns="1fr">
						<ListLoading text="Loading accounts" />
					</ListItem>
				)}
				{!isSuccess && !isLoading && (
					<ListItem templateColumns="1fr">
						<ListLoading text="No accounts found" />
					</ListItem>
				)}
				{isSuccess &&
					memoizedAccounts.map((account) => (
						<ListItem
							templateColumns="repeat(3, 1fr)"
							key={account.id}
							popMenuItems={createPopMenuItemsForAccounts}
							handleMenuItemSelected={handleSelectedItem(account.id)}
							handleClick={() => handleSelectAccount(account.id)}
						>
							<p>{account.name}</p>
						</ListItem>
					))}
			</List>
		</AdminLayout>
	);
};
