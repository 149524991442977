export const not =
	<T>(fn: (input: T) => boolean) =>
	(val: T) =>
		!fn(val);

export const eq =
	<T extends unknown>(val1: T) =>
	(val2: T) =>
		val1 === val2;

export const isNullOrUndefined = (val: unknown): val is null | undefined => {
	return val === null && typeof val === 'undefined';
};

export const isNull = (val: unknown): val is null => {
	return val === null;
};

export const isUndefined = (val: unknown): val is undefined => {
	return typeof val === 'undefined';
};

export const isDefined = <T>(val: T | undefined): val is T => {
	return typeof val !== 'undefined';
};
