import { useMemo } from 'react';
import { List } from '../../Core/components/List/List';
import { ListItem } from '../../Core/components/List/ListItem';
import { ListLoading } from '../../Core/components/List/ListLoading';
import { AdminLayout } from './AdminLayout';

// ! I assume this needs an endpoint, where the reference to the active model is stored
export const AdminSiteModels = () => {
	// TODO Implement get query to get actual data
	const memoizedModels = useMemo(() => {
		return [];
	}, []);

	const handleUploadModel = () => {
		console.log('Trigger upload model');
	};

	return (
		<AdminLayout title="Models">
			<List buttonLabel="Upload new model" handleOnAdd={handleUploadModel}>
				{memoizedModels.length === 0 && (
					<ListItem templateColumns="1fr">
						<ListLoading text="No models to display" />
					</ListItem>
				)}
				{memoizedModels.map((model) => (
					<ListItem
						templateColumns="3fr 1fr"
						popMenuItems={[
							{
								key: 'edit',
								label: 'Edit',
							},
							{
								key: 'delete',
								label: 'Delete',
							},
						]}
					>
						<p>"Model..."</p>
					</ListItem>
				))}
			</List>
		</AdminLayout>
	);
};
