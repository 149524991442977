import { useNavigate, useParams } from 'react-router-dom';
import {
	useAdminCreateAccountMutation,
	useAdminGetAccountQuery,
	useAdminUpdateAccountMutation,
} from '../../Core/Api';
import { BackButton } from '../../Core/components/SlideInOverlay/BackButton';
import './AdminAccountEdit.scss';
import { AdminAccountForm } from './AdminAccountForm';
import { toast } from 'react-toastify';
import { toastConfig } from '../../Core/configuration/toast';

export const AdminAccountEdit = () => {
	const navigate = useNavigate();
	const { accountId } = useParams<{ accountId: string }>();
	const [updateAccount] = useAdminUpdateAccountMutation();
	const [createAccount] = useAdminCreateAccountMutation();

	const { data } = useAdminGetAccountQuery({
		accountId: accountId!,
	});

	const navigateBack = () => {
		navigate(-1);
	};

	const handleUpdateAccount = (newName: string) => {
		if (accountId === 'new') {
			// Create account
			createAccount({
				name: newName,
			}).then((response) => {
				if ('error' in response) {
					// @ts-ignore
					toast(response.error.data.message, toastConfig.error);
					return;
				}

				toast('Account created', toastConfig.success);
				navigateBack();
			});
		} else {
			updateAccount({ id: accountId!, name: newName }).then((response) => {
				if ('error' in response) {
					// @ts-ignore
					toast(response.error.data.message, toastConfig.error);
					return;
				}

				toast('Account created', toastConfig.success);
				navigateBack();
			});
		}
	};

	return (
		<div className="AdminAccountEdit">
			<div className="AdminAccountEdit__Top">
				<BackButton />
			</div>
			{(accountId === 'new' || (data && data?.account?.name)) && (
				<AdminAccountForm
					accountName={data?.account?.name || ''}
					updateAccount={handleUpdateAccount}
				/>
			)}
		</div>
	);
};
