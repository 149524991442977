import { ApexOptions } from 'apexcharts';
import { FC, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import Chart from 'react-apexcharts';
import { Icon } from '../Icon/Icon';
import './ScatterPlotChart.scss';
import { ScatterDataSet } from '../../../SharedTypes/API/Dashboard';
import { ChartNoData } from './ChartNoData';
import { ColorLookup } from '../../utils/ColorLookup';
import { createArrayWith } from '../../utils/Array';
import { IndicatorDot } from '../IndicatorDot/IndicatorDot';
import {
	useDateRange,
	useSelectedDate,
} from '../../../RIAMS/Dashboard/Dashboard.slice';
import { useParams } from 'react-router-dom';
import { useGetMetricQuery } from '../../Api';
import { ChartWarning } from './ChartWarning';
import {
	getDate,
	getHour,
	getMinutes,
	getMonth,
	getPadded,
	getYear,
} from '../../utils/Dates';
import { getChartDownloadName } from './ChartOptions';

type ScatterPlotChartProps = {
	dataSet: ScatterDataSet;
	height?: number;
	dateClicked: (date: Date) => void;
	chosenDate?: number;
	dateRange: [Date, Date];
};

export const ScatterPlotChart: FC<ScatterPlotChartProps> = ({ dataSet }) => {
	const selectedDate = useSelectedDate();
	const dateRange = useDateRange();

	/** Checks if we should query data, other than the default dataSet we get via props */
	const shouldFetchData = (): boolean => {
		if (!selectedDate && !dateRange?.[1]) {
			return false;
		}

		if (selectedDate === dateRange?.[1].getTime()) {
			return false;
		}

		return true;
	};

	/** Checks if the date range is a single, 24 hours period */
	const dateRangeIsSingleDay = useCallback(() => {
		// Date range is nothing
		if (!dateRange?.[0] && !dateRange?.[1]) {
			return false;
		}

		if (dateRange[0].getDate() !== dateRange[1].getDate() - 1) {
			return false;
		}

		return true;
	}, [dateRange]);

	const params = useParams();

	const { data } = useGetMetricQuery(
		{
			siteId: params.siteId!,
			metricId: dataSet.id,
			start: selectedDate - 1000 * 60 * 60,
			stop: selectedDate,
		},
		{
			skip: !shouldFetchData() && !dateRangeIsSingleDay(),
		}
	);

	const getDataSet = useMemo(() => {
		if (
			dateRangeIsSingleDay() &&
			selectedDate !== dateRange?.[1].getTime() &&
			data &&
			data.chartType === 'scatter'
		) {
			return data;
		}

		return dataSet;
	}, [dateRangeIsSingleDay, selectedDate, dateRange, data, dataSet]);

	const scatterDataSetsAmount = getDataSet.dataSetNames.filter(
		(set) => set.type === 'data'
	);
	const scatterDataLimitsAmount = getDataSet.dataSetNames.filter(
		(set) => set.type === 'limit'
	).length;

	const dashes = createArrayWith(scatterDataLimitsAmount, 10);
	const widths = createArrayWith(scatterDataLimitsAmount, 1);
	const colors = createArrayWith(
		scatterDataLimitsAmount,
		'rgba(255,255,255,0.6)'
	);

	const markerColors = createArrayWith(scatterDataLimitsAmount, 'transparent');

	const dataDashes = createArrayWith(scatterDataSetsAmount.length, 0);
	const dataWidths = createArrayWith(scatterDataSetsAmount.length, 4);
	const allDataColors = [
		ColorLookup.forGraph['blue'],
		ColorLookup.forGraph['blue2'],
	];
	const dataColors = scatterDataSetsAmount.map(
		(_set, index) => allDataColors[index]
	);

	const dataMarkerColors = createArrayWith(
		scatterDataSetsAmount.length,
		'#fff'
	);

	// Create the export download name
	const exportDownloadName = getChartDownloadName({
		dateRange: dateRange!,
		name: dataSet.name,
	});

	// Create the options for the chart
	const options: ApexOptions = {
		chart: {
			background: '#003347',
			width: '100%',
			height: '100%',
			animations: {
				enabled: true,
				speed: 150,
				easing: 'easeout',
				animateGradually: {
					enabled: false,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 150,
				},
			},
			zoom: {
				enabled: true,
				type: 'xy',
			},
			toolbar: {
				show: true,
				tools: {
					pan: false,
					zoom: renderToString(<Icon name="ChartSearch" width={16} />),
					download: renderToString(<Icon name="ChartBurgerMenu" width={16} />),
					zoomin: renderToString(<Icon name="ChartIn" width={16} />),
					zoomout: renderToString(<Icon name="ChartOut" width={16} />),
					reset: renderToString(<Icon name="Reset" width={18} />),
				},
				offsetX: 0,
				offsetY: -25,
				export: {
					png: {
						filename: exportDownloadName,
					},
					svg: {
						filename: exportDownloadName,
					},
					csv: {
						filename: exportDownloadName,
					},
				},
			},
			events: {
				beforeZoom: (_event, { xaxis }) => {
					// Ensures we do not zoom out further than the present datapoints
					const first = getDataSet.data[0][0].x;
					const last = getDataSet.data.at(-1)?.[0].y;

					const calculatedFirst = first < 0 ? first * -1 : first;
					const calulatedLast = last && last < 0 ? last * -1 : last;

					const mainDifference = (calulatedLast ?? 0) - calculatedFirst;
					const zoomDifference = xaxis.max - xaxis.min;

					if (zoomDifference < mainDifference)
						return {
							// dont zoom out any further
							xaxis: {
								min: first,
								max: last,
							},
						};
					else {
						return {
							// keep on zooming
							xaxis: {
								min: xaxis.min,
								max: xaxis.max,
							},
						};
					}
				},
			},
		},
		yaxis: {
			forceNiceScale: true,
			tickAmount: 5,
			labels: {
				style: {
					colors: ['rgba(255,255,255, .7)'],
					fontSize: '11px',
				},
				formatter: (val) =>
					`${val.toFixed(2)} ${getDataSet.units ? getDataSet.units : ''}`,
				offsetY: 2,
				padding: 10,
			},
		},
		xaxis: {
			type: 'numeric',
			axisTicks: {
				show: false,
			},
			tooltip: {
				enabled: false,
			},
			axisBorder: {
				show: false,
			},
			crosshairs: {
				show: false,
			},
			tickAmount: 4,
			labels: {
				show: true,
				formatter: (val) =>
					`${Number(val).toFixed(1)} ${dataSet.units ? dataSet.units : ''}`,
				style: {
					colors: 'rgba(255,255,255, .7)',
					fontSize: '11px',
				},
			},
		},
		legend: {
			show: false,
		},
		grid: {
			borderColor: 'rgba(255,255,255,0.25)',
			yaxis: {
				lines: {
					show: true,
				},
			},
			xaxis: {
				lines: {
					show: true,
				},
			},
		},
		markers: {
			size: 0,
			hover: {
				size: 4,
			},
			colors: [...dataMarkerColors, ...markerColors],
			fillOpacity: 1,
			strokeWidth: 0,
			showNullDataPoints: false,
		},
		stroke: {
			colors: [...dataColors, ...colors],
			width: [...dataWidths, ...widths],
			dashArray: [...dataDashes, ...dashes],
		},
		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				// All series have the same x axis values, we can grab them from whereever
				const x = w.globals.seriesX[seriesIndex][dataPointIndex];

				const y = series[seriesIndex][dataPointIndex];

				const colorDot = renderToString(
					<IndicatorDot
						status="default"
						color={dataColors[seriesIndex]}
						size={8}
					/>
				);

				const seriesName = getDataSet.dataSetNames[seriesIndex].name;

				const label = `${colorDot} ${seriesName} x: ${Number(x).toFixed(1)}${
					dataSet.units ? dataSet.units : ''
				} - y: ${Number(y).toFixed(1)}${dataSet.units ? dataSet.units : ''}`;

				return `
					<div class="LineChart__Label" data-testid="LineChartTooltip">
						<span style="display: flex; gap: 4px; align-items: center;">${label}</span>
					</div> 
					`;
			},
		},
	};

	return (
		<div className="ScatterPlotChart">
			{getDataSet.data.length === 0 && <ChartNoData />}
			{getDataSet.data.length > 0 && (
				<>
					<Chart
						data-testid="scatterPlot"
						type="line"
						series={getDataSet.data.map((set) => ({ data: set, type: 'line' }))}
						width="100%"
						height="95%"
						options={options}
					/>
					{!dateRangeIsSingleDay() &&
						dateRange?.[1] &&
						selectedDate !== dateRange?.[1].getTime() && (
							<ChartWarning
								timestamp={`${getPadded(getDate(dateRange[1]))}/${getPadded(
									getMonth(dateRange[1])
								)}/${getYear(dateRange[1])} ${getPadded(
									getHour(dateRange[1])
								)}:${getPadded(getMinutes(dateRange[1]))}`}
							/>
						)}
				</>
			)}
		</div>
	);
};
