export const configuration = {
	explorer: {
		modelPlateColor: '#777',
		modelColor: '#FFC83C',
		modelHeatmapColor: '#fff',
		lightColor: '#fff',
		soilColor: '#F7DDBA',
		fogColor: '#999',
		gridColor: '#555',
		waterColor: '#007396',
		showStats: false,
	},
};
