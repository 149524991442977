import { useMemo } from 'react';
import { Color, DoubleSide } from 'three';
import { configuration } from '../../../Core/configuration/configuration';
import { ModelElement } from '../models/ModelElement.entity';

interface Props {
	plates: ModelElement[];
}
export const PlateMesh = ({ plates }: Props) => {
	const shapes = useMemo(() => {
		return plates.map(getCoordinates).flatMap(mapCoordinatesToVertices);
	}, [plates]);

	const color = useMemo(
		() =>
			new Color(configuration.explorer.modelPlateColor).convertSRGBToLinear(),
		[]
	);

	return (
		<group>
			{shapes.map((shape, i) => (
				<mesh key={i} receiveShadow>
					<bufferGeometry>
						<bufferAttribute
							attachObject={['attributes', 'position']}
							count={shape.length / 3}
							array={shape}
							itemSize={3}
						/>
					</bufferGeometry>
					<meshPhongMaterial
						color={color}
						shininess={0}
						side={DoubleSide}
						flatShading
						transparent
					/>
				</mesh>
			))}
		</group>
	);
};

type GetCoordinates = (
	element: ModelElement
) => [x: number, y: number, z: number][];
const getCoordinates: GetCoordinates = (element) => {
	const [, , , ...coordinates] = element;

	// As here seems to be necessary due to TS not interpreting ...coordinates correctly
	return coordinates as [x: number, y: number, z: number][];
};

type MapCoordinatesToVertices = (
	coordinates: [x: number, y: number, z: number][]
) => Float32Array | [];
const mapCoordinatesToVertices: MapCoordinatesToVertices = (coordinates) => {
	const numberOfPoints = coordinates.length;

	switch (numberOfPoints) {
		case 3:
			return new Float32Array([
				...coordinates[0],
				...coordinates[1],
				...coordinates[2],
			]);
		case 4:
			return new Float32Array([
				...coordinates[0],
				...coordinates[1],
				...coordinates[2],
				// We add a final vertice to ensure that ordering of the points don't matter
				...coordinates[2],
				...coordinates[3],
				...coordinates[0],
			]);
		default:
			return [];
	}
};
