import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { noop } from '../../utils/Function';

import './Button.scss';

type InputHeight = 'Default' | 'Small' | 'Large';

type Props = {
	children: ReactNode;
	onClick?: () => void;
	theme?:
		| 'Blue'
		| 'OutlineGrey'
		| 'OutlineBlue'
		| 'Grey'
		| 'TrafficLight'
		| 'TrafficLightRound'
		| 'Circle';
	height?: InputHeight;
} & JSX.IntrinsicElements['button'];

export const Button = forwardRef<HTMLButtonElement, Props>(
	(
		{ children, theme = 'Blue', height = 'Default', onClick = noop, ...props },
		ref
	) => {
		return (
			<button
				ref={ref}
				{...props}
				className={classNames([
					'Button',
					`Button--Theme-${theme}`,
					`Button--Height-${height}`,
				])}
				onClick={onClick}
				data-testid="button"
			>
				<div className="Button__Inner">{children}</div>
			</button>
		);
	}
);
