import classNames from 'classnames';
import { FC, ReactNode, useRef, useState } from 'react';
import { noop } from '../../utils/Function';
import { Icon } from '../Icon/Icon';
import { PopMenu, PopMenuItems } from '../PopMenu/PopMenu';
import './ListItem.scss';

type Props = {
	popMenuItems?: PopMenuItems;
	/** Represents grid-template-column */
	templateColumns?: string;
	// ! I would love to use a generic here, to assign the type of return arg, but alas...
	handleMenuItemSelected?: (action: string) => void;
	handleClick?: () => void;
	children: ReactNode;
};

/**
 * Renders a row in a list
 */
export const ListItem: FC<Props> = ({
	children,
	popMenuItems,
	templateColumns = 'repeat(6, 1fr)',
	handleMenuItemSelected = noop,
	handleClick,
}) => {
	const menuRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	return (
		<li
			className={classNames(['ListItem'], {
				ListItem__HandleClick: !!handleClick,
			})}
			style={{ gridTemplateColumns: templateColumns }}
			onClick={handleClick ? handleClick : noop}
		>
			{children}
			{popMenuItems && (
				<PopMenu
					theme="Dark"
					items={popMenuItems}
					placement="bottom-start"
					onChangeState={(state) => setIsOpen(state === 'open')}
					onSelectItem={handleMenuItemSelected}
					elementRef={menuRef}
					className={classNames('ListItem__Action', {
						isOpen,
					})}
				>
					<Icon
						className="ListItem__ActionIcon"
						name="ThreeDotsH"
						width={14}
						ref={menuRef}
					/>
				</PopMenu>
			)}
		</li>
	);
};
