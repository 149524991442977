export const noop = () => {};

/**
 * "Never" Function for switch guards to make sure all values are exhausted
 *  use ithe default case:
 *  > default:
 * 	>   never(); // Ensures that all values are exhausted
 *
 */
export const never = (value: never): never => {
	throw new Error('An unexpected value was encountered in switch statement.');
};
