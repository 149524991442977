import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../Core/components/Button/Button';
import { FormInput } from '../../Core/components/FormInput/FormInput';
import './AdminAccountForm.scss';

type Props = {
	accountName: string;
	updateAccount: (accountName: string) => void;
};

export const AdminAccountForm: FC<Props> = ({ accountName, updateAccount }) => {
	const { handleSubmit, control } = useForm({
		defaultValues: {
			name: accountName,
		},
	});

	const saveChanges = ({ name }: { name: string }) => {
		return updateAccount(name);
	};

	return (
		<form className="AdminAccountForm" onSubmit={handleSubmit(saveChanges)}>
			<FormInput control={control} label="Name" name="name" theme="Admin" />

			<Button type="submit" theme="Blue">
				Save
			</Button>
		</form>
	);
};
