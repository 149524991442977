import { CSSProperties } from 'react';
import { ColorLookup } from '../../Core/utils/ColorLookup';
import { ResultColor } from '../../SharedTypes/API/Explorer';
import './ResultBar.scss';

type Props = {
	valueText: string;
	color: ResultColor;
	label?: string;
	width: number;
};
export const ResultBar = ({ color, valueText, label = '', width }: Props) => {
	const hexColor = ColorLookup.forGraph[color];

	const barStyle: CSSProperties = {
		width: `${width}%`,
		backgroundColor: hexColor,
	};

	return (
		<div className="ResultBar">
			<div className="ResultBar__BarTrack">
				<div className="ResultBar__Bar" style={barStyle}></div>
			</div>
			<div className="ResultBar__Text">
				{label && <div className="ResultBar__Label">{label}</div>}
				<div className="ResultBar__Value">{valueText}</div>
			</div>
		</div>
	);
};
