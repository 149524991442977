import { ApexOptions } from 'apexcharts';
import { FC } from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';
import { TimeseriesDataSet } from '../../../SharedTypes/API/Dashboard';
import { getDate, getMonthShort, getYear } from '../../utils/Dates';
import { noop } from '../../utils/Function';
import { clamp } from '../../utils/Number';
import { Icon } from '../Icon/Icon';
import { IndicatorDot } from '../IndicatorDot/IndicatorDot';
import './LineChart.scss';
import './MultiLineChart.scss';

const riserValue = 20;

type MultiLineChartProps = {
	dataSets: TimeseriesDataSet[];
	height?: number;
	dateClicked: (date: Date) => void;
	chosenDate?: number;
	chartColors?: string[];
};

export const MultiLineChart: FC<MultiLineChartProps> = ({
	dataSets,
	height = 200,
	dateClicked = noop,
	chosenDate,
	chartColors = ['#06BB75', '#F57800', '#FFC300', '#E00039', '#009DE0'],
}) => {
	if (dataSets[0].chartType !== 'line') {
		return null;
	}

	const dataUnits = dataSets[0].units ? dataSets[0].units : ' ';

	const handleMarkerClick = (
		chartContext: any,
		{
			seriesIndex,
			dataPointIndex,
		}: { seriesIndex: number; dataPointIndex: number }
	) => {
		const timestamp =
			chartContext.w.globals.seriesX[seriesIndex][dataPointIndex];
		dateClicked(timestamp);
	};

	const options: ApexOptions = {
		stroke: {
			curve: 'straight',
			width: 4,
		},
		states: {
			hover: {
				filter: {
					type: 'darken',
					value: 0.7,
				},
			},
			normal: {
				filter: {
					type: 'none',
				},
			},
		},
		xaxis: {
			type: 'datetime',
			axisTicks: {
				show: false,
			},
			tooltip: {
				enabled: false,
			},
			labels: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			crosshairs: {
				show: false,
			},
		},
		yaxis: {
			forceNiceScale: true,
			show: true,
			labels: {
				show: true,
				formatter: (value) =>
					value ? `${value.toFixed(0)}${dataUnits}` : `${value}${dataUnits}`,
				padding: 5,
				style: {
					colors: 'rgba(255,255,255,0.7)',
					cssClass: 'LineChart__YAxis',
				},
			},
			tickAmount: 4,
			min:
				clamp(0)(100)(
					Math.floor(dataSets[dataSets.length - 1].minValue - riserValue)
				) ?? 0,
		},
		colors: chartColors,
		legend: {
			show: false,
		},
		chart: {
			height: 'auto',
			width: '100%',
			zoom: {
				enabled: true,
				type: 'x',
			},
			toolbar: {
				show: true,
				tools: {
					pan: false,
					zoom: renderToString(<Icon name="ChartSearch" width={16} />),
					download: renderToString(<Icon name="ChartBurgerMenu" width={16} />),
					zoomin: renderToString(<Icon name="ChartIn" width={16} />),
					zoomout: renderToString(<Icon name="ChartOut" width={16} />),
					reset: renderToString(<Icon name="Reset" width={18} />),
				},
				offsetX: 0,
				offsetY: -25,
			},
			events: {
				beforeZoom: (_event, { xaxis }) => {
					// Ensures we do not zoom out further than the present datapoints
					const first = dataSets[0].data[0]?.x;
					const last = dataSets[0].data.at(-1)?.x;

					const mainDifference = (last ?? 0) - first!;
					const zoomDifference = xaxis.max - xaxis.min;

					if (zoomDifference > mainDifference)
						return {
							// dont zoom out any further
							xaxis: {
								min: first,
								max: last,
							},
						};
					else {
						return {
							// keep on zooming
							xaxis: {
								min: xaxis.min,
								max: xaxis.max,
							},
						};
					}
				},
				markerClick: (
					_: any,
					chartContext: any,
					{
						seriesIndex,
						dataPointIndex,
					}: { seriesIndex: number; dataPointIndex: number }
				) => handleMarkerClick(chartContext, { seriesIndex, dataPointIndex }),
			},
			animations: {
				enabled: true,
				speed: 150,
				easing: 'easeout',
				animateGradually: {
					enabled: false,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 150,
				},
			},
		},
		grid: {
			show: true,
			yaxis: {
				lines: {
					show: true,
				},
			},
			borderColor: 'rgba(255,255,255,0.7)',
			padding: {
				left: -5,
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0,
			hover: {
				size: 4,
			},
			colors: ['#fff'],
			fillOpacity: 1,
			strokeWidth: 0,
		},
		fill: {
			type: 'gradient',
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.5,
				opacityTo: 0,
				stops: [0, 100],
				type: 'vertical',
			},
			colors: [
				'#009de0',
				'transparent',
				'transparent',
				'transparent',
				'transparent',
			],
		},
		tooltip: {
			custom: function ({ series, dataPointIndex, w }) {
				// All series have the same x axis values, we can grab them from whereever
				const timestamps = w.globals.seriesX.map(
					(stamp: any) => stamp[dataPointIndex]
				);

				// Get all the values
				const values = series.map((serie: any) => serie[dataPointIndex]);

				return renderToString(
					<MultilineChartTooltip
						items={values.map((value: string, index: number) => ({
							seriesName: dataSets[index].shortName ?? dataSets[index].name,
							value,
						}))}
						timestamp={timestamps[0]}
						colors={chartColors}
						units={dataUnits}
					/>
				);
			},
			followCursor: true,
		},
	};

	const series = dataSets
		.filter((set) => set.chartType === 'line')
		.map((set) => ({ ...set, type: 'area' }));

	return (
		<div className="LineChart" style={{ paddingTop: 0, paddingBottom: 0 }}>
			<Chart
				data-testid="lineChart"
				type="area"
				series={series}
				height={height}
				width="100%"
				options={options}
			/>
		</div>
	);
};

type TooltipProps = {
	items: { seriesName: string; value: string }[];
	timestamp: string;
	colors: string[];
	units: string;
};

const MultilineChartTooltip: FC<TooltipProps> = ({
	items,
	timestamp,
	colors,
	units,
}) => (
	<div className="MultilineChartTooltip">
		<div className="MultilineChartTooltip__Content">
			<h1 className="MultilineChartTooltip__Title">
				{getMonthShort(timestamp)} {getDate(timestamp)}, {getYear(timestamp)}
			</h1>
			{items.map((item, index) => (
				<div className="MultilineChartTooltip__Item" key={item.seriesName}>
					<IndicatorDot status="default" size={8} color={colors[index]} />
					<div className="MultilineChartTooltip__Label">{item.seriesName}</div>
					<div className="MultilineChartTooltip__Value">
						{item.value}
						{units}
					</div>
				</div>
			))}
		</div>
	</div>
);
