import React from 'react';
import { ReactNode } from 'react';
import { InstancedMesh, Matrix4 } from 'three';
import { ModelJoint, ModelJointCoordinate } from '../models/ModelJoint.entity';
import { SelectionMesh } from './SelectionMesh';

const radius = 0.8;

interface Props {
	joints: ModelJoint[];
	detail?: number;
}

export const JointMesh = ({
	joints,
	detail = 1,
	instanceRef = React.createRef<InstancedMesh>(),
}: Props & { instanceRef?: React.RefObject<InstancedMesh> }) => {
	const InstancedGeometry = ({ children }: { children: ReactNode }) => (
		<icosahedronBufferGeometry args={[radius, detail]}>
			{children}
		</icosahedronBufferGeometry>
	);

	const placementFn = (element: ModelJoint) => {
		// Extract with explicit types to be sure we descructure the tuple correctly
		const x: ModelJointCoordinate = element[3];
		const y: ModelJointCoordinate = element[4];
		const z: ModelJointCoordinate = element[5];

		return new Matrix4().makeTranslation(x, y, z);
	};

	return (
		<SelectionMesh
			category="joint"
			components={joints}
			InstancedGeometry={InstancedGeometry}
			placementFn={placementFn}
			instanceRef={instanceRef}
		/>
	);
};
