import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { never } from '../../Core/utils/Function';
import {
	ComponentId,
	DimensionConfiguration,
	DimensionConfigurationDimension,
} from '../../SharedTypes/API/Explorer';
import * as O from '../../Core/utils/Object';
import * as L from '../../Core/utils/Logic';
import { Nominal } from '../../SharedTypes/API/_utilities';
import { SensorLocation } from '../../SharedTypes/API/Dashboard';

/**
 * A compund key representing a component selection - ie. a selected joint or element
 * The reasoning for using a compund key is that each key is unique and represents both the type and id
 * making for easier toggling, since array.includes(key) can be used
 */
export type ComponentSelectionId = Nominal<string, 'ComponentSelectionID'>;

/**
 * Create a ComponentSelectionId compound key
 */
export const serializeComponentSelectionId = (args: {
	componentId: ComponentId;
	componentType: 'joint' | 'element';
}) => `${args.componentType}#${args.componentId}` as ComponentSelectionId;

export const deserializeComponentSelectionId = (id: ComponentSelectionId) => {
	const [componentType, ...componentIdSections] = id.split('#');

	return { componentType, componentId: componentIdSections.join('#') } as {
		componentType: 'joint' | 'element';
		componentId: ComponentId;
	};
};

enum ExplorerMode {
	IDLE = 'IDLE',
	VISUALIZE_RESULTS = 'VISUALIZE_RESULTS',
}

// Base state
interface State {
	mode: ExplorerMode;
	chosenSiteId: string;
	chosenSectionId: string;
	elementDimensionConfig: DimensionConfiguration;
	jointDimensionConfig: DimensionConfiguration;
	selectedComponents: ComponentSelectionId[];
	highlightedComponent: ComponentSelectionId | null;
	numberOfExplorerElements: number;
	analysisExplorerPanel: 'hidden' | 'small' | 'large';
	dimensionChooser: 'hidden' | 'showElement' | 'showJoint';
	sensorLocations: SensorLocation[];
	selectedSensorId: string | null;
}

type EmptyDimensionConfig = {
	sortedDimension: null;
	sortDirection: 'desc';
	includeChildResults: ComponentId[];
	dimensions: DimensionConfigurationDimension[];
};

const emptyDimensionConfig: EmptyDimensionConfig = {
	sortedDimension: null,
	sortDirection: 'desc',
	includeChildResults: [],
	dimensions: [],
};

const defaultNumberOfElements = 30;

const initialState: State = {
	mode: ExplorerMode.IDLE,
	chosenSiteId: 'eabf3079-6cbc-3d02-a12b-72dcda19dc0b', // Just a fake id
	chosenSectionId: 'eabf3079-6cbc-3d02-a12b-72dcda19dc0b', // Just a fake id
	elementDimensionConfig: emptyDimensionConfig,
	jointDimensionConfig: emptyDimensionConfig,
	selectedComponents: [],
	highlightedComponent: null,
	numberOfExplorerElements: defaultNumberOfElements,
	analysisExplorerPanel: 'hidden',
	dimensionChooser: 'hidden',
	sensorLocations: [],
	selectedSensorId: null,
};

export const explorer = createSlice({
	name: 'explorer',
	initialState: initialState, // Ensure TS infers the correct state type
	reducers: {
		setAnalysisExplorerPanelState: (
			state,
			action: PayloadAction<{
				action: 'hide' | 'set-small' | 'set-large' | 'toggle' | 'toggleSize';
			}>
		): State => {
			let wantedState: 'hidden' | 'small' | 'large' = 'hidden';

			switch (action.payload.action) {
				case 'hide':
					wantedState = 'hidden';
					break;
				case 'set-small':
					wantedState = 'small';
					break;
				case 'set-large':
					wantedState = 'large';
					break;
				case 'toggle':
					wantedState =
						state.analysisExplorerPanel === 'hidden' ? 'small' : 'hidden';
					break;
				case 'toggleSize':
					if (state.analysisExplorerPanel === 'hidden') {
						return state;
					}
					wantedState =
						state.analysisExplorerPanel === 'small' ? 'large' : 'small';
					break;
				default:
					never(action.payload.action);
			}

			// If already there, do nothing
			if (wantedState === state.analysisExplorerPanel) {
				return state;
			}

			return {
				...state,
				analysisExplorerPanel: wantedState,
			};
		},
		openDimensionChooser: (
			state,
			action: PayloadAction<{ type: 'element' | 'joint' }>
		): State => {
			return {
				...state,
				dimensionChooser:
					action.payload.type === 'element' ? 'showElement' : 'showJoint',
			};
		},
		closeDimensionChooser: (state): State => {
			if (state.dimensionChooser === 'hidden') {
				return state;
			}

			return {
				...state,
				dimensionChooser: 'hidden',
			};
		},
		updateChosenDimensions: (
			state,
			action: PayloadAction<{
				dimensions: DimensionConfigurationDimension[];
				type: 'elements' | 'joints';
			}>
		): State => {
			const dimensionConfigKey =
				action.payload.type === 'elements'
					? 'elementDimensionConfig'
					: 'jointDimensionConfig';
			const dimensionConfigState = state[dimensionConfigKey];

			// If no elements are selected, reset the config
			if (action.payload.dimensions.length === 0) {
				return {
					...state,
					mode: ExplorerMode.IDLE,
					numberOfExplorerElements: defaultNumberOfElements,
					[dimensionConfigKey]: emptyDimensionConfig,
				};
			}

			// If there is no sorted column, or the previously sorted column is no longer chosen, default to the first chosen dimension
			if (
				L.isNull(dimensionConfigState.sortedDimension) ||
				!isDimensionStillChosen(dimensionConfigState.sortedDimension)(
					action.payload.dimensions
				)
			) {
				return {
					...state,
					mode: ExplorerMode.VISUALIZE_RESULTS,
					[dimensionConfigKey]: {
						...state.elementDimensionConfig,
						dimensions: action.payload.dimensions,
						sortDirection: 'desc',
						sortedDimension: action.payload.dimensions[0].id,
					},
				};
			}

			// Otherwise do a simple update of the chosen dimensions
			return {
				...state,
				mode: ExplorerMode.VISUALIZE_RESULTS,
				[dimensionConfigKey]: {
					...dimensionConfigState,
					dimensions: action.payload.dimensions,
				},
			};
		},
		setElementSort: (
			state,
			action: PayloadAction<{ dimensionId: string; direction: 'asc' | 'desc' }>
		): State => {
			// If the dimension is not chosen, it can't be sorted
			if (
				!doesDimConfigContainDimension(
					state.elementDimensionConfig,
					action.payload.dimensionId
				)
			) {
				return state;
			}

			return {
				...state,
				elementDimensionConfig: {
					...state.elementDimensionConfig,
					sortDirection: action.payload.direction,
					sortedDimension: action.payload.dimensionId,
					// Remove child selections, since they may be scrolled out of view
					includeChildResults: [],
				},
			};
		},
		setJointSort: (
			state,
			action: PayloadAction<{ dimensionId: string; direction: 'asc' | 'desc' }>
		): State => {
			// If the dimension is not chosen, it can't be sorted
			if (
				!doesDimConfigContainDimension(
					state.jointDimensionConfig,
					action.payload.dimensionId
				)
			) {
				return state;
			}

			return {
				...state,
				jointDimensionConfig: {
					...state.jointDimensionConfig,
					sortDirection: action.payload.direction,
					sortedDimension: action.payload.dimensionId,
					// Remove child selections, since they may be scrolled out of view
					includeChildResults: [],
				},
			};
		},
		removeElementDimension: (
			state,
			action: PayloadAction<{ dimensionId: string }>
		): State => {
			// If dimension is not selected, abort
			if (
				!state.elementDimensionConfig.dimensions.find(
					(configDim) => configDim.id === action.payload.dimensionId
				)
			) {
				return state;
			}

			// Remove the dimension from the array
			const dimensions = state.elementDimensionConfig.dimensions.filter(
				(configDim) => configDim.id !== action.payload.dimensionId
			);

			// If we have no left, we should return the empty dimension config and reset element
			if (dimensions.length === 0) {
				return {
					...state,
					// Add the empty configuration
					elementDimensionConfig: emptyDimensionConfig,
					// Filter away selected element components
					selectedComponents: state.selectedComponents.filter((id) => {
						const { componentType } = deserializeComponentSelectionId(id);

						return componentType !== 'element';
					}),
				};
			}

			// Determine if the removed element was also the sorted element
			const isSorted =
				state.elementDimensionConfig.sortedDimension ===
				action.payload.dimensionId;

			return {
				...state,
				elementDimensionConfig: {
					...state.elementDimensionConfig,
					dimensions,
					// If the element was sorted, choose the first element in the list
					sortedDimension: isSorted
						? dimensions[0].id
						: state.elementDimensionConfig.sortedDimension,
					// If the element was sorted, set the direction to the default
					sortDirection: isSorted
						? emptyDimensionConfig.sortDirection
						: state.elementDimensionConfig.sortDirection,
					// Remove child selections, since they may be scrolled out of view
					includeChildResults: [],
				} as DimensionConfiguration,
			};
		},
		removeJointDimension: (
			state,
			action: PayloadAction<{ dimensionId: string }>
		): State => {
			// If dimension is not selected, abort
			if (
				!state.jointDimensionConfig.dimensions.find(
					(configDim) => configDim.id === action.payload.dimensionId
				)
			) {
				return state;
			}

			// Remove the dimension from the array
			const dimensions = state.jointDimensionConfig.dimensions.filter(
				(configDim) => configDim.id !== action.payload.dimensionId
			);

			// If we have no left, we should return the empty dimension config
			if (dimensions.length === 0) {
				return {
					...state,
					// Add the empty configuration
					jointDimensionConfig: emptyDimensionConfig,
					// Filter away selected joint components
					selectedComponents: state.selectedComponents.filter((id) => {
						const { componentType } = deserializeComponentSelectionId(id);

						return componentType !== 'joint';
					}),
				};
			}

			// Determine if the removed element was also the sorted element
			const isSorted =
				state.jointDimensionConfig.sortedDimension ===
				action.payload.dimensionId;

			return {
				...state,
				jointDimensionConfig: {
					...state.jointDimensionConfig,
					dimensions,
					// If the element was sorted, choose the first element in the list
					sortedDimension: isSorted
						? dimensions[0].id
						: state.jointDimensionConfig.sortedDimension,
					// If the element was sorted, set the direction to the default
					sortDirection: isSorted
						? emptyDimensionConfig.sortDirection
						: state.jointDimensionConfig.sortDirection,
					// Remove child selections, since they may be scrolled out of view
					includeChildResults: [],
				} as DimensionConfiguration,
			};
		},
		foldOutElement: (
			state,
			action: PayloadAction<{ componentId: string }>
		): State => {
			if (
				// If the element is already selected, don't do anything
				state.elementDimensionConfig.includeChildResults.includes(
					action.payload.componentId as ComponentId
				)
			) {
				return state;
			}

			return {
				...state,
				elementDimensionConfig: {
					...state.elementDimensionConfig,
					includeChildResults: [
						...state.elementDimensionConfig.includeChildResults,
						action.payload.componentId as ComponentId,
					],
				},
			};
		},
		foldInElement: (
			state,
			action: PayloadAction<{ componentId: string }>
		): State => {
			if (
				// If the element is not selected, don't do anything
				!state.elementDimensionConfig.includeChildResults.includes(
					action.payload.componentId as ComponentId
				)
			) {
				return state;
			}

			return {
				...state,
				elementDimensionConfig: {
					...state.elementDimensionConfig,
					includeChildResults: [
						...state.elementDimensionConfig.includeChildResults.filter(
							(id) => id !== action.payload.componentId
						),
					],
				},
			};
		},
		foldOutJoint: (
			state,
			action: PayloadAction<{ componentId: string }>
		): State => {
			if (
				// If the element is already selected, don't do anything
				state.jointDimensionConfig.includeChildResults.includes(
					action.payload.componentId as ComponentId
				)
			) {
				return state;
			}

			return {
				...state,
				jointDimensionConfig: {
					...state.jointDimensionConfig,
					includeChildResults: [
						...state.jointDimensionConfig.includeChildResults,
						action.payload.componentId as ComponentId,
					],
				},
			};
		},
		foldInJoint: (
			state,
			action: PayloadAction<{ componentId: string }>
		): State => {
			if (
				// If the element is not selected, don't do anything
				!state.jointDimensionConfig.includeChildResults.includes(
					action.payload.componentId as ComponentId
				)
			) {
				return state;
			}

			return {
				...state,
				jointDimensionConfig: {
					...state.jointDimensionConfig,
					includeChildResults: [
						...state.jointDimensionConfig.includeChildResults.filter(
							(id) => id !== action.payload.componentId
						),
					],
				},
			};
		},
		toggleComponentSelection: (
			state,
			action: PayloadAction<{ componentSelectionId: ComponentSelectionId }>
		): State => {
			// If the component is already selected, remove it from the list
			if (
				state.selectedComponents.includes(action.payload.componentSelectionId)
			) {
				return {
					...state,
					selectedComponents: state.selectedComponents.filter(
						(selection) => selection !== action.payload.componentSelectionId
					),
					// Remove highlight
					// This is needed since the table hover interaction is broken by the
					// element changing positions and not triggering pointer out event
					highlightedComponent: null,
				};
			}

			// Only add the selection if there are dimensions selected of the same type
			const { componentType } = deserializeComponentSelectionId(
				action.payload.componentSelectionId
			);

			if (
				(componentType === 'element' &&
					state.elementDimensionConfig.dimensions.length === 0) ||
				(componentType === 'joint' &&
					state.jointDimensionConfig.dimensions.length === 0)
			) {
				return state;
			}

			// Otherwise add it to the list
			return {
				...state,
				selectedComponents: state.selectedComponents.concat(
					action.payload.componentSelectionId
				),
				// Remove highlight - see above comment
				highlightedComponent: null,
			};
		},
		clearComponentSelection: (state): State => ({
			...state,
			selectedComponents: [],
			elementDimensionConfig: {
				...state.elementDimensionConfig,
				includeChildResults: [],
			},
			jointDimensionConfig: {
				...state.jointDimensionConfig,
				includeChildResults: [],
			},
		}),
		setComponentHighlight: (
			state,
			action: PayloadAction<{ componentSelectionId: ComponentSelectionId }>
		): State => {
			// Do not set highlight of components without a selected dimension
			const { componentType } = deserializeComponentSelectionId(
				action.payload.componentSelectionId
			);

			// If the matching type of DimensionConfiguration has no selected dimensions (ie. no results in the table)
			// do not highlight this component
			if (
				(componentType === 'element' &&
					state.elementDimensionConfig.dimensions.length === 0) ||
				(componentType === 'joint' &&
					state.jointDimensionConfig.dimensions.length === 0)
			) {
				return state;
			}

			return {
				...state,
				highlightedComponent: action.payload.componentSelectionId,
			};
		},
		stopComponentHighlight: (
			state,
			action: PayloadAction<{ componentSelectionId?: ComponentSelectionId }>
		): State => {
			// Since we may get some race conditions we check explicitly if the element matches the currently hovered one
			// This is only a problem in the table and should perhaps be addressed there in the emitter instead of here
			if (
				action.payload.componentSelectionId === undefined ||
				action.payload.componentSelectionId === state.highlightedComponent
			) {
				return {
					...state,
					highlightedComponent: null,
				};
			}

			// Otherwise we leave it alone
			return state;
		},
		setSensorData: (state, action: PayloadAction<SensorLocation[]>): State => {
			state.sensorLocations = action.payload;

			return state;
		},
		setSelectedSensor: (state, action: PayloadAction<string | null>) => {
			state.selectedSensorId = action.payload;

			return state;
		},
	},
});

/** Determine if a dimension is part of the given DimensionConfiguration */
const doesDimConfigContainDimension = (
	dimConfig: DimensionConfiguration,
	dimensionId: string
): boolean => dimConfig.dimensions.map(O.prop('id')).includes(dimensionId);

const isDimensionStillChosen =
	(dimension: string) =>
	(chosenDimensions: DimensionConfigurationDimension[]) => {
		return chosenDimensions.map(O.prop('id')).includes(dimension);
	};

// Action creators are generated for each case reducer function
export const {
	updateChosenDimensions,
	setAnalysisExplorerPanelState,
	openDimensionChooser,
	closeDimensionChooser,
	setElementSort,
	removeElementDimension,
	removeJointDimension,
	setJointSort,
	foldInElement,
	foldOutElement,
	foldInJoint,
	foldOutJoint,
	toggleComponentSelection,
	setComponentHighlight,
	stopComponentHighlight,
	clearComponentSelection,
	setSensorData,
	setSelectedSensor,
} = explorer.actions;
