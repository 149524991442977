import classNames from 'classnames';
import { FC } from 'react';
import { DashboardStatus } from '../../types/DashboardStatus';
import './ProgressBar.scss';

type ProgressBarProps = {
	progress: number;
	status: DashboardStatus;
	thresholds: (number | null)[];
};

export const ProgressBar: FC<ProgressBarProps> = ({
	thresholds,
	progress,
	status,
}) => (
	<div className="ProgressBar">
		<div
			className={classNames(['ProgressBar__Bar', `ProgressBar__Bar-${status}`])}
			style={{ width: `${progress}%` }}
		></div>
		{thresholds.map(
			(point) => point && <ProgressBarBreakpoint position={point} key={point} />
		)}
	</div>
);

type ProgressBarBreakpointProps = {
	position: number;
};

const ProgressBarBreakpoint: FC<ProgressBarBreakpointProps> = ({
	position,
}) => (
	<div className="ProgressBarBreakpoint" style={{ left: `${position}%` }}></div>
);
