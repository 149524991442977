import { useThree } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import { Color, DirectionalLight } from 'three';
import { configuration } from '../../../Core/configuration/configuration';
import { useSkyBox } from '../useSkyBox';

export const Stage = () => {
	const fogColor = useMemo(
		() => new Color(configuration.explorer.fogColor).convertSRGBToLinear(),
		[]
	);

	return (
		<>
			<SkyBox />
			<fog attach="fog" args={[fogColor, 0, 5000]} />
			<Lighting />
		</>
	);
};

function SkyBox() {
	// Loads the skybox texture and applies it to the scene.
	const { scene } = useThree();
	const texture = useSkyBox();

	// Set the scene background property to the resulting texture.
	scene.background = texture;

	return null;
}

function Lighting() {
	// Set to true to get helpers for light placement
	// const showHelpers = false;

	const mainLight = useRef<DirectionalLight>();
	const backTopLeft = useRef<DirectionalLight>();
	const frontTopLeft = useRef<DirectionalLight>();
	const backTopRight = useRef<DirectionalLight>();
	// const frontTopRight = useRef<DirectionalLight>();
	const backBotLeft = useRef<DirectionalLight>();
	const frontBotLeft = useRef<DirectionalLight>();
	const backBotRight = useRef<DirectionalLight>();
	const frontBotRight = useRef<DirectionalLight>();

	// useHelper(showHelpers && mainLight, DirectionalLightHelper, 10, 'yellow');
	// useHelper(showHelpers && backTopLeft, DirectionalLightHelper, 2, 'green');
	// useHelper(showHelpers && frontTopLeft, DirectionalLightHelper, 2, 'blue');
	// useHelper(showHelpers && backTopRight, DirectionalLightHelper, 2, 'cyan');
	// useHelper(showHelpers && frontTopRight, DirectionalLightHelper, 2, 'orange');
	// useHelper(showHelpers && backBotLeft, DirectionalLightHelper, 2, 'lime');
	// useHelper(showHelpers && frontBotLeft, DirectionalLightHelper, 2, 'purple');
	// useHelper(showHelpers && backBotRight, DirectionalLightHelper, 2, 'teal');
	// useHelper(showHelpers && frontBotRight, DirectionalLightHelper, 2, 'red');

	const mainFactor = 0.75;
	const otherFactor = 1.3;

	const ambientStrength = 0.04;
	const mainStrength = 0.72 * mainFactor;
	const moodLightAboveStrength = 0.144 * otherFactor;
	const moodLightBelowStrength = 0.04 * otherFactor;
	const moodLightBounceStrength = 0.2 * otherFactor;

	// Define the distance and height values for all lights
	const distance = 100;
	const height = 80;

	// User friendly position values
	const positions = {
		top: height,
		bottom: -height,
		left: -distance,
		right: distance,
		back: -distance,
		front: distance,
	};

	const lightColor = useMemo(
		() => new Color(configuration.explorer.lightColor).convertSRGBToLinear(),
		[]
	);

	return (
		<group>
			<ambientLight args={[lightColor, ambientStrength]} />

			<directionalLight
				ref={mainLight}
				args={[lightColor, mainStrength]}
				position={[positions.right, positions.top, positions.front]}
				castShadow
				shadow-camera-left={-1000}
				shadow-camera-right={1000}
				shadow-camera-top={1000}
				shadow-camera-bottom={-1000}
				shadow-camera-far={2000}
				shadow-camera-near={-2000}
				shadow-mapSize-width="4096"
				shadow-mapSize-height="4096"
			/>

			<directionalLight
				name="BackTopLeft"
				ref={backTopLeft}
				args={[lightColor, moodLightAboveStrength]}
				position={[positions.left, positions.top, positions.back]}
			/>
			<directionalLight
				name="FrontTopLeft"
				ref={frontTopLeft}
				args={[lightColor, moodLightAboveStrength]}
				position={[positions.left, positions.top, positions.front]}
			/>
			<directionalLight
				name="BackTopRight"
				ref={backTopRight}
				args={[lightColor, moodLightAboveStrength]}
				position={[positions.right, positions.top, positions.back]}
			/>
			<directionalLight
				name="BackBottomLeft"
				ref={backBotLeft}
				args={[lightColor, moodLightBounceStrength]}
				position={[positions.left, positions.bottom, positions.back]}
			/>
			<directionalLight
				name="FrontBottomLeft"
				ref={frontBotLeft}
				args={[lightColor, moodLightBelowStrength]}
				position={[positions.left, positions.bottom, positions.front]}
			/>
			<directionalLight
				name="BackBottomRight"
				ref={backBotRight}
				args={[lightColor, moodLightBelowStrength]}
				position={[positions.right, positions.bottom, positions.back]}
			/>
			<directionalLight
				name="FrontBottomRight"
				ref={frontBotRight}
				args={[lightColor, moodLightBelowStrength]}
				position={[positions.right, positions.bottom, positions.front]}
			/>
		</group>
	);
}
