import { ReactNode } from 'react';

import './ConfiguratorBody.scss';

type Props = {
	children: ReactNode;
};

export const ConfiguratorBody = ({ children }: Props) => {
	return <div className="ConfiguratorBody">{children}</div>;
};
