import { FC, useEffect, useState } from 'react';
import { noop } from '../../utils/Function';
import { isDefined } from '../../utils/Logic';

type Props = {
	date?: Date;
	onChange?: (date: Date) => void;
	className?: string;
};

export const CalendarDateInput: FC<Props> = ({
	date,
	onChange = noop,
	className = 'CalendarDateInput',
}) => {
	const [value, setValue] = useState<string>(
		isDefined(date) ? formatDateAsString(date) : ''
	);

	/** Handler for when the value is to be "committed" */
	const handleValue = () => {
		// Parse the input date to see if it is valid
		const inputDate = formatStringAsDate(value);

		// If the input is invalid, return input value to the previous date input
		if (inputDate === null) {
			setValue(isDefined(date) ? formatDateAsString(date) : '');
			return;
		}

		// If the input date is the same as the previous value, no need to do anything
		// Also if there was no previous date (undefined), we always publish the change
		if (isDefined(date) && inputDate.getTime() === date.getTime()) {
			return;
		}

		// Otherwise publish the new date value
		onChange(inputDate);
	};

	/** Handler for Enter key events */
	const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
		switch (event.code) {
			case 'Enter':
			case 'NumpadEnter':
				handleValue();
		}
	};

	// If the input date changes, set the input value
	useEffect(() => {
		setValue(isDefined(date) ? formatDateAsString(date) : '');
	}, [date]);

	return (
		<input
			onBlur={handleValue}
			onKeyDown={onKeyDown}
			onChange={(event) => setValue(event.target.value)}
			type="text"
			value={value}
			className={className}
		/>
	);
};

/** Formats a date as  DD/MM/YYYY  */
const formatDateAsString = (date: Date) => {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${year}/${padNumber(month)}/${padNumber(day)}`;
};

/** Converts a DD/MM/YYYY string to a Date or null if invalid */
const formatStringAsDate = (value: string): Date | null => {
	const [yearString, monthString, dateString] = value.split('/');

	// If any of the string parts are undefined, we can't parse
	// @ts-ignore As the parser thinks split can't return undefined
	if ([yearString, monthString, dateString].includes(undefined)) {
		return null;
	}

	// Parsed the date with an ISO timestamp format
	const parsedTimestamp = Date.parse(
		`${yearString}-${padString(monthString)}-${padString(dateString)}`
	);

	// If the parsing failed, return null
	if (isNaN(parsedTimestamp)) {
		return null;
	}

	return new Date(parsedTimestamp);
};

/** Format a number as a string with prepended 0 until the given length */
const padNumber = (value: number, length = 2): string => {
	return `${value}`.padStart(length, '0');
};

/** Pad a string with 0 to a given length */
const padString = (value: string, length = 2) => {
	return value.padStart(length, '0');
};
