import classNames from 'classnames';
import { FC } from 'react';
import './Card.scss';

type CardProps = {
	padding?: 'default' | 'small' | 'large' | 'tiny';
	theme?: 'default' | 'dark' | 'light';
	radiusPosition?: 'top' | 'bottom' | 'left' | 'right' | 'all';
} & JSX.IntrinsicElements['div'];

export const Card: FC<CardProps> = ({
	children,
	padding = 'default',
	theme = 'default',
	radiusPosition = 'all',
	...props
}) => (
	<div
		{...props}
		className={classNames([
			'Card',
			`Card--Theme-${theme}`,
			`Card--Padding-${padding}`,
			`Card--Radius-${radiusPosition}`,
		])}
	>
		{children}
	</div>
);
