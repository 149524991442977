import classNames from 'classnames';

import {
	DataTable,
	TableCellColumn,
	TableColumn,
} from '../../Core/components/DataTable/DataTable';

import './ResultsTable.scss';

import { ResultBar } from './ResultBar';
import { Icon } from '../../Core/components/Icon/Icon';
import { CSSProperties, Fragment, useCallback, useRef, useState } from 'react';
import { never, noop } from '../../Core/utils/Function';
import { any, includes } from '../../Core/utils/Array';
import { isUndefined } from '../../Core/utils/Logic';
import { IconButton } from '../../Core/components/IconButton/IconButton';
import { Spinner } from '../../Core/components/Spinner/Spinner';
import { ToolTip } from '../../Core/components/ToolTip/ToolTip';
import {
	PopMenu,
	PopMenuItems,
	PopMenuState,
} from '../../Core/components/PopMenu/PopMenu';
import { Result, ResultColor } from '../../SharedTypes/API/Explorer';
import * as S from '../../Core/utils/String';

interface BraceResult {
	key: string;
	type: 'Brace';
	name: string;
	results: { [dimensionId: string]: Result };
}

interface SubElementResult {
	key: string;
	type: 'SubElement';
	name: string;
	results: { [dimensionId: string]: Result };
}

type ResultChild = BraceResult | SubElementResult;

export interface ResultRow {
	key: string;
	type: 'Joint' | 'Element';
	name: string;
	hasChildren: boolean;
	children: ResultChild[];
	results: { [dimensionId: string]: Result | undefined };
}

export type ResultTableColumn = TableColumn & {
	tooltip?: string;
};
export type ResultTableCellColumn = TableCellColumn & {
	tooltip?: string;
};

export interface ResultRowSorting {
	column: string;
	direction: 'desc' | 'asc';
}

// --- Utility functions ---

// Generate unique keys for loops in the result and chooser rows
const INFO_KEY = '#%!RESULT_KEY!%#';
const CHOOSER_KEY = '#%!CHOOSER_KEY!%#';

// Generic function to check the column for a specific key
const getColumnHasKey = (key: string) => (column: { key: string }) =>
	column.key === key;

// Column type checkers
const isInfoColumn = getColumnHasKey(INFO_KEY);
const isChooserColumn = getColumnHasKey(CHOOSER_KEY);
const isResultColumn = (column: { key: string }) =>
	!(isInfoColumn(column) || isChooserColumn(column));

// --- Components --
export const ResultsTable = ({
	rows,
	selectedRows,
	highlightedRowKey,
	columns,
	sorting,
	showChooserColumn = true,
	isLoading = false,
	onFoldOut,
	onFoldIn,
	onSortDimension,
	onRemoveDimension,
	onChooserClick = noop,
	onLoadMore = noop,
	onClearSelection = noop,
	onParentClick,
	onRowStartHover,
	onRowEndHover,
}: {
	rows: ResultRow[];
	selectedRows: ResultRow[];
	highlightedRowKey: string | null;
	columns: ResultTableColumn[];
	sorting: ResultRowSorting;
	showChooserColumn?: boolean;
	isLoading?: boolean;
	onLoadMore?: () => void;
	onClearSelection?: () => void;
	onFoldOut: (key: string) => void;
	onFoldIn: (key: string) => void;
	onSortDimension: (args: {
		dimensionId: string;
		direction: 'asc' | 'desc';
	}) => void;
	onRemoveDimension: (dimensionId: string) => void;
	onChooserClick?: () => void;
	onParentClick: (key: string) => void;
	onRowStartHover: (key: string) => void;
	onRowEndHover: (key: string) => void;
}) => {
	// Add the first "info" column
	// And the last "chooser" column if wanted
	const tableColumns: ResultTableColumn[] = [
		{
			key: INFO_KEY,
			title: '',
			subtitle: '',
			width: '115px',
			isSticky: true,
		},
		...columns,
		...(showChooserColumn
			? [
					{
						key: CHOOSER_KEY,
						title: '',
						subtitle: '',
						width: '162px',
						isSticky: false,
					},
			  ]
			: []),
	];

	const loadingElement = isLoading ? (
		<div className="ResultsTableLoading">
			<Spinner />
		</div>
	) : null;

	function handleSortDimension(direction: 'asc' | 'desc') {
		return (column: string) => {
			onSortDimension({ dimensionId: column, direction });
		};
	}

	return (
		<>
			{loadingElement}
			<DataTable
				className="ResultsTable"
				rows={rows}
				selectedRows={selectedRows}
				columns={tableColumns}
				style={{ width: '100%', height: '100%', backgroundColor: '#002532' }}
				onLoadMore={onLoadMore}
				onClearSelection={onClearSelection}
				headerComponent={({ columns, style }) => (
					<ResultsTableHeaderRow
						columns={columns}
						sorting={sorting}
						style={style}
						onChooserClick={onChooserClick}
						onSortAsc={handleSortDimension('asc')}
						onSortDesc={handleSortDimension('desc')}
						onRemove={onRemoveDimension}
					/>
				)}
				rowComponent={({ columns, row, style }) => (
					<ResultsTableRow
						columns={columns}
						row={row}
						style={style}
						onFoldIn={onFoldIn}
						onFoldOut={onFoldOut}
						key={row.key}
						onRowClick={onParentClick}
						isSelected={false}
						highlightedKey={highlightedRowKey}
						onRowStartHover={onRowStartHover}
						onRowEndHover={onRowEndHover}
					/>
				)}
				selectedRowComponent={({ columns, row, style }) => (
					<ResultsTableRow
						columns={columns}
						row={row}
						style={style}
						onFoldIn={onFoldIn}
						onFoldOut={onFoldOut}
						key={row.key}
						onRowClick={onParentClick}
						isSelected={true}
						highlightedKey={highlightedRowKey}
						onRowStartHover={onRowStartHover}
						onRowEndHover={onRowEndHover}
					/>
				)}
			/>
		</>
	);
};

const Caret = ({
	direction,
	onClick = noop,
}: {
	direction: 'up' | 'down' | 'left' | 'right';
	onClick?: () => void;
}) => {
	const directionToRotation = {
		up: -90,
		right: 0,
		down: 90,
		left: 180,
	};

	const style: CSSProperties = {
		transform: `rotate(${directionToRotation[direction]}deg)`,
		transition: `transform 200ms ease-out`,
	};

	return (
		<div
			className="ResultsTable__Caret"
			onClick={(event) => {
				event.stopPropagation();
				onClick();
			}}
		>
			<Icon name="CaretRight" style={style} width={5} />
		</div>
	);
};

/** Format labels with commas, may be replaced in the future */
function temporaryFormatLabel(label: string) {
	const [, first, last] = label.split('_');

	if (isUndefined(last)) {
		return label;
	}

	return `${S.upperFirst(first)}, ${last.toUpperCase()}`;
}

const ResultsTableRow = ({
	row,
	columns,
	style,
	isSelected,
	highlightedKey,
	onFoldOut,
	onFoldIn,
	onRowClick,
	onRowStartHover,
	onRowEndHover,
}: {
	row: ResultRow;
	columns: ResultTableCellColumn[];
	style: CSSProperties;
	isSelected: boolean;
	highlightedKey: string | null;
	onFoldOut: (key: string) => void;
	onFoldIn: (key: string) => void;
	onRowClick: (key: string) => void;
	onRowStartHover: (key: string) => void;
	onRowEndHover: (key: string) => void;
}) => {
	const hasChildren = row.hasChildren;
	const areChildrenFoldedOut = any(row.children);
	const isParentHighlighted = highlightedKey === row.key;

	type FoldOutState = 'visible' | 'hidden' | 'none';
	const foldOutState: FoldOutState = hasChildren
		? areChildrenFoldedOut
			? 'visible'
			: 'hidden'
		: 'none';
	const showCaret = includes<FoldOutState>(['hidden', 'visible'])(foldOutState);
	const isUnfolded = includes<FoldOutState>(['visible'])(foldOutState);

	const handleFoldClick = useCallback(
		(key: string) => {
			switch (foldOutState) {
				case 'none':
					return;
				case 'visible':
					return onFoldIn(key);
				case 'hidden':
					return onFoldOut(key);
				default:
					never(foldOutState);
			}
		},
		[foldOutState, onFoldIn, onFoldOut]
	);

	const handleRowClick = useCallback(() => {
		onRowClick(row.key);
	}, [onRowClick, row.key]);

	const handleParentStartHover = useCallback(() => {
		onRowStartHover(row.key);
	}, [onRowStartHover, row.key]);

	const handleParentEndHover = useCallback(() => {
		onRowEndHover(row.key);
	}, [onRowEndHover, row.key]);

	const handleChildStartHover = useCallback(
		(key: string) => {
			onRowStartHover(key);
		},
		[onRowStartHover]
	);

	const handleChildEndHover = useCallback(
		(key: string) => {
			onRowEndHover(key);
		},
		[onRowEndHover]
	);

	// Get the first row (wrapped in array for easier iteration)
	const infoColumns = columns.filter(isInfoColumn);

	// Get the chooser row, if activaed (also wrapped in array)
	const chooserColumns = columns.filter(isChooserColumn);

	// Get the results columns
	const resultColumns = columns.filter(isResultColumn);

	// Get the children cells for this row
	const children = hasChildren && isUnfolded ? row.children : [];

	return (
		<div style={style} className="ResultsTableRow">
			{infoColumns.map(({ key, style }) => {
				// The info cell should always be the first column, so this is safe
				const isFirstColumn = true;

				// The info cell is the last column only if there is only 1 column in the table
				const isLastColumn = columns.length === 1;

				return (
					<div
						className={classNames(
							'ResultsTableRow__ParentCell',
							'ResultsTableRow__Info',
							{
								isSelected,
								isHighlighted: isParentHighlighted,
								isUnfolded,
								isLastColumn,
								isFirstColumn,
							}
						)}
						style={style}
						key={key}
						onDoubleClick={handleRowClick}
						onPointerEnter={handleParentStartHover}
						onPointerLeave={handleParentEndHover}
					>
						<div className="ResultsTableRow__InfoNameWrapper">
							{showCaret ? (
								<Caret
									direction={isUnfolded ? 'down' : 'right'}
									onClick={() => handleFoldClick(row.key)}
								/>
							) : null}

							<div className="ResultsTableRow__InfoName">{row.name}</div>
						</div>
						<div className="ResultsTableRow__InfoType">{row.type}</div>
					</div>
				);
			})}

			{resultColumns.map(({ key, style }, i) => {
				// The result cells should never be the first column, so this is safe
				const isFirstColumn = false;

				// The last results cell should be considered the last column
				const isLastColumn = i === resultColumns.length - 1;

				// Get the results to render the bar
				const results = row.results[key] ?? [];
				const valueText = `${results?.[0] ?? 'N/A'}`;
				const color: ResultColor = results?.[2] ?? 'none';
				const width: number = results?.[3] ?? 0;
				const label: string = results?.[4]
					? temporaryFormatLabel(results[4])
					: '';

				return (
					<div
						style={style}
						key={key}
						onDoubleClick={handleRowClick}
						onPointerEnter={handleParentStartHover}
						onPointerLeave={handleParentEndHover}
						className={classNames(
							'ResultsTableRow__ParentCell',
							'ResultsTableRow__Data',
							{
								isSelected,
								isHighlighted: isParentHighlighted,
								isUnfolded,
								isLastColumn,
								isFirstColumn,
							}
						)}
					>
						<ResultBar
							color={color}
							valueText={valueText}
							label={label}
							width={width}
						/>
					</div>
				);
			})}

			{chooserColumns.map(({ key, style }) => {
				return (
					<div
						key={key}
						style={style}
						className={classNames('ResultsTableRow__ChooserCell', {
							isFirstRow: true,
							isLastRow: children.length === 0,
						})}
					></div>
				);
			})}

			{children.map((child, childNum) => {
				const isLastChild = childNum === children.length - 1;
				const isHighlighted = child.key === highlightedKey;

				return (
					<Fragment key={child.key}>
						{infoColumns.map(({ key, style }) => {
							// The info cell should always be the first column, so this is safe
							const isFirstColumn = true;

							// The info cell is the last column only if there is only 1 column in the table
							const isLastColumn = columns.length === 1;

							return (
								<div
									className={classNames(
										'ResultsTableRow__ChildCell',
										'ResultsTableRow__Info',
										{
											isLastChild,
											isLastColumn,
											isFirstColumn,
											isHighlighted,
										}
									)}
									key={key}
									style={style}
									onPointerEnter={() => handleChildStartHover(child.key)}
									onPointerLeave={() => handleChildEndHover(child.key)}
								>
									<div className="ResultsTableRow__InfoName">{child.name}</div>
									<div className="ResultsTableRow__InfoType">{child.type}</div>
								</div>
							);
						})}
						{resultColumns.map(({ key, style }, i) => {
							// The result cells should never be the first column, so this is safe
							const isFirstColumn = false;

							// The last results cell should be considered the last column
							const isLastColumn = i === resultColumns.length - 1;

							// Get the results to render the bar
							const results = child.results[key] ?? [];
							const valueText = `${results?.[0]}`;
							const color = results?.[2];
							const width = results?.[3];
							const label = results?.[4]
								? temporaryFormatLabel(results[4])
								: undefined;

							return (
								<div
									className={classNames('ResultsTableRow__ChildCell', {
										isLastChild,
										isLastColumn,
										isFirstColumn,
										isHighlighted,
									})}
									key={key}
									style={style}
									onPointerEnter={() => handleChildStartHover(child.key)}
									onPointerLeave={() => handleChildEndHover(child.key)}
								>
									<ResultBar
										color={color}
										valueText={valueText}
										label={label}
										width={width}
									/>
								</div>
							);
						})}
						{chooserColumns.map(({ key, style }) => {
							return (
								<div
									key={key}
									style={style}
									className={classNames('ResultsTableRow__ChooserCell', {
										isLastRow: isLastChild,
									})}
								></div>
							);
						})}
					</Fragment>
				);
			})}
		</div>
	);
};

const ResultsTableHeaderRow = ({
	columns,
	sorting,
	style,
	onSortAsc,
	onSortDesc,
	onRemove,
	onChooserClick,
}: {
	columns: ResultTableCellColumn[];
	sorting: ResultRowSorting;
	style: CSSProperties;
	onSortAsc: (column: string) => void;
	onSortDesc: (column: string) => void;
	onRemove: (column: string) => void;
	onChooserClick: () => void;
}) => {
	// Get the first row (wrapped in array for easier iteration)
	const infoColumns = columns.filter(isInfoColumn);

	// Get the chooser row, if activaed (also wrapped in array)
	const chooserColumns = columns.filter(isChooserColumn);

	// Get the results columns
	const resultColumns = columns.filter(isResultColumn);

	return (
		<div style={style}>
			{infoColumns.map(({ key, style }) => {
				return (
					<div
						key={key}
						className="ResultsTableHeaderColumn"
						style={style}
					></div>
				);
			})}
			{resultColumns.map((column) => (
				<ResultsTableHeaderColumn
					key={column.key}
					column={column}
					onRemove={onRemove}
					onSortAsc={onSortAsc}
					onSortDesc={onSortDesc}
					sorting={sorting}
				/>
			))}
			{chooserColumns.map(({ key, style }) => {
				return (
					<div
						className={classNames(
							'ResultsTableHeaderColumn',
							'ResultsTableHeaderColumn--Chooser'
						)}
						key={key}
						style={style}
					>
						<IconButton
							icon="CirclePlus"
							theme="blue-grey"
							iconSize={18}
							onClick={onChooserClick}
						>
							Add dimension
						</IconButton>
					</div>
				);
			})}
		</div>
	);
};

/** Split the title into separate parts to display differently in the UI */
function temporarySplitColumnTitle(
	title: string
): [title: string, subtitle: string] {
	const [first, ...rest] = title.split('_');

	return [S.upperFirst(first), rest.map(S.upperFirst).join(', ')];
}

const ResultsTableHeaderColumn = ({
	sorting,
	column,
	onRemove,
	onSortAsc,
	onSortDesc,
}: {
	column: ResultTableCellColumn;
	sorting: ResultRowSorting;
	onRemove: (column: string) => void;
	onSortAsc: (column: string) => void;
	onSortDesc: (column: string) => void;
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	// Used by ToolTip component
	const titleRef = useRef<HTMLDivElement>(null);

	// Used by PopMenu component
	const menuRef = useRef<HTMLDivElement>(null);

	const isSorted = sorting.column === column.key;

	const popMenuItems: PopMenuItems = [
		{
			key: 'remove',
			label: 'Remove dimension',
			icon: <Icon name="CircleMinusSlim" width={18} />,
		},
		{
			key: 'sort-asc',
			label: 'Sort ascending',
			icon: <Icon name="ThreeBarsAscending" width={18} />,
		},
		{
			key: 'sort-desc',
			label: 'Sort descending',
			icon: <Icon name="ThreeBarsDescending" width={18} />,
		},
	];

	// Get title and subtitle from Foo_bar type string
	const [title, subTitlePart] = temporarySplitColumnTitle(column.title);

	const subtitle = [subTitlePart, S.upperFirst(column.subtitle)]
		.filter((v) => v !== '')
		.join(', ');

	function handleMenuChangeState(state: PopMenuState) {
		setIsMenuOpen(state === 'open');
	}

	function handleMenuClick(key: string) {
		switch (key) {
			case 'remove':
				return onRemove(column.key);
			case 'sort-asc':
				return onSortAsc(column.key);
			case 'sort-desc':
				return onSortDesc(column.key);
			default:
				return;
		}
	}

	return (
		<div className="ResultsTableHeaderColumn" style={column.style}>
			<div
				className={classNames('ResultsTableHeaderColumn__TitleWrapper', {
					isSorted,
				})}
			>
				<Caret direction={sorting.direction === 'desc' ? 'down' : 'up'} />

				<ToolTip
					tip={column.tooltip ?? ''}
					elementRef={titleRef}
					isEnabled={!!column.tooltip}
				>
					<div className="ResultsTableHeaderColumn__Title" ref={titleRef}>
						{title}
					</div>
				</ToolTip>

				<PopMenu
					theme="Dark"
					items={popMenuItems}
					placement="bottom-end"
					onChangeState={handleMenuChangeState}
					onSelectItem={handleMenuClick}
					elementRef={menuRef}
					className={classNames('ResultsTableHeaderColumn__MenuIcon', {
						isOpen: isMenuOpen,
					})}
				>
					<Icon name="ThreeDotsH" width={14} ref={menuRef} />
				</PopMenu>
			</div>
			<div className="ResultsTableHeaderColumn__Subtitle">{subtitle}</div>
		</div>
	);
};
