import classNames from 'classnames';
import { useMatch } from 'react-router-dom';
import { noop } from '../../utils/Function';
import { Icon } from '../Icon/Icon';

import './MenuButton.scss';

type Props = {
	iconName: string;
	routeMatcher: string;
	onClick?: () => void;
	isDisabled: boolean;
};

export const MenuButton = ({
	iconName,
	routeMatcher,
	onClick = noop,
	isDisabled,
}: Props) => {
	const isSelected = useMatch(routeMatcher);
	const myClassName = classNames('MenuButton', {
		'is-selected': isSelected,
		'is-disabled': isDisabled,
	});

	return (
		<div className={myClassName} onClick={isDisabled ? noop : onClick}>
			<Icon name={iconName} width={18} />
		</div>
	);
};
