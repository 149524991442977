import classNames from 'classnames';
import { FC } from 'react';
import { DashboardStatus } from '../../types/DashboardStatus';
import './IndicatorDot.scss';

type IndicatorDotProps = {
	status: DashboardStatus;
	size?: number;
	color?: string;
};

export const IndicatorDot: FC<IndicatorDotProps> = ({
	status,
	size = 6,
	color,
}) => (
	<div
		style={{
			width: size,
			height: size,
			backgroundColor: color ? color : undefined,
			minWidth: size,
		}}
		className={classNames(['IndicatorDot', `IndicatorDot__Status-${status}`])}
	></div>
);
