import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useAdminInviteSiteUserMutation,
	useLazyAdminGetUserInformationByEmailQuery,
} from '../../Core/Api';
import { Button } from '../../Core/components/Button/Button';
import { FormInput } from '../../Core/components/FormInput/FormInput';
import { BackButton } from '../../Core/components/SlideInOverlay/BackButton';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { toastConfig } from '../../Core/configuration/toast';

export const AdminSiteUserInvite = () => {
	const navigate = useNavigate();
	const { selectedSiteId } = useParams<{ selectedSiteId: string }>();
	const [inviteUser] = useAdminInviteSiteUserMutation();

	const navigateBack = () => {
		navigate(-1);
	};

	const handleInviteUser = ({
		email,
		name,
	}: {
		email: string;
		name: string;
	}) => {
		inviteUser({
			siteId: selectedSiteId!,
			name,
			email,
		}).then((response) => {
			if ('error' in response) {
				// I have a typing issue here, but I don't know how to fix it
				//@ts-ignore
				toast(response.error.data.message, toastConfig.error);
				return;
			} else {
				toast(`User invited`, toastConfig.success);
				navigateBack();
			}
		});
	};

	return (
		<div className="AdminAccountEdit">
			<div className="AdminAccountEdit__Top">
				<BackButton />
			</div>
			<AdminSiteInviteForm email={''} inviteUser={handleInviteUser} />
		</div>
	);
};

const AdminSiteInviteForm = ({
	email,
	inviteUser,
}: {
	email: string;
	inviteUser: ({ email, name }: { email: string; name: string }) => void;
}) => {
	const [nameQueried, setNameQueried] = useState(false);
	const [queryUserInformation] = useLazyAdminGetUserInformationByEmailQuery();

	const { handleSubmit, control, setValue } = useForm({
		defaultValues: {
			email: email,
			name: '',
			role: '',
		},
	});

	const saveChanges = ({ email, name }: { email: string; name: string }) => {
		return inviteUser({ email, name });
	};

	const handleEmailBlur = (value: string) => {
		if (value === '') {
			return;
		}

		queryUserInformation({
			email: value,
		}).then((result) => {
			if (result.error) {
				// An error here is fine - Don't show it to the end user
				return;
			}

			const nameValue = result.data?.user?.name;
			if (nameValue) {
				setValue('name', nameValue);
				setNameQueried(true);
			}
		});
	};

	return (
		<form className="AdminAccountForm" onSubmit={handleSubmit(saveChanges)}>
			<FormInput
				control={control}
				label="E-mail"
				name="email"
				theme="Admin"
				blurEvent={handleEmailBlur}
			/>
			<FormInput
				control={control}
				label="Name"
				name="name"
				theme="Admin"
				disabled={nameQueried}
			/>
			<FormInput
				control={control}
				label="Role"
				name="role"
				theme="Admin"
				inputType="select"
				options={[{ key: 'User', label: 'User' }]}
			/>

			<Button type="submit" theme="Blue">
				Invite user
			</Button>
		</form>
	);
};
