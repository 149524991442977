/**
 * The password rules for new passwords.
 * Deliberately not enforced on login page in case the rules change in the future
 */
export const passwordRules = {
	containsUppercaseLetter(password: string) {
		return /[A-Z]/.test(password);
	},
	containsLowercaseLetter(password: string) {
		return /[a-z]/.test(password);
	},
	containsNumber(password: string) {
		return /[0-9]/.test(password);
	},
	containsSpecialCharacter(password: string) {
		return /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`+=]/.test(password);
	},
	minimumEightCharacters(password: string) {
		return password.length >= 8;
	},
};
