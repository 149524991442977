import { ToastOptions } from 'react-toastify';

export const toastConfig: { [x: string]: ToastOptions<{}> } = {
	error: {
		type: 'error',
		className: 'Toast Toast--Error',
		progressClassName: 'Toast__Progress Toast__Progress--Error',
	},
	success: {
		type: 'success',
		className: 'Toast Toast--Success',
		progressClassName: 'Toast__Progress Toast__Progress--Success',
	},
};
