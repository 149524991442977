import { configureStore } from '@reduxjs/toolkit';
import { login } from '../../Login/Login.slice';
import { dashboard } from '../../RIAMS/Dashboard/Dashboard.slice';
import { elementDetailsReducer } from '../../RIAMS/ElementDetails/ElementDetailsOverlay/elementDetails.slice';
import { explorer } from '../../RIAMS/Explorer/Explorer.slice';
import { api } from '../Api';
import { authorizationMiddleware } from './authorizationMiddleware';

export const store = configureStore({
	reducer: {
		elementDetails: elementDetailsReducer,
		[api.reducerPath]: api.reducer,
		explorer: explorer.reducer,
		dashboard: dashboard.reducer,
		login: login.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			// Disable serializable check since it is slow with the amount of data in Results
			serializableCheck: false,
		})
			// Add authorization middleware (redirects to login if unauthorized)
			.concat(authorizationMiddleware)
			// Add API middleware
			.concat(api.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
