import { motion, Variants } from 'framer-motion';
import { ReactNode } from 'react';

import './SlideInOverlay.scss';

// Animation styles for the content
const contentVariants: Variants = {
	open: {
		x: '0',
		opacity: 1,
	},
	closed: {
		x: '100%',
		opacity: 0.3,
	},
	initial: {
		x: '100%',
		opacity: 0.3,
	},
};

// Animation styles for the background
const backgroundVariants: Variants = {
	initial: { opacity: 0, pointerEvents: 'none' },
	closed: { opacity: 0, pointerEvents: 'none' },
	open: { opacity: 1, pointerEvents: 'all' },
};

type Props = { children: ReactNode; width: string; isOpen: boolean };

export const SlideInOverlay = ({ children, width, isOpen }: Props) => {
	return (
		<>
			{isOpen && (
				<motion.div
					key="OverlayBackground"
					className="SlideInOverlay__Background"
					variants={backgroundVariants}
					initial="initial"
					animate="open"
					exit="closed"
					transition={{
						ease: 'easeOut',
						duration: 0.2,
					}}
				></motion.div>
			)}

			{isOpen && (
				<motion.div
					key="OverlayContent"
					className="SlideInOverlay__Content"
					variants={contentVariants}
					initial="initial"
					animate="open"
					exit="closed"
					transition={{
						ease: 'easeOut',
						duration: 0.2,
					}}
					style={{ maxWidth: width }}
				>
					{children}
				</motion.div>
			)}
		</>
	);
};
