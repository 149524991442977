import { useEffect, useState } from 'react';
import { Configurator } from '../../Core/components/Configurator/Configurator';
import { ConfiguratorBody } from '../../Core/components/Configurator/ConfiguratorBody';
import { ConfiguratorHeader } from '../../Core/components/Configurator/ConfiguratorHeader';
import {
	ConfiguratorTab,
	ConfiguratorTabs,
} from '../../Core/components/Configurator/ConfiguratorTabs';
import { TrendsContent } from './TrendsContent';
import './Trends.scss';
import { useDateRange } from '../Dashboard/Dashboard.slice';
import {
	useGetTrendsQuickReferenceQuery,
	useGetTrendTemplatesQuery,
} from '../../Core/Api';
import { useParams } from 'react-router-dom';
import { DashboardQuickReference } from '../Dashboard/DashboardQuickReference';
import { useBoolean } from 'usehooks-ts';
import classNames from 'classnames';
import { useQueryParam } from '../../Core/hooks/useQueryParam';

export const Trends = () => {
	const params = useParams();
	const [selectedTab, setSelectedTab] = useState<string | null>(null);
	const dateRange = useDateRange();
	const [fullscreen, setFullscreen] = useQueryParam('fullscreen');
	const { value: quickReference, setFalse, setTrue } = useBoolean(false);

	// Get trends templates
	const { data: trendsTemplates } = useGetTrendTemplatesQuery(
		{ siteId: params.siteId! },
		{
			skip: !params.siteId,
		}
	);

	const { data } = useGetTrendsQuickReferenceQuery(
		{
			siteId: params.siteId!,
			keys: [selectedTab!],
		},
		{
			skip: !params.siteId || !selectedTab,
			refetchOnMountOrArgChange: true,
		}
	);

	useEffect(() => {
		if (selectedTab === null && trendsTemplates) {
			setSelectedTab(trendsTemplates?.templates[0].templateKey);
		}
	}, [selectedTab, trendsTemplates]);

	const isFullscreen = fullscreen === 'true';

	const handleToggleFullscreen = () => {
		setFullscreen(isFullscreen ? 'false' : 'true');
	};

	return (
		<section
			className={classNames(['Trends'], {
				'Trends--Fullscreen': isFullscreen,
				Trends__ShowQuickReference: quickReference,
			})}
		>
			{/* <SensorOverviewModel /> */}
			<Configurator>
				<ConfiguratorHeader
					title="Trends Demo"
					onCloseClick={() => {}}
					onExpandClick={handleToggleFullscreen}
					onQuickReferenceClick={setTrue}
					quickReferenceState={quickReference}
					showCloseButton={false}
				>
					{trendsTemplates && (
						<ConfiguratorTabs>
							{trendsTemplates.templates.map((tab) => (
								<ConfiguratorTab
									key={tab.templateKey}
									id={tab.templateKey}
									title={tab.title}
									onSelected={() => setSelectedTab(tab.templateKey)}
								/>
							))}
						</ConfiguratorTabs>
					)}
				</ConfiguratorHeader>
				<ConfiguratorBody>
					{selectedTab && dateRange && (
						<TrendsContent
							siteId={params.siteId!}
							templateKey={selectedTab}
							dates={dateRange}
						/>
					)}
				</ConfiguratorBody>
			</Configurator>
			<div className="Trends__QuickReference">
				<DashboardQuickReference
					closeQuickReference={setFalse}
					markdown={data?.markdown ?? ''}
				/>
			</div>
		</section>
	);
};
