import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import classNames from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { RFCDataSet } from '../../../SharedTypes/API/Dashboard';
import { createArrayWith } from '../../utils/Array';
import { ChartNoData } from './ChartNoData';
import { createChartThresholdData } from './ChartThresholdData';
import { renderToString } from 'react-dom/server';
import { Icon } from '../Icon/Icon';
import { ColorLookup } from '../../utils/ColorLookup';
import './LineChart.scss';
import { useGetMetricQuery } from '../../Api';
import { useParams } from 'react-router-dom';
import {
	useDateRange,
	useSelectedDate,
} from '../../../RIAMS/Dashboard/Dashboard.slice';
import { ChartWarning } from './ChartWarning';
import {
	getDate,
	getHour,
	getMinutes,
	getMonth,
	getPadded,
	getYear,
} from '../../utils/Dates';
import './RFCChart.scss';
import {
	getChartDownloadName,
	roundValueToNearestIncrement,
} from './ChartOptions';

type Props = {
	dataSet: RFCDataSet;
	height?: number;
};

export const RFCChart: FC<Props> = ({ dataSet, height = 100 }) => {
	const selectedDate = useSelectedDate();
	const dateRange = useDateRange();

	/** Checks if we should query data, other than the default dataSet we get via props */
	const shouldFetchData = (): boolean => {
		if (!selectedDate && !dateRange?.[1]) {
			return false;
		}

		if (selectedDate === dateRange?.[1].getTime()) {
			return false;
		}

		return true;
	};

	/** Checks if the date range is a single, 24 hours period */
	const dateRangeIsSingleDay = useCallback(() => {
		// Date range is nothing
		if (!dateRange?.[0] && !dateRange?.[1]) {
			return false;
		}

		if (dateRange[0].getDate() !== dateRange[1].getDate() - 1) {
			return false;
		}

		return true;
	}, [dateRange]);

	const params = useParams();

	const { data } = useGetMetricQuery(
		{
			siteId: params.siteId!,
			metricId: dataSet.id,
			start: selectedDate - 1000 * 60 * 60,
			stop: selectedDate,
		},
		{
			skip: !shouldFetchData() && !dateRangeIsSingleDay(),
		}
	);

	const getDataSet = useMemo(() => {
		if (
			dateRangeIsSingleDay() &&
			selectedDate !== dateRange?.[1].getTime() &&
			data &&
			data.chartType === 'rfc'
		) {
			return data;
		}

		return dataSet;
	}, [dateRangeIsSingleDay, selectedDate, dateRange, data, dataSet]);

	// Devise a function that can check what the highest value is, and then only plot in the thresholds that are needed (plus/minus a bit)
	const neededThresholds = createChartThresholdData(
		getDataSet.data,
		getDataSet.minValue,
		getDataSet.maxValue
	);

	const stepLines = createArrayWith<'smooth' | 'straight' | 'stepline'>(
		neededThresholds.length,
		getDataSet.chartType === 'rfc' ? 'smooth' : 'stepline'
	);
	const dashes = createArrayWith<number>(neededThresholds.length, 0);

	const widths = createArrayWith(neededThresholds.length, 2);
	const colors = createArrayWith(
		neededThresholds.length,
		ColorLookup.forGraph['red']
	);
	const markerColors = createArrayWith(neededThresholds.length, 'transparent');
	const fillers = createArrayWith(neededThresholds.length, 'solid');

	const dataUnits = getDataSet.units ? getDataSet.units : ' ';

	// Create the export download name
	const exportDownloadName = getChartDownloadName({
		dateRange: dateRange!,
		name: dataSet.name,
	});

	const options: ApexOptions = {
		stroke: {
			curve: [...stepLines, 'straight'],
			width: [...widths, 4],
			dashArray: [...dashes, 0],
		},
		xaxis: {
			type: 'numeric',
			axisTicks: {
				show: false,
			},
			tooltip: {
				enabled: false,
			},
			labels: {
				show: true,
				formatter: (value) => {
					return `Δ${roundValueToNearestIncrement(parseFloat(value)).toFixed(
						2
					)} mm`;
				},
				style: {
					colors: 'rgba(255,255,255,0.7)',
					cssClass: 'LineChart__YAxis',
				},
			},
			axisBorder: {
				show: false,
			},
			crosshairs: {
				show: false,
			},
		},
		legend: {
			show: false,
		},
		yaxis: {
			forceNiceScale: true,
			show: true,
			tickAmount: 4,
			logarithmic: true,
			logBase: 1000,
			labels: {
				show: true,
				formatter: formatYAxisValue(dataUnits),
				padding: 5,
				style: {
					colors: 'rgba(255,255,255,0.7)',
					cssClass: 'LineChart__YAxis',
				},
			},
			min: getDataSet.minValue,
		},
		colors: [...colors, ColorLookup.forGraph['blue']],
		chart: {
			background: '#003347',
			width: '100%',
			zoom: {
				enabled: true,
				type: 'x',
			},
			toolbar: {
				show: true,
				tools: {
					pan: false,
					zoom: renderToString(<Icon name="ChartSearch" width={16} />),
					download: renderToString(<Icon name="ChartBurgerMenu" width={16} />),
					zoomin: renderToString(<Icon name="ChartIn" width={16} />),
					zoomout: renderToString(<Icon name="ChartOut" width={16} />),
					reset: renderToString(<Icon name="Reset" width={18} />),
				},
				offsetX: 0,
				offsetY: -25,
				export: {
					png: {
						filename: exportDownloadName,
					},
					svg: {
						filename: exportDownloadName,
					},
					csv: {
						filename: exportDownloadName,
					},
				},
			},
			events: {
				beforeZoom: (_event, { xaxis }) => {
					// Ensures we do not zoom out further than the present datapoints
					const first = getDataSet.data[0].x;
					const last = getDataSet.data.at(-1)?.x;

					const mainDifference = (last ?? 0) - first!;
					const zoomDifference = xaxis.max - xaxis.min;

					if (zoomDifference > mainDifference)
						return {
							// dont zoom out any further
							xaxis: {
								min: first,
								max: last,
							},
						};
					else {
						return {
							// keep on zooming
							xaxis: {
								min: xaxis.min,
								max: xaxis.max,
							},
						};
					}
				},
			},
			// Sparkline removes anything but the chart itself
			sparkline: {
				enabled: false,
			},
			animations: {
				enabled: true,
				speed: 150,
				easing: 'easeout',
				animateGradually: {
					enabled: false,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 150,
				},
			},
		},
		grid: {
			show: true,
			yaxis: {
				lines: {
					show: true,
				},
			},
			xaxis: {
				lines: {
					show: true,
				},
			},
			borderColor: 'rgba(255,255,255,0.2)',
			padding: {
				left: -5,
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0,
			hover: {
				size: 4,
			},
			colors: [...markerColors, '#fff'],
			fillOpacity: 1,
			strokeWidth: 0,
			showNullDataPoints: false,
		},
		fill: {
			type: [...fillers, 'gradient'],
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.5,
				opacityTo: 0,
				stops: [0, 100],
				type: 'vertical',
			},
			colors: [...markerColors, '#009de0'],
		},
		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				// All series have the same x axis values, we can grab them from whereever
				const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];

				// We know the last series is the actual data, and not the thresholds
				const value: number = series[series.length - 1][dataPointIndex];

				if (!value) {
					return null;
				}

				return `
						<div class="LineChart__Label" data-testid="LineChartTooltip">
							<span>Δ${parseFloat(timestamp).toFixed(2)} mm - ${value} ${
					getDataSet.units
				}</span>
						</div>
					`;
			},
			followCursor: true,
		},
	};

	return (
		<div className={classNames(['LineChart', 'LineChart--Analytical'])}>
			{getDataSet.data.length === 0 && <ChartNoData />}
			{getDataSet.data.length > 0 && (
				<>
					<Chart
						data-testid="lineChart"
						type="area"
						series={[...neededThresholds, { ...getDataSet, type: 'area' }]}
						height={height}
						width="100%"
						options={options}
					/>
					{!dateRangeIsSingleDay() &&
						dateRange?.[1] &&
						selectedDate !== dateRange?.[1].getTime() && (
							<ChartWarning
								timestamp={`${getPadded(getDate(dateRange[1]))}/${getPadded(
									getMonth(dateRange[1])
								)}/${getYear(dateRange[1])} ${getPadded(
									getHour(dateRange[1])
								)}:${getPadded(getMinutes(dateRange[1]))}`}
							/>
						)}
				</>
			)}
		</div>
	);
};

/** Formats the value of yaxis to be more easily readable */
const formatYAxisValue = (units: string) => (value: number) => {
	const formattedValue = Intl.NumberFormat('en-GB', {
		notation: 'compact',
	}).format(value);

	return `${formattedValue} ${units}`;
};
