import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { useConfirmForgotPasswordCodeMutation } from '../Core/Api';
import { Button } from '../Core/components/Button/Button';
import { FormInput } from '../Core/components/FormInput/FormInput';
import { useQueryParam } from '../Core/hooks/useQueryParam';
import { passwordRules } from './Config';

export const ForgotPasswordConfirmCode = () => (
	<>
		<h1 className="Login__Title">Reset password</h1>
		<ForgotPasswordConfirmCodeForm />
	</>
);

const schema = z
	.object({
		code: z.string(),
		newPassword: z
			.string()
			.refine(
				passwordRules.containsLowercaseLetter,
				'Must contain at least 1 lower-case letter'
			)
			.refine(
				passwordRules.containsUppercaseLetter,
				'Must contain at least 1 upper-case letter'
			)
			.refine(passwordRules.containsNumber, 'Must contain at least 1 number')
			.refine(
				passwordRules.containsSpecialCharacter,
				'Must contain at least 1 special character'
			)
			.refine(
				passwordRules.minimumEightCharacters,
				'Must be at least 8 characters'
			),
		newPassword2: z.string(),
	})
	.refine((data) => data.newPassword === data.newPassword2, {
		message: 'Please repeat the same password',
		path: ['newPassword2'], // path of error
	});
type Schema = z.infer<typeof schema>;

export const ForgotPasswordConfirmCodeForm = () => {
	const navigate = useNavigate();
	const [emailParam] = useQueryParam('email');
	const [confirmCode] = useConfirmForgotPasswordCodeMutation();

	// Setup React Hook Form handler
	const { control, handleSubmit, watch } = useForm<Schema>({
		resolver: zodResolver(schema),
		mode: 'onBlur',
		shouldFocusError: true,
		defaultValues: {
			code: '',
			newPassword: '',
			newPassword2: '',
		},
	});

	// Handle the submit call
	const onSubmit = handleSubmit(({ code, newPassword }) => {
		if (!emailParam) {
			console.error('No email passed, invalid operation');
			return;
		}

		confirmCode({ code, email: emailParam, newPassword })
			.then(() => {
				navigate('/login');
			})
			.catch(() => {
				toast('An error occurred');
			});
	});

	const formChange = watch();

	return (
		<div className="LoginForm">
			<div className="Login__Instruction" style={{ paddingBottom: '30px' }}>
				We have sent a verification code to your email.
			</div>

			<form className="LoginForm__Form" onSubmit={onSubmit}>
				<div className="LoginForm__Input">
					<FormInput
						theme="Login"
						control={control}
						name="code"
						label="Verification code"
					/>
					<FormInput
						theme="Login"
						control={control}
						name="newPassword"
						label="New Password"
						type="password"
						style={{
							display: formChange.code.length === 6 ? 'block' : 'none',
						}}
					/>
					<FormInput
						theme="Login"
						control={control}
						name="newPassword2"
						label="Confirm New Password"
						type="password"
						style={{
							display: formChange.code.length === 6 ? 'block' : 'none',
						}}
					/>
				</div>

				<div className="LoginForm__Actions">
					<div className="LoginForm__Submit">
						<Button type="submit" theme="Blue" height="Large">
							Confirm code
						</Button>
					</div>
					<div className="LoginForm__ForgotPassword">
						<Button
							type="button"
							theme="Grey"
							onClick={() => navigate('/login')}
						>
							Back to login
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};
