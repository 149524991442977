import { useNavigate } from 'react-router-dom';
import { Icon } from '../Icon/Icon';
import './BackButton.scss';

export const BackButton = () => {
	const navigate = useNavigate();

	return (
		<div className="BackButton" onClick={() => navigate(-1)}>
			<Icon name="CircleArrowLeft" width={24} />
			<p className="BackButton__Label">Back</p>
		</div>
	);
};
