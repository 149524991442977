import { ReactNode } from 'react';
import './SelectedDivider.scss';

export const SelectedDivider = ({
	width,
	children,
}: {
	width: string;
	children: ReactNode;
}) => {
	return (
		<div className="SelectedDivider" style={{ width }}>
			{children}
		</div>
	);
};

export const SelectedDividerButton = ({
	children,
	onClick,
}: {
	children: ReactNode;
	onClick?: () => void;
}) => {
	return (
		<div className="SelectedDividerButton" onClick={onClick}>
			{children}
		</div>
	);
};
