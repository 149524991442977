import classNames from 'classnames';
import { FC } from 'react';
import { Icon } from '../../Core/components/Icon/Icon';
import { RealtimeIndicator } from '../../Core/components/RealtimeIndicator/RealtimeIndicator';
import './DashboardHeader.scss';

type DashboardHeaderProps = {
	toggleFullscreen: () => void;
	openQuickReference: () => void;
	quickReferenceOpen: boolean;
	siteId: string;
};

export const DashboardHeader: FC<DashboardHeaderProps> = ({
	toggleFullscreen,
	openQuickReference,
	quickReferenceOpen,
	siteId,
}) => (
	<div className="DashboardHeader">
		<h1 className="DashboardHeader__Title">Integrity dashboard</h1>
		<div className="DashboardHeader__Realtime">
			<RealtimeIndicator siteId={siteId} pulsating={true} />
		</div>
		<div className="DashboardHeader__Actions">
			<div
				className={classNames(['DashboardHeader__QuickReference'], {
					'DashboardHeader__QuickReference--Open': quickReferenceOpen,
				})}
				onClick={openQuickReference}
			>
				<Icon name="Help" width={24} />
			</div>
			<div className="DashboardHeader__Fullscreen" onClick={toggleFullscreen}>
				<Icon name="CircleExpand" width={24} />
			</div>
		</div>
	</div>
);
