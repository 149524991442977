import { AnimatePresence, motion } from 'framer-motion';
import {
	ReactNode,
	useEffect,
	useRef,
	useState,
	CSSProperties,
	forwardRef,
	useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { useOnClickOutside } from 'usehooks-ts';
import classNames from 'classnames';

import './PopMenu.scss';
import { stopPropagation } from '../../utils/stopPropagation';
import { noop } from '../../utils/Function';
import { FormTheme } from '../../types/FormTheme';

export type PopMenuState = 'closed' | 'open';
export type PopMenuItems = PopMenuItem[];
export type PopMenuTheme = 'Dark' | FormTheme;

interface PopMenuItem {
	key: string;
	label: string;
	title?: string;
	icon?: ReactNode;
	style?: CSSProperties;
	isSelected?: boolean;
}

type Props = {
	items: PopMenuItems;
	placement: Placement;
	className?: string;
	style?: CSSProperties;
	isEnabled?: boolean;
	children: ReactNode;
	elementRef: React.RefObject<HTMLDivElement>;
	onChangeState?: (state: PopMenuState) => void;
	onSelectItem?: (key: string) => void;
	theme?: PopMenuTheme;
	onMouseEnterItem?: (key: string, index: number) => void;
	onMouseLeaveItem?: (key: string, index: number) => void;
};
export const PopMenu = ({
	items,
	children,
	elementRef,
	isEnabled = true,
	className = '',
	style = {},
	placement,
	onChangeState = noop,
	onSelectItem = noop,
	theme = 'Default',
	onMouseEnterItem = noop,
	onMouseLeaveItem = noop,
}: Props) => {
	const [state, setState] = useState<PopMenuState>('closed');

	const PopMenuRef = useRef<HTMLDivElement>(null);

	// Set up outside click handler
	useOnClickOutside(PopMenuRef, () => {
		close();
	});

	// Publish when the menu opens and closes
	useEffect(() => {
		onChangeState(state);
	}, [onChangeState, state]);

	const isOpen = state === 'open';

	const { styles, attributes } = usePopper(
		elementRef.current,
		PopMenuRef.current,
		{
			placement,
		}
	);

	function handleSelectItem(key: string) {
		onSelectItem(key);
		close();
	}

	function open() {
		if (!isOpen) {
			setState('open');
		}
	}

	function close() {
		if (isOpen) {
			setState('closed');
		}
	}

	function handleClick() {
		isOpen ? close() : open();
	}

	const popper = isEnabled ? (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className="PopMenuWrapper"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{
						opacity: 0,
						transition: { type: 'spring', duration: 0.3, bounce: 0 },
					}}
					transition={{
						type: 'spring',
						delay: 0.01,
						bounce: 0,
						duration: 0.2,
					}}
				>
					<PopMenuContent
						items={items}
						onSelectItem={handleSelectItem}
						ref={PopMenuRef}
						style={styles.popper}
						theme={theme}
						onMouseEnterItem={onMouseEnterItem}
						onMouseLeaveItem={onMouseLeaveItem}
						{...attributes.popper}
					/>
				</motion.div>
			)}
		</AnimatePresence>
	) : null;

	const element = document.querySelector('#popperRoot');

	return (
		<>
			<div
				className={className}
				style={style}
				onClick={stopPropagation(handleClick)}
			>
				{children}
			</div>
			{element && createPortal(popper, element)}
		</>
	);
};

export type PopMenuContentProps = {
	style?: CSSProperties;
	theme?: PopMenuTheme;
	items: PopMenuItems;
	onSelectItem?: (key: string) => void;
	onMouseEnterItem?: (key: string, index: number) => void;
	onMouseLeaveItem?: (key: string, index: number) => void;
};

export const PopMenuContent = forwardRef<HTMLDivElement, PopMenuContentProps>(
	(
		{
			items,
			style = {},
			theme = 'Default',
			onSelectItem = noop,
			onMouseEnterItem = noop,
			onMouseLeaveItem = noop,
			...props
		},
		ref
	) => {
		// Determine if this is an icon variant or not
		const hasIcon = useMemo(() => items.some(({ icon }) => !!icon), [items]);

		return (
			<div
				className={classNames('PopMenu', `PopMenu--Theme-${theme}`, {
					hasIcon,
				})}
				style={style}
				ref={ref}
				{...props}
			>
				{items.map(
					(
						{ key, label, icon, style = {}, isSelected = false, title },
						index
					) => (
						<div
							className={classNames('PopMenu__Item', {
								hasIcon: !!icon,
								isSelected,
							})}
							style={style}
							key={key}
							{...(title !== undefined ? { title } : {})}
							onMouseLeave={() => onMouseLeaveItem(key, index)}
							onMouseEnter={() => onMouseEnterItem(key, index)}
							onClick={stopPropagation(() => onSelectItem(key))}
						>
							{icon && <div className="PopMenu__ItemIcon">{icon}</div>}

							<div className="PopMenu__ItemLabel">{label}</div>
						</div>
					)
				)}
			</div>
		);
	}
);
