import classNames from 'classnames';
import { FC, ReactElement, ReactNode, useState } from 'react';

import './ConfiguratorTabs.scss';

type ConfiguratorTabsProps = {
	children: ReactElement<ConfiguratorTabProps>[];
};

export const ConfiguratorTabs = ({ children }: ConfiguratorTabsProps) => {
	// Select the first tab on init
	const [selectedId, setSelectedId] = useState<string | null>(
		children?.[0].props.id ?? null
	);

	const selectedTab =
		children.find((child) => child.props.id === selectedId)?.props?.children ??
		null;

	const handleClick = (child: ReactElement<ConfiguratorTabProps>) => () => {
		setSelectedId(child.props.id);
		child.props.onSelected?.();
	};

	return (
		<div className="ConfiguratorTabs">
			<div className="ConfiguratorTabs__Tabs">
				{children.map((child) => (
					<div
						className={classNames('ConfiguratorTabs__Tab', {
							isSelected: selectedId === child.props.id,
						})}
						key={child.props.id}
						onClick={handleClick(child)}
					>
						{child.props.title}
					</div>
				))}
			</div>
			<div className="ConfiguratorTabs__TabContent">{selectedTab}</div>
		</div>
	);
};

type ConfiguratorTabProps = {
	id: string;
	title: string;
	children?: ReactNode;
	onSelected?: () => void;
};
export const ConfiguratorTab: FC<ConfiguratorTabProps> = () => {
	return <></>;
};
