import React, { useMemo, useState } from 'react';

import './AnalysisExplorer.scss';
import { Configurator } from '../../Core/components/Configurator/Configurator';
import { ConfiguratorHeader } from '../../Core/components/Configurator/ConfiguratorHeader';
import { ConfiguratorBody } from '../../Core/components/Configurator/ConfiguratorBody';
import {
	ConfiguratorTab,
	ConfiguratorTabs,
} from '../../Core/components/Configurator/ConfiguratorTabs';
import {
	ComponentId,
	DimensionConfiguration,
	SectionResults,
} from '../../SharedTypes/API/Explorer';
import { SectionResultsTable } from './SectionResultsTable';
import {
	ComponentSelectionId,
	deserializeComponentSelectionId,
	serializeComponentSelectionId,
} from '../Explorer/Explorer.slice';
import { never } from '../../Core/utils/Function';
import { useAppSelector } from '../../Core/redux/useAppSelector';
import { selectHighlightedComponent } from '../Explorer/Explorer.selectors';
import { debounce } from 'lodash';

const initialMaxComponents = 30;
const loadMoreComponents = 20;
const highlightDelay = 300;

type Props = {
	elementDimensionConfig: DimensionConfiguration;
	jointDimensionConfig: DimensionConfiguration;
	sectionResult: SectionResults | null;
	selectedComponents: ComponentSelectionId[];
	fetchState: 'success' | 'loading' | 'error' | 'fetching';
	onOpenDimensionChooser: (args: { type: 'element' | 'joint' }) => void;
	onClose: () => void;
	onRemoveElementDimension: (dimensionId: string) => void;
	onRemoveJointDimension: (dimensionId: string) => void;
	onSortElementDimension: (args: {
		dimensionId: string;
		direction: 'desc' | 'asc';
	}) => void;
	onSortJointDimension: (args: {
		dimensionId: string;
		direction: 'desc' | 'asc';
	}) => void;
	onToggleElementFoldOut: (args: {
		componentId: string;
		newState: 'open' | 'closed';
	}) => void;
	onToggleJointFoldOut: (args: {
		componentId: string;
		newState: 'open' | 'closed';
	}) => void;
	onToggleSize: () => void;
	onToggleComponentSelection: (
		componentSelectionId: ComponentSelectionId
	) => void;
	onStartComponentHighlight: (
		componentSelectionId: ComponentSelectionId
	) => void;
	onStopComponentHighlight: (
		componentSelectionId: ComponentSelectionId
	) => void;
	onClearSelection?: () => void;
};

export const AnalysisExplorer = ({
	elementDimensionConfig,
	jointDimensionConfig,
	sectionResult,
	selectedComponents,
	fetchState,
	onOpenDimensionChooser,
	onClose,
	onSortElementDimension,
	onSortJointDimension,
	onToggleElementFoldOut,
	onToggleJointFoldOut,
	onToggleSize,
	onRemoveElementDimension,
	onRemoveJointDimension,
	onToggleComponentSelection,
	onStartComponentHighlight,
	onStopComponentHighlight,
	onClearSelection,
}: Props) => {
	const [selectedTab, setSelectedTab] = useState<'elements' | 'joints'>(
		'elements'
	);

	const [maxComponents, setMaxComponents] =
		useState<number>(initialMaxComponents);

	// Split the ComponentSelectionIds array into into separate arrays of ComponentId
	const [selectedElements, selectedJoints] = useMemo(
		() =>
			selectedComponents
				.reduce<
					[selectedElements: Set<ComponentId>, selectedJoints: Set<ComponentId>]
				>(
					([selectedElements, selectedJoints], componentSelectionId) => {
						const { componentId, componentType } =
							deserializeComponentSelectionId(componentSelectionId);

						// We use concat since it may be more performant than destructuring
						switch (componentType) {
							case 'element':
								return [selectedElements.add(componentId), selectedJoints];
							case 'joint':
								return [selectedElements, selectedJoints.add(componentId)];
						}

						// This is a weird case where typings are fighting against the TS compiler
						// I leave this here to make the types more safe
						// Unfortunately ts-ignore doesn't help against the 'unreachable code' error.
						return never(componentType);
					},
					[new Set<ComponentId>(), new Set<ComponentId>()]
				)
				// Convert the Set's to arrays
				.map((group) => Array.from(group)),
		[selectedComponents]
	);

	function handleLoadMore() {
		setMaxComponents((v) => v + loadMoreComponents);
	}

	const handleSelection =
		(componentType: 'element' | 'joint') => (componentId: ComponentId) => {
			onToggleComponentSelection(
				serializeComponentSelectionId({ componentId, componentType })
			);
		};

	const handleHighlight =
		(componentType: 'element' | 'joint') => (action: 'start' | 'stop') =>
			debounce((componentId: ComponentId) => {
				const componentSelectionId = serializeComponentSelectionId({
					componentId,
					componentType,
				});

				switch (action) {
					case 'start':
						onStartComponentHighlight(componentSelectionId);
						return;
					case 'stop':
						onStopComponentHighlight(componentSelectionId);
						return;
				}
			}, highlightDelay);

	// Get the highlighted component as separate results for joints and elements
	// since these live in separate table components
	const highlightedComponentSelectionId = useAppSelector(
		selectHighlightedComponent
	);

	const { highlightedElement, highlightedJoint } = useMemo(() => {
		const emptyResult = { highlightedJoint: null, highlightedElement: null };

		if (highlightedComponentSelectionId === null) {
			return emptyResult;
		}

		const { componentId, componentType } = deserializeComponentSelectionId(
			highlightedComponentSelectionId
		);

		switch (componentType) {
			case 'joint':
				return { ...emptyResult, highlightedJoint: componentId };
			case 'element':
				return { ...emptyResult, highlightedElement: componentId };
		}
	}, [highlightedComponentSelectionId]);

	return (
		<div className="AnalysisExplorer">
			<Configurator>
				<ConfiguratorHeader
					title="Analysis Explorer"
					onCloseClick={onClose}
					onExpandClick={onToggleSize}
				>
					<ConfiguratorTabs>
						<ConfiguratorTab
							title="Elements"
							id="elements"
							onSelected={() => setSelectedTab('elements')}
						/>
						<ConfiguratorTab
							title="Joints"
							id="joints"
							onSelected={() => setSelectedTab('joints')}
						/>
					</ConfiguratorTabs>
				</ConfiguratorHeader>
				<ConfiguratorBody>
					<div className="AnalysisExplorer__ResultsTable">
						{selectedTab === 'elements' && sectionResult && (
							<SectionResultsTable
								dimensionConfiguration={elementDimensionConfig}
								maxComponents={maxComponents}
								isLoading={fetchState === 'loading'}
								sectionResult={sectionResult?.elements}
								selectedComponents={selectedElements}
								highlightedComponent={highlightedElement}
								type={'Element'}
								onChooserClick={() =>
									onOpenDimensionChooser({ type: 'element' })
								}
								onSortDimension={onSortElementDimension}
								onToggleSubComponent={onToggleElementFoldOut}
								onRemoveDimension={onRemoveElementDimension}
								onLoadMore={handleLoadMore}
								onClearSelection={onClearSelection}
								onComponentSelection={handleSelection('element')}
								onComponentStartHighlight={handleHighlight('element')('start')}
								onComponentStopHighlight={handleHighlight('element')('stop')}
							/>
						)}
						{selectedTab === 'joints' && sectionResult && (
							<SectionResultsTable
								dimensionConfiguration={jointDimensionConfig}
								maxComponents={maxComponents}
								isLoading={fetchState === 'loading'}
								sectionResult={sectionResult?.joints}
								selectedComponents={selectedJoints}
								highlightedComponent={highlightedJoint}
								type={'Joint'}
								onChooserClick={() => onOpenDimensionChooser({ type: 'joint' })}
								onSortDimension={onSortJointDimension}
								onToggleSubComponent={onToggleJointFoldOut}
								onRemoveDimension={onRemoveJointDimension}
								onLoadMore={handleLoadMore}
								onClearSelection={onClearSelection}
								onComponentSelection={handleSelection('joint')}
								onComponentStartHighlight={handleHighlight('joint')('start')}
								onComponentStopHighlight={handleHighlight('joint')('stop')}
							/>
						)}
					</div>
				</ConfiguratorBody>
			</Configurator>
		</div>
	);
};
