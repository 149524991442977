import { Icon } from '../../Core/components/Icon/Icon';
import { MarkdownRenderer } from '../../Core/components/MarkdownRenderer/MarkdownRenderer';
import './DashboardQuickReference.scss';

export const DashboardQuickReference = ({
	closeQuickReference,
	markdown,
}: {
	closeQuickReference: () => void;
	markdown: string;
}) => {
	return (
		<div className="DashboardQuickReference">
			<div className="DashboardQuickReference__Header">
				<h1 className="DashboardQuickReference__Title">Quick reference</h1>
				<div
					className="DashboardQuickReference__Close"
					onClick={closeQuickReference}
				>
					<Icon name="CircleArrowLeft" width={24} />
				</div>
			</div>
			<div className="DashboardQuickReference__Body">
				<MarkdownRenderer markdown={markdown} />
			</div>
		</div>
	);
};
