// blend two hex colors together by an amount
export const blendColors = (colorA: string, colorB: string, amount: number) => {
	const [safeColorA, safeColorB] = [colorA, colorB].map((color) => {
		if (color.length === 7) {
			return color;
		} else if (color.length === 4) {
			return `${color[0]}${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}}`;
		}

		return '#fff';
	}) as [string, string];

	const [rA, gA, bA] = safeColorA
		.match(/\w\w/g)
		?.map((c) => parseInt(c, 16)) ?? [0, 0, 0];
	const [rB, gB, bB] = safeColorB
		.match(/\w\w/g)
		?.map((c) => parseInt(c, 16)) ?? [0, 0, 0];

	const r = Math.round(rA + (rB - rA) * amount)
		.toString(16)
		.padStart(2, '0');
	const g = Math.round(gA + (gB - gA) * amount)
		.toString(16)
		.padStart(2, '0');
	const b = Math.round(bA + (bB - bA) * amount)
		.toString(16)
		.padStart(2, '0');
	return '#' + r + g + b;
};
