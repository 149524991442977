import { Placement } from '@popperjs/core';
import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { noop } from '../../utils/Function';
import { Icon } from '../Icon/Icon';
import { PopMenu, PopMenuItems, PopMenuState } from '../PopMenu/PopMenu';
import { TitleBar, TitleBarTheme } from '../TitleBar/TitleBar';

export type TitleBarMenuProps = {
	items: PopMenuItems;
	theme?: TitleBarTheme;
	placement?: Placement;
	onSelectItem?: (key: string) => void;
	children: ReactNode;
};

export const TitleBarMenu: FC<TitleBarMenuProps> = ({
	children,
	theme,
	items,
	placement = 'bottom-start',
	onSelectItem = noop,
}) => {
	const elementRef = useRef<HTMLDivElement>(null);

	const [isTitleBarHovered, setIsTitleBarHovered] = useState(false);
	const [isPopMenuOpen, setIsPopMenuOpen] = useState(false);

	const onPopMenuChangeState = (state: PopMenuState) => {
		setIsPopMenuOpen(state === 'open');
	};

	const isActive = useMemo(() => {
		return isTitleBarHovered || isPopMenuOpen;
	}, [isPopMenuOpen, isTitleBarHovered]);

	return (
		<div>
			<PopMenu
				items={items}
				placement={placement}
				elementRef={elementRef}
				theme="Default"
				onChangeState={onPopMenuChangeState}
				onSelectItem={onSelectItem}
			>
				<TitleBar
					ref={elementRef}
					theme={theme}
					isActive={isActive}
					style={{ cursor: 'pointer' }}
					icon={<Icon name="Burger" width={14} />}
					onMouseEnter={() => setIsTitleBarHovered(true)}
					onMouseLeave={() => setIsTitleBarHovered(false)}
				>
					{children}
				</TitleBar>
			</PopMenu>
		</div>
	);
};
