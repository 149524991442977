import { useCallbackRef } from './useCallbackRef';
import { useState, useEffect, RefObject } from 'react';

export const useMeasure = (ref: RefObject<any>) => {
	const [element, attachRef] = useCallbackRef();
	const [bounds, setBounds] = useState<{ height: number }>({ height: 0 });

	useEffect(() => {
		const onResize: ResizeObserverCallback = ([entry]) => {
			setBounds({
				height: entry.contentRect.height,
			});
		};

		const observer = new ResizeObserver(onResize);

		if (element && element.current) {
			observer.observe(element.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [element]);

	useEffect(() => {
		attachRef(ref);
	}, [attachRef, ref]);

	return bounds;
};
