import classNames from 'classnames';
import { useState } from 'react';
import { Button } from '../../Core/components/Button/Button';
import { FoldOutList } from '../../Core/components/FoldOutList/FoldOutList';
import { Icon } from '../../Core/components/Icon/Icon';
import { IconButton } from '../../Core/components/IconButton/IconButton';
import { Modal } from '../../Core/components/Modal/Modal';
import {
	Dimension,
	DimensionCategory,
	DimensionConfigurationDimension,
} from '../../SharedTypes/API/Explorer';

import './DimensionChooser.scss';

type Props = {
	type: 'elements' | 'joints';
	onCancel: () => void;
	onSave: (dimensions: DimensionConfigurationDimension[]) => void;
	dimensionCategories: DimensionCategory[];
	chosenDimensions: DimensionConfigurationDimension[];
	state: 'loading' | 'success' | 'error';
};

export const DimensionChooser = ({
	type,
	onCancel,
	onSave,
	state,
	chosenDimensions: chosenDimensionsDefault,
	dimensionCategories,
}: Props) => {
	const [chosenDimensions, setChosenDimensions] = useState([
		...chosenDimensionsDefault,
	]);

	function handleAdd(dimension: DimensionConfigurationDimension) {
		// Remove any duplicates
		const cleanDimensions = removeDimension(dimension, chosenDimensions);

		// Emit with the dimension appended
		setChosenDimensions([...cleanDimensions, dimension]);
	}

	function handleRemove(dimension: DimensionConfigurationDimension) {
		// Remove any duplicates
		const cleanDimensions = removeDimension(dimension, chosenDimensions);

		// Emit the cleaned array
		setChosenDimensions(cleanDimensions);
	}

	function handleSave() {
		onSave(chosenDimensions);
	}

	const title = `<strong>Add dimensions</strong> for ${type}`;

	return (
		<Modal
			title={title}
			footerContent={<FooterContent onCancel={onCancel} onSave={handleSave} />}
		>
			<div className="DimensionChooser">
				{dimensionCategories.map((category) => (
					<DimensionChooserCategory
						chosenDimensions={chosenDimensions}
						category={category}
						key={category.name}
						onAdd={handleAdd}
						onRemove={handleRemove}
					/>
				))}
			</div>
		</Modal>
	);
};

const DimensionChooserCategory = (props: {
	category: DimensionCategory;
	chosenDimensions: DimensionConfigurationDimension[];
	onAdd: (dimension: DimensionConfigurationDimension) => void;
	onRemove: (dimension: DimensionConfigurationDimension) => void;
}) => {
	const [isVisible, setIsVisible] = useState(props.chosenDimensions.length > 0);

	function handleAdd(dimension: Dimension) {
		props.onAdd(mapDimensionToDimConfigDimension(dimension));
	}

	function handleRemove(dimension: Dimension) {
		props.onRemove(mapDimensionToDimConfigDimension(dimension));
	}

	function isDimensionSelected(dimension: DimensionConfigurationDimension) {
		return (
			props.chosenDimensions.find(
				(item) => item.id === dimension.id && item.type === dimension.type
			) !== undefined
		);
	}

	return (
		<FoldOutList
			isVisible={isVisible}
			labelComponent={
				<div
					className="DimensionChooser__CategoryName"
					onClick={() => setIsVisible((s) => !s)}
				>
					{props.category.name}
					<Icon
						className={classNames(['FoldOutList__Icon'], {
							'FoldOutList__Icon--Active': isVisible,
						})}
						name="CaretDown"
						width={12}
					/>
				</div>
			}
		>
			<div className="DimensionChooser__CategoryDimensions">
				{props.category.dimensions.map((dimension) => (
					<DimensionLine
						key={dimension.id}
						dimension={dimension}
						isSelected={isDimensionSelected(dimension)}
						onAdd={() => handleAdd(dimension)}
						onRemove={() => handleRemove(dimension)}
					/>
				))}
			</div>
		</FoldOutList>
	);
};

const DimensionLine = (props: {
	dimension: Dimension;
	isSelected: boolean;
	onAdd: () => void;
	onRemove: () => void;
}) => {
	function handleClick() {
		props.isSelected ? props.onRemove() : props.onAdd();
	}

	const addButton = (
		<div className="DimensionChooser__DimensionAdd">
			<IconButton
				alignText={'left'}
				iconSize={18}
				theme={'none'}
				icon="CirclePlus"
			>
				Add
			</IconButton>
		</div>
	);

	const removeButton = (
		<div className="DimensionChooser__DimensionRemove">
			<IconButton
				alignText={'left'}
				iconSize={18}
				theme={'none'}
				icon="CircleMinusSlim"
			>
				Remove
			</IconButton>
		</div>
	);

	return (
		<div
			className={classNames('DimensionChooser__Dimension', {
				isSelected: props.isSelected,
			})}
			title={props.dimension.name}
			onClick={handleClick}
		>
			<div className="DimensionChooser__DimensionName">
				{props.dimension.name}
			</div>
			{props.isSelected ? removeButton : addButton}
		</div>
	);
};

const FooterContent = (props: { onCancel: () => void; onSave: () => void }) => {
	return (
		<>
			<Button onClick={props.onCancel} theme="OutlineBlue">
				Cancel
			</Button>
			<Button onClick={props.onSave} theme="Blue">
				Save
			</Button>
		</>
	);
};

type MapDimensionToDimConfigDimension = (
	v: Dimension
) => DimensionConfigurationDimension;
const mapDimensionToDimConfigDimension: MapDimensionToDimConfigDimension = (
	v
) => {
	return { id: v.id, type: v.type };
};

function removeDimension(
	dimension: DimensionConfigurationDimension,
	dimensions: DimensionConfigurationDimension[]
) {
	return dimensions.filter(
		(item) => !(item.id === dimension.id && item.type === dimension.type)
	);
}
