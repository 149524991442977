import React from 'react';
import { ReactNode } from 'react';
import { InstancedMesh, Matrix4, Object3D, Quaternion, Vector3 } from 'three';
import * as N from '../../../Core/utils/Number';
import { ModelJointSubResult } from '../models/ModelJointSubResult.entity';
import { SelectionMesh } from './SelectionMesh';

const diameter = 1.25;
const opacity = 0.9;

const matrixHelper = new Object3D();

interface Props {
	jointSubResults: ModelJointSubResult[];
	segments?: number;
	isHighlight?: boolean;
}

export const JointSubResultMesh = ({
	jointSubResults,
	segments = 8,
	instanceRef = React.createRef<InstancedMesh>(),
	isHighlight = false,
}: Props & { instanceRef?: React.RefObject<InstancedMesh> }) => {
	const isOpenEnded = !isHighlight;

	const InstancedGeometry = ({ children }: { children: ReactNode }) => (
		<cylinderBufferGeometry
			args={[diameter / 2, diameter / 2, 1, segments, 1, isOpenEnded]}
		>
			{children}
		</cylinderBufferGeometry>
	);

	// The function that maps the element to the 3d space
	const placementFn = (element: ModelJointSubResult) => {
		const [, , , from, to] = element;

		// The as number[] here are only included as TS seems to not be able to correctly
		// infer the ...coordinates: number[][] in ModelElement
		const vectorFrom = new Vector3(...(from as number[]));
		const vectorTo = new Vector3(...(to as number[]));
		const totalLength = new Vector3().subVectors(vectorTo, vectorFrom).length();
		const displayLength = N.clamp(0.01)(1)(totalLength / 3);
		const direction = new Vector3()
			.subVectors(vectorTo, vectorFrom)
			.normalize();

		const tempObject2 = matrixHelper.clone();

		tempObject2.applyMatrix4(
			new Matrix4()
				.multiply(
					new Matrix4().makeTranslation(...(from as [number, number, number]))
				)
				.multiply(
					new Matrix4().makeRotationFromQuaternion(
						new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), direction)
					)
				)
				.multiply(new Matrix4().makeScale(diameter, displayLength, diameter))
				.multiply(new Matrix4().makeTranslation(0, 0.5 - 0.001, 0)) // Push by a small amount to avoid z fighting the underlying pipe element
		);

		tempObject2.updateMatrix();

		return tempObject2.matrix;
	};

	return (
		<SelectionMesh
			category="brace"
			components={jointSubResults}
			InstancedGeometry={InstancedGeometry}
			placementFn={placementFn}
			instanceRef={instanceRef}
			sides="double"
			opacity={isHighlight ? 0 : opacity}
			shadows={!isHighlight}
		/>
	);
};
