import { ChangeEventHandler, FC, useRef } from 'react';
import { Icon } from '../Icon/Icon';
import './Search.scss';

type Props = {
	value: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
	clearInput: () => void;
};

export const Search: FC<Props> = ({ value, onChange, clearInput }) => {
	const ref = useRef<HTMLInputElement>(null);

	const handleClearInput = () => {
		if (ref && ref.current) {
			clearInput();
			// Refocus the input field
			ref.current.focus();
		}
	};

	return (
		<div className="Search">
			<input
				placeholder="Search..."
				value={value}
				onChange={onChange}
				ref={ref}
			/>
			{value === '' && (
				<div className="Search__Icon" onClick={handleClearInput}>
					<Icon name={'Search'} width={24} />
				</div>
			)}
			{value !== '' && (
				<div
					className="Search__Icon"
					style={{ cursor: 'pointer' }}
					onClick={handleClearInput}
				>
					<Icon name={'X'} width={24} />
				</div>
			)}
		</div>
	);
};
