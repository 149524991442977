import { ApexOptions } from 'apexcharts';
import { FC, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { createChartThresholdData } from './ChartThresholdData';
import {
	getDate,
	getHour,
	getMinutes,
	getMonth,
	getMonthShort,
	getPadded,
	getYear,
} from '../../utils/Dates';
import { noop } from '../../utils/Function';
import './LineChart.scss';
import { calculateChartColor, getChartDownloadName } from './ChartOptions';
import { createArrayWith, last } from '../../utils/Array';
import { ChartNoData } from './ChartNoData';
import { TimeseriesDataSet } from '../../../SharedTypes/API/Dashboard';
import { clamp } from 'lodash';
import { renderToString } from 'react-dom/server';
import { Icon } from '../Icon/Icon';
import classNames from 'classnames';
import {
	setZoomRange,
	useDateRange,
	useZoomRange,
} from '../../../RIAMS/Dashboard/Dashboard.slice';
import { useDispatch } from 'react-redux';

type LineChartProps = {
	dataSet: TimeseriesDataSet;
	height?: number;
	dateClicked: (date: Date) => void;
	chosenDate?: number;
	size?: 'default' | 'extended' | 'fullscreen';
	isAnalytical?: boolean;
};

const riserValue = 20;

export const LineChart: FC<LineChartProps> = ({
	dataSet,
	height = 100,
	dateClicked = noop,
	chosenDate,
	isAnalytical = true,
}) => {
	const dateRange = useDateRange();
	const zoomRange = useZoomRange();
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(setZoomRange(null));
		};
	}, [dispatch]);

	// Devise a function that can check what the highest value is, and then only plot in the thresholds that are needed (plus/minus a bit)
	const neededThresholds = createChartThresholdData(
		dataSet.data,
		dataSet.minValue,
		dataSet.maxValue
	);

	const handleMarkerClick = (
		chartContext: any,
		{
			seriesIndex,
			dataPointIndex,
		}: { seriesIndex: number; dataPointIndex: number }
	) => {
		if (dataSet.chartType !== 'line') {
			return;
		}

		const timestamp =
			chartContext.w.globals.seriesX[seriesIndex][dataPointIndex];
		dateClicked(timestamp);
	};

	const chosenMarker = chosenDate
		? {
				x: chosenDate,
				y: dataSet.data.find(({ x }) => x === chosenDate)?.y,
		  }
		: null;

	const selectedEntry =
		dataSet.data.find((entry) => entry.x === chosenDate) ?? last(dataSet.data)!;

	const chartColor = calculateChartColor(selectedEntry, dataSet.thresholdOrder);

	const stepLines = createArrayWith<'smooth' | 'straight' | 'stepline'>(
		neededThresholds.length,
		'stepline'
	);
	const dashes = createArrayWith<number>(neededThresholds.length, 10);

	const widths = createArrayWith(neededThresholds.length, 1);
	const colors = createArrayWith(
		neededThresholds.length,
		'rgba(255,255,255,0.6)'
	);
	const markerColors = createArrayWith(neededThresholds.length, 'transparent');
	const fillers = createArrayWith(neededThresholds.length, 'solid');

	const dataUnits = dataSet.units ? dataSet.units : ' ';

	const minValue =
		dataSet.layout?.yaxis?.min ??
		clamp(Math.floor(dataSet.minValue - riserValue), 0, 100);
	const maxValue = dataSet.layout?.yaxis?.max;

	// Create the export download name
	const exportDownloadName = getChartDownloadName({
		dateRange: dateRange!,
		name: dataSet.name,
	});

	const options: ApexOptions = {
		stroke: {
			curve: [...stepLines, 'straight'],
			width: [...widths, 4],
			dashArray: [...dashes, 0],
		},
		xaxis: {
			type: 'datetime',
			axisTicks: {
				show: false,
			},
			tooltip: {
				enabled: false,
			},
			labels: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			crosshairs: {
				show: false,
			},
			min: zoomRange?.[0] ? zoomRange[0] : undefined,
			max: zoomRange?.[1] ? zoomRange[1] : undefined,
		},
		legend: {
			show: false,
		},
		annotations: {
			points: chosenMarker
				? [
						{
							...chosenMarker,
							marker: {
								size: 4,
								strokeWidth: 0,
								fillColor: '#fff',
							},
						},
				  ]
				: [],
		},
		yaxis: {
			forceNiceScale: dataSet.layout?.yaxis ? false : true,
			show: true,
			labels: {
				show: true,
				formatter: formatYAxisValue({
					units: dataUnits,
					maxValue: dataSet.maxValue,
					minValue: dataSet.minValue,
				}),
				padding: 5,
				style: {
					colors: 'rgba(255,255,255,0.7)',
					cssClass: 'LineChart__YAxis',
				},
			},
			tickAmount: 4,
			min: minValue,
			max: maxValue,
		},
		colors: [...colors, chartColor],
		chart: {
			background: '#003347',
			width: '100%',
			zoom: {
				enabled: isAnalytical,
				type: 'x',
			},
			toolbar: {
				show: isAnalytical,
				tools: {
					pan: false,
					zoom: renderToString(<Icon name="ChartSearch" width={16} />),
					download: renderToString(<Icon name="ChartBurgerMenu" width={16} />),
					zoomin: renderToString(<Icon name="ChartIn" width={16} />),
					zoomout: renderToString(<Icon name="ChartOut" width={16} />),
					reset: renderToString(<Icon name="Reset" width={18} />),
				},
				offsetX: 0,
				offsetY: -25,
				export: {
					png: {
						filename: exportDownloadName,
					},
					svg: {
						filename: exportDownloadName,
					},
					csv: {
						filename: exportDownloadName,
						dateFormatter(timestamp?) {
							if (!timestamp) {
								return '';
							}

							return `${getPadded(getDate(timestamp))}/${getPadded(
								getMonth(timestamp)
							)}/${getYear(timestamp)} - ${getPadded(
								getHour(timestamp)
							)}:${getPadded(getMinutes(timestamp))}`;
						},
					},
				},
			},
			events: {
				beforeZoom: (_event, { xaxis }) => {
					// Ensures we do not zoom out further than the present datapoints
					const first = dataSet.data[0].x;
					const last = dataSet.data.at(-1)?.x;

					const mainDifference = (last ?? 0) - first!;
					const zoomDifference = xaxis.max - xaxis.min;

					if (zoomDifference > mainDifference) {
						dispatch(setZoomRange(null));
						return {
							// dont zoom out any further
							xaxis: {
								min: first,
								max: last,
							},
						};
					} else {
						dispatch(setZoomRange([xaxis.min, xaxis.max]));
						return {
							// keep on zooming
							xaxis: {
								min: xaxis.min,
								max: xaxis.max,
							},
						};
					}
				},
				dataPointSelection: (
					_: any,
					chartContext: any,
					{
						seriesIndex,
						dataPointIndex,
					}: { seriesIndex: number; dataPointIndex: number }
				) =>
					handleMarkerClick(chartContext, {
						seriesIndex,
						dataPointIndex,
					}),
				beforeResetZoom: () => {
					dispatch(setZoomRange(null));
				},
			},
			// Sparkline removes anything but the chart itself
			sparkline: {
				enabled: false,
			},
			animations: {
				enabled: true,
				speed: 150,
				easing: 'easeout',
				animateGradually: {
					enabled: false,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 150,
				},
			},
		},
		grid: {
			show: true,
			yaxis: {
				lines: {
					show: true,
				},
			},
			borderColor: 'rgba(255,255,255,0.2)',
			padding: {
				left: -5,
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 2,
			hover: {
				size: 6,
			},
			colors: [...markerColors, chartColor],
			fillOpacity: 1,
			strokeWidth: 0,
			showNullDataPoints: false,
		},
		fill: {
			type: [...fillers, 'gradient'],
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.5,
				opacityTo: 0,
				stops: [0, 100],
				type: 'vertical',
			},
			colors: [...markerColors, '#009de0'],
		},
		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				// All series have the same x axis values, we can grab them from whereever
				const timestamp: string =
					w.globals.seriesX[seriesIndex][dataPointIndex];
				// We know the last series is the actual data, and not the thresholds
				const value = series[series.length - 1][dataPointIndex];
				if (!value) {
					return null;
				}
				const label = `${getMonthShort(timestamp)} ${getDate(
					timestamp
				)}, ${getYear(timestamp)}, ${getPadded(getHour(timestamp))}:${getPadded(
					getMinutes(timestamp)
				)} - ${value ?? 'Nothing'}${dataUnits}`;
				return `
					<div class="LineChart__Label" data-testid="LineChartTooltip">
						<span>${label}</span>
					</div>
					`;
			},
			followCursor: true,
		},
	};

	return (
		<div
			className={classNames(['LineChart'], {
				'LineChart--Analytical': isAnalytical,
			})}
		>
			{dataSet.data.length === 0 && <ChartNoData />}
			{dataSet.data.length > 0 && (
				<Chart
					data-testid="lineChart"
					type="area"
					series={[...neededThresholds, { ...dataSet, type: 'area' }]}
					height={height}
					width="100%"
					options={options}
				/>
			)}
		</div>
	);
};

/** Format values for y axis with rules for how many decimals to include */
const formatYAxisValue =
	(args: {
		/** The unit to prepend to the value */
		units: string;
		/** The maximum value of the series, used to determine the number of decimals to show */
		maxValue: number;
		minValue: number;
	}) =>
	(value: number) => {
		const delta = args.maxValue - args.minValue;
		const numberOfDecimals = delta < 10 ? 2 : 0;

		if (!value) {
			return '';
		}

		return `${value.toFixed(numberOfDecimals)}${args.units}`;
	};
