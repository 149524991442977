import { useCallback } from 'react';
import { IconButton } from '../../Core/components/IconButton/IconButton';
import { noop } from '../../Core/utils/Function';
import {
	ComponentId,
	ComponentName,
	DimensionConfiguration,
	Result,
	ResultDimension,
	SectionResult,
	SectionResultElement,
	SectionResultItem,
} from '../../SharedTypes/API/Explorer';
import {
	ResultRow,
	ResultRowSorting,
	ResultsTable,
	ResultTableColumn,
} from './ResultsTable';

import './SectionResultsTable.scss';

type Props = {
	sectionResult: SectionResult;
	selectedComponents: ComponentId[];
	highlightedComponent: ComponentId | null;
	maxComponents: number;
	type: 'Element' | 'Joint';
	dimensionConfiguration: DimensionConfiguration;
	isLoading: boolean;
	onChooserClick: () => void;
	onRemoveDimension: (dimensionId: string) => void;
	onSortDimension: (args: {
		dimensionId: string;
		direction: 'desc' | 'asc';
	}) => void;
	onToggleSubComponent: (args: {
		componentId: string;
		newState: 'open' | 'closed';
	}) => void;
	onLoadMore?: () => void;
	onClearSelection?: () => void;
	onComponentSelection: (componentId: ComponentId) => void;
	onComponentStartHighlight: (componentId: ComponentId) => void;
	onComponentStopHighlight: (componentId: ComponentId) => void;
};

export const SectionResultsTable = ({
	sectionResult,
	selectedComponents,
	highlightedComponent,
	maxComponents,
	type,
	dimensionConfiguration,
	isLoading,
	onChooserClick,
	onRemoveDimension,
	onSortDimension,
	onToggleSubComponent,
	onLoadMore = noop,
	onClearSelection = noop,
	onComponentSelection,
	onComponentStartHighlight,
	onComponentStopHighlight,
}: Props) => {
	// Utility function for checking if a result is part of the selected components
	const isSelectedComponent = useCallback(
		(result: SectionResultItem) => {
			const id: ComponentId = result[0];
			return selectedComponents.includes(id);
		},
		[selectedComponents]
	);

	// These are the default table rows. They should not include selected components
	const standardRows = createRows({
		maxComponents,
		type,
		results: sectionResult.results.filter(
			(result) => !isSelectedComponent(result)
		),
	});

	// These are the selected table rows
	const selectedRows = createRows({
		maxComponents: 9999,
		type,
		results: sectionResult.results.filter((result) =>
			isSelectedComponent(result)
		),
	});

	const sorting = createSorting({ dimensionConfiguration });
	const columns = createColumns({ dimensions: sectionResult.resultDimensions });
	const hasDimensions = dimensionConfiguration.dimensions.length > 0;

	function handleToggleSubComponent(newState: 'open' | 'closed') {
		return (componentId: string) => {
			onToggleSubComponent({ newState, componentId });
		};
	}

	function handleParentClick(key: string) {
		onComponentSelection(key as ComponentId);
	}

	function handleRowStartHover(key: string) {
		onComponentStartHighlight(key as ComponentId);
	}

	function handleRowEndHover(key: string) {
		onComponentStopHighlight(key as ComponentId);
	}

	return (
		<>
			{!hasDimensions && (
				<div className="SectionResultsTable__NullScreen">
					<div
						className="SectionResultsTable__NullScreenButton"
						onClick={onChooserClick}
					>
						<IconButton icon="CirclePlus" iconSize={18} theme="blue-grey">
							{type === 'Element'
								? 'Add Element Dimensions'
								: 'Add Joint Dimensions'}
						</IconButton>
					</div>
				</div>
			)}
			{hasDimensions && (
				<ResultsTable
					rows={standardRows}
					selectedRows={selectedRows}
					highlightedRowKey={highlightedComponent}
					columns={columns}
					sorting={sorting}
					isLoading={isLoading}
					onLoadMore={onLoadMore}
					onClearSelection={onClearSelection}
					onFoldIn={handleToggleSubComponent('closed')}
					onFoldOut={handleToggleSubComponent('open')}
					onSortDimension={onSortDimension}
					onRemoveDimension={onRemoveDimension}
					onChooserClick={onChooserClick}
					onParentClick={handleParentClick}
					onRowStartHover={handleRowStartHover}
					onRowEndHover={handleRowEndHover}
				/>
			)}
		</>
	);
};

type CreateRows = (args: {
	results: SectionResult['results'];
	maxComponents: number;
	type: 'Joint' | 'Element';
}) => ResultRow[];
const createRows: CreateRows = ({ results, maxComponents, type }) => {
	const typeLookup: Record<
		'Joint' | 'Element',
		ResultRow['children'][0]['type']
	> = {
		Element: 'SubElement',
		Joint: 'Brace',
	};

	return results.slice(0, maxComponents).map((result) => {
		// We evaluate each value for better type checking
		const id: ComponentId = result[0];
		const name: ComponentName = result[1];
		const hasChildren: boolean = result[5];
		const results: { [dimensionId: string]: Result } = result[4];
		const children: SectionResultElement[] = result[6];

		return {
			hasChildren,
			children: children.map<ResultRow['children'][0]>((subResult) => {
				const key: ComponentId = subResult[0];
				const name: ComponentName = subResult[1];
				const results: { [dimensionId: string]: Result } = subResult[4];

				return {
					key,
					name,
					results,
					type: typeLookup[type],
				};
			}),
			key: id,
			name: name,
			results,
			type,
		};
	});
};

type CreateColumns = (args: {
	dimensions: ResultDimension[];
}) => ResultTableColumn[];
const createColumns: CreateColumns = ({ dimensions }) => {
	return dimensions.map((dimension, i) => ({
		isSticky: false,
		key: dimension.id,
		title: dimension.shortName,
		subtitle: dimension.unit,
		tooltip: dimension.name,
		width: '135px',
	}));
};

type CreateSorting = (args: {
	dimensionConfiguration: DimensionConfiguration;
}) => ResultRowSorting;
const createSorting: CreateSorting = ({ dimensionConfiguration }) => {
	return {
		column: dimensionConfiguration.sortedDimension ?? '',
		direction: dimensionConfiguration.sortDirection,
	};
};
