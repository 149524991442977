import { ReactNode } from 'react';
import { Icon } from '../Core/components/Icon/Icon';
import { noop } from '../Core/utils/Function';

import './SiteControl.scss';

type Props = {
	name: string;
	children: ReactNode;
};

export const SiteControl = ({ name, children }: Props) => {
	return (
		<div className="SiteControl">
			<div className="SiteControl__Name">{name}</div>
			<div className="SiteControl__Sites">{children}</div>
		</div>
	);
};

export const SiteControlButton = ({
	children,
	onClick = noop,
}: {
	children: ReactNode;
	onClick?: () => void;
}) => {
	return (
		<div className="SiteControlButton" onClick={onClick}>
			<div className="SiteControlButton__Text">{children}</div>
			<div className="SiteControlButton__Caret">
				<Icon name="CaretRight" width={7} />
			</div>
		</div>
	);
};
