import { FC } from 'react';
import './ListLoading.scss';

type Props = {
	text: string;
};

export const ListLoading: FC<Props> = ({ text }) => (
	<div className="ListLoading">
		<p className="ListLoading__Text">{text}</p>
	</div>
);
