import classNames from 'classnames';
import { FC, Fragment } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatCamelCase } from '../../utils/String';
import { Icon } from '../Icon/Icon';
import './Breadcrumb.scss';

type Props = {
	basePath: string; // Relative path to the "start" of the breadcrumb
	baseTitle: string; // A nice title for the base path, could be "Overview" or "Home" or w/e
};

export const Breadcrumb: FC<Props> = ({ basePath, baseTitle }) => {
	const params = useParams();
	const navigate = useNavigate();
	const { search } = useLocation();

	const handleGoToBasePath = () => {
		navigate(basePath + search);
	};

	const crumbs =
		params['*'] &&
		params['*']?.split('/').reduce((acc, path) => {
			const previousPath = acc.map((a) => a.path).join('/');
			return [
				...acc,
				{
					path: previousPath ? previousPath + `/${path}` : path,
					title: formatCamelCase(path),
				},
			];
		}, [] as { path: string; title: string }[]);

	return (
		<div className="Breadcrumb">
			<div
				className={classNames(['Breadcrumb__Base'], {
					'Breadcrumb__Base--Highlight': crumbs,
				})}
				onClick={handleGoToBasePath}
			>
				<div
					className={classNames(['Breadcrumb__Icon'], {
						'Breadcrumb__Icon--Highlight': crumbs,
					})}
				>
					<Icon name="Home" width={17} />
				</div>
				<p className="Breadcrumb__Title">{baseTitle}</p>
			</div>
			{crumbs &&
				crumbs.map(({ path, title }, index) => {
					return (
						<Fragment key={title}>
							<div className="Breadcrumb__Divider">/</div>
							<Link
								className={classNames(['Breadcrumb__Base'], {
									'Breadcrumb__Base--Highlight': index !== crumbs.length - 1,
								})}
								to={path}
							>
								<p className="Breadcrumb__Title">{title}</p>
							</Link>
						</Fragment>
					);
				})}
		</div>
	);
};
