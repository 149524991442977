import { ChartDataSetData } from '../../../SharedTypes/API/Dashboard';
import { ApexSeries } from '../../types/ChartDataSet';

const marginToDisplayThreshold = 10;

export const createChartThresholdData = (
	dataSet: ChartDataSetData[],
	minValue: number = 0,
	maxValue: number = 100
): ApexSeries[] => {
	if (!dataSet) {
		return [];
	}

	const thresholdData1 = dataSet
		.map(({ x, thresholds }) => ({
			x,
			y: thresholds[0] ?? null,
		}))
		// In case we do not have a threshold value for a given date
		// We use the previous value in order to keep the thresholds rendered on page
		.reduce<{ x: number; y: number | null }[]>(
			(acc, currentValue, currentIndex) => {
				acc.push({
					x: currentValue.x,
					y: !currentValue?.y
						? acc[currentIndex - 1]?.y ?? null
						: currentValue.y,
				});

				return acc;
			},
			[]
		);
	const thresholdData2 = dataSet
		.map(({ x, thresholds }) => ({
			x,
			y: thresholds[1] ?? null,
		}))
		.reduce<{ x: number; y: number | null }[]>(
			(acc, currentValue, currentIndex) => {
				acc.push({
					x: currentValue.x,
					y: !currentValue?.y
						? acc[currentIndex - 1]?.y ?? null
						: currentValue.y,
				});

				return acc;
			},
			[]
		);

	const thresholdData3 = dataSet
		.map(({ x, thresholds }) => ({
			x,
			y: thresholds[2] ?? null,
		}))
		.reduce<{ x: number; y: number | null }[]>(
			(acc, currentValue, currentIndex) => {
				acc.push({
					x: currentValue.x,
					y: !currentValue?.y
						? acc[currentIndex - 1]?.y ?? null
						: currentValue.y,
				});

				return acc;
			},
			[]
		);

	const thresholdSet1 = {
		name: 'thresholds1',
		data: thresholdData1,
		type: 'line',
	};
	const thresholdSet2 = {
		name: 'thresholds2',
		data: thresholdData2,
		type: 'line',
	};
	const thresholdSet3 = {
		name: 'thresholds3',
		data: thresholdData3,
		type: 'line',
	};

	// The objective is to only show threshold lines needed.
	// By knowing the min and max values, we must find the thresholds that are between (plus minus some margin...)

	return dataSet
		.reduce((acc: ApexSeries[], _: ChartDataSetData, index: number) => {
			if (
				thresholdData1.length === 0 &&
				thresholdData2.length === 0 &&
				thresholdData3.length === 0
			) {
				return acc;
			}

			if (
				!thresholdData1[index]?.y &&
				!thresholdData2[index]?.y &&
				!thresholdData3[index]?.y
			) {
				return acc;
			}

			const firstThresholdY = thresholdData1[index].y;
			const secondThresholdY = thresholdData2[index].y;
			const thirdThresholdY = thresholdData3[index].y;

			if (
				firstThresholdY &&
				minValue - marginToDisplayThreshold <= firstThresholdY
			) {
				acc[0] = thresholdSet1;
			}
			if (
				secondThresholdY &&
				minValue - marginToDisplayThreshold <= secondThresholdY &&
				maxValue + marginToDisplayThreshold >= secondThresholdY
			) {
				acc[1] = thresholdSet2;
			}
			if (
				thirdThresholdY &&
				maxValue + marginToDisplayThreshold >= thirdThresholdY
			) {
				acc[2] = thresholdSet3;
			}

			return acc;
		}, [])
		.filter((el) => !!el);
};
