import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { useQueryParam } from '../../hooks/useQueryParam';
import './Fullscreener.scss';

type Props = {
	leftElement: ReactNode;
	rightElement: ReactNode;
};

export const Fullscreener: FC<Props> = ({ leftElement, rightElement }) => {
	const [fullscreen] = useQueryParam('fullscreen');

	const isFullscreen = fullscreen === 'true';

	return (
		<div
			className={classNames(['Fullscreener'], {
				'Fullscreener--Fullscreen': isFullscreen,
			})}
		>
			{leftElement}
			{rightElement}
		</div>
	);
};
