import classNames from 'classnames';
import { FC, useCallback, useRef, useState } from 'react';
import { useGetTrendsQuery } from '../../Core/Api';
import { Button } from '../../Core/components/Button/Button';
import { Calendar } from '../../Core/components/Calendar/Calendar';
import { Card } from '../../Core/components/Card/Card';
import { MultiLineChart } from '../../Core/components/Chart/MultiLineChart';
import { CounterButton } from '../../Core/components/CounterButton/CounterButton';
import { Icon } from '../../Core/components/Icon/Icon';
import { IndicatorDot } from '../../Core/components/IndicatorDot/IndicatorDot';
import { PopMenu, PopMenuState } from '../../Core/components/PopMenu/PopMenu';
import { useQueryParam } from '../../Core/hooks/useQueryParam';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import { blendColors } from '../../Core/utils/Colors';
import { ChartDataSet } from '../../SharedTypes/API/Dashboard';
import { setDateRange } from '../Dashboard/Dashboard.slice';
import './TrendsContent.scss';

type Props = {
	siteId: string;
	templateKey: string;
	dates: [Date | undefined, Date | undefined];
};

const defaultChartColors = [
	'#06BB75',
	'#F57800',
	'#FFC300',
	'#E00039',
	'#009DE0',
];

export const TrendsContent: FC<Props> = ({ siteId, templateKey, dates }) => {
	const dispatch = useAppDispatch();
	const [fullscreen] = useQueryParam('fullscreen');
	const isFullscreen = fullscreen === 'true';
	// Fetch the template here, to display as cards
	const { data } = useGetTrendsQuery(
		{
			siteId,
			templateKey,
		},
		{
			skip: !templateKey,
		}
	);

	const [chartColors, setChartColors] = useState<string[]>(defaultChartColors);
	const [activeChartSection, setActiveChartSection] = useState<number | null>(
		null
	);

	/** Handles the highlighting of the hovered chart */
	const handleOnMouseEnter =
		(trendIndex: number) => (hoveringIndex: number) => {
			// const defaultColorsCopy = [...defaultChartColors];

			// const colorToLighten = defaultColorsCopy[hoveringIndex];

			// const newColor = blendColors(colorToLighten as string, '#fff', 0.5);

			const mutedColors = defaultChartColors.map((color, index) => {
				if (index === hoveringIndex) {
					return color;
				}

				return blendColors(color, '#000', 0.3);
			});

			// defaultColorsCopy[hoveringIndex] = newColor;
			setActiveChartSection(trendIndex);
			setChartColors(mutedColors);
		};

	/** Resets the focused (hovered) graph on the chart */
	const handleOnMouseExit = () => {
		setActiveChartSection(null);
		setChartColors(defaultChartColors);
	};

	/**
	 * Handler for setting the selected date range
	 * If the input is not an array, we do nothing
	 */
	const handleSelectedDateRangeChange = useCallback(
		(dates: [Date, Date]) => {
			dispatch(setDateRange(dates));
		},
		[dispatch]
	);

	const getColorsForBlock = (trendIndex: number) => {
		return activeChartSection === trendIndex ? chartColors : defaultChartColors;
	};

	return (
		<section className="TrendsContent">
			<div className="TrendsContent__Calendar">
				<Calendar
					onChange={handleSelectedDateRangeChange}
					startDate={dates?.[0]}
					endDate={dates?.[1]}
				/>
			</div>
			<div
				className={classNames(['TrendsContent__Content'], {
					'TrendsContent__Content--Fullscreen': isFullscreen,
				})}
			>
				{data?.trends.map((trend, trendIndex) => (
					<Card theme="dark" key={trend.id}>
						<TrendsContentChartButtons
							colors={getColorsForBlock(trendIndex)}
							dataSets={trend.dataSets}
							onHoverItemStart={handleOnMouseEnter(trendIndex)}
							onHoverItemEnd={handleOnMouseExit}
						/>
						<MultiLineChart
							dataSets={trend.dataSets}
							dateClicked={() => {}}
							chartColors={getColorsForBlock(trendIndex)}
						/>
						<h3>{trend.title}</h3>
					</Card>
				))}
			</div>
		</section>
	);
};

type TrendsContentChartButtonsProps = {
	dataSets: ChartDataSet[];
	colors: string[];
	onHoverItemStart: (itemIndex: number) => void;
	onHoverItemEnd: () => void;
};

const TrendsContentChartButtons: FC<TrendsContentChartButtonsProps> = ({
	dataSets,
	colors,
	onHoverItemStart,
	onHoverItemEnd,
}) => {
	const menuRef = useRef<HTMLDivElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const dataSetsToDisplay = dataSets.slice(0, 3);
	const otherDataSets = dataSets.slice(3);

	return (
		<div className="TrendsContent__CardButtons">
			{dataSetsToDisplay.map((set, index) => (
				<Button
					key={set.id}
					theme="TrafficLight"
					data-active="false"
					onMouseEnter={() => onHoverItemStart(index)}
					onMouseLeave={onHoverItemEnd}
				>
					<div className="TrendsContent__ButtonContent">
						<IndicatorDot status="default" size={10} color={colors[index]} />
						<p>{set.shortName ?? set.name}</p>
					</div>
				</Button>
			))}
			{otherDataSets.length > 0 && (
				<PopMenu
					theme="Dark"
					items={dataSets.map((set, index) => ({
						key: set.id,
						label: set.name,
						icon: (
							<IndicatorDot status="default" size={10} color={colors[index]} />
						),
					}))}
					onMouseEnterItem={(_key, index) => onHoverItemStart(index)}
					onMouseLeaveItem={onHoverItemEnd}
					placement="bottom-start"
					onChangeState={(state: PopMenuState) =>
						setIsMenuOpen(state === 'open')
					}
					elementRef={menuRef}
					className={classNames(['TrendsContent__PopMenu'], {
						isOpen: isMenuOpen,
					})}
				>
					<div className="TrendsContent__MoreButton" ref={menuRef}>
						<CounterButton count={otherDataSets.length}>
							<Button theme="TrafficLightRound" data-active="false">
								<Icon
									className={isMenuOpen ? 'FormInputSelect--Open' : ''}
									name="ThreeDotsH"
									width={14}
								/>
							</Button>
						</CounterButton>
					</div>
				</PopMenu>
			)}
		</div>
	);
};
