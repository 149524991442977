import { FC, ReactNode } from 'react';
import { Counter } from '../Counter/Counter';
import './CounterButton.scss';

type Props = {
	count: number;
	children: ReactNode;
};

/** Takes a child of Button, and will position a counter on that button */
export const CounterButton: FC<Props> = ({ count, children }) => (
	<div className="ButtonCounter">
		{children}
		<div className="ButtonCounter__Counter">
			<Counter theme="Default" count={count} />
		</div>
	</div>
);
