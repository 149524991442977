import { Provider } from 'react-redux';
import './App.scss';
import { store } from './Core/redux/store';
import { ToastContainer } from 'react-toastify';

import { AppRoutes } from './Routes';

function App() {
	return (
		<Provider store={store}>
			<div className="App">
				<AppRoutes />
				<ToastContainer icon={false} position="bottom-right" />
				<div id="modalRoot"></div>
			</div>
		</Provider>
	);
}

export default App;
