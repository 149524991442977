import { useMemo } from 'react';
import { CubeTextureLoader, sRGBEncoding } from 'three';

import skyboxSideA from './textures/skybox_side_3.png';
import skyboxSideB from './textures/skybox_side_1.png';
import skyboxSideC from './textures/skybox_side_2.png';
import skyboxSideD from './textures/skybox_side_4.png';
import skyboxTop from './textures/skybox_top.png';
import skyboxBottom from './textures/skybox_bottom.png';

export function useSkyBox() {
	const loader = useMemo(() => new CubeTextureLoader(), []);

	// The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
	const texture = useMemo(() => {
		const texture = loader.load([
			skyboxSideA,
			skyboxSideB,
			skyboxTop,
			skyboxBottom,
			skyboxSideC,
			skyboxSideD,
		]);

		texture.encoding = sRGBEncoding;

		return texture;
	}, [loader]);

	return texture;
}
