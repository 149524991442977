import { Navigate } from 'react-router-dom';
import { useGetIsUserEmailVerifiedQuery, useGetUserQuery } from './Api';
import { useMemo, FC, ReactNode } from 'react';

/**
 * Wrapper component that only renders the children if the user is logged in
 * and the users email is verified.
 * Redirects to the login screen if the user is logged out
 */
export const RequireLogin: FC<{ children: ReactNode }> = ({ children }) => {
	// Query for the user
	const userResult = useGetUserQuery({});

	// Get the logicaæ state of the users login status
	const loginStatus: 'unknown' | 'loggedOut' | 'loggedIn' = useMemo(() => {
		if (userResult.isLoading || userResult.isFetching) {
			return 'unknown';
		}

		if (userResult.isError) {
			return 'loggedOut';
		}

		if (userResult.isSuccess) {
			return userResult.data.isLoggedIn ? 'loggedIn' : 'loggedOut';
		}

		return 'unknown';
	}, [userResult]);

	// Query for the users email verification status, but only if the user is properly logged in
	const emailVerifiedResult = useGetIsUserEmailVerifiedQuery(
		{},
		{ skip: loginStatus !== 'loggedIn' }
	);

	// Get the logical state of the users email verification status
	const emailVerifiedStatus: 'unknown' | 'verified' | 'unverified' =
		useMemo(() => {
			if (loginStatus !== 'loggedIn') {
				return 'unknown';
			}

			if (emailVerifiedResult.isLoading || emailVerifiedResult.isFetching) {
				return 'unknown';
			}

			if (emailVerifiedResult.isSuccess) {
				return emailVerifiedResult.data.isEmailVerified
					? 'verified'
					: 'unverified';
			}

			return 'unknown';
		}, [emailVerifiedResult, loginStatus]);

	// Go to the login screen if the user is logged out
	const goToLoginScreen = useMemo(
		() => loginStatus === 'loggedOut',
		[loginStatus]
	);

	// Show email verification error if the email is unverified
	const showEmailUnverifiedError = useMemo(
		() => emailVerifiedStatus === 'unverified',
		[emailVerifiedStatus]
	);

	// The user is first fully authorized when the email is verified
	const isAuthorized = useMemo(
		() => emailVerifiedStatus === 'verified',
		[emailVerifiedStatus]
	);

	return (
		<>
			{goToLoginScreen && <Navigate to="/login" />}
			{showEmailUnverifiedError && (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '100vh',
					}}
				>
					<div>
						Your email has not yet been verified. Please contact your
						administrator.
					</div>
				</div>
			)}
			{isAuthorized && children}
		</>
	);
};
